<template>
  <div class="wrapper">
<!--    <div class="list_name" style="overflow-y: auto">-->
<!--      <el-menu style="width: 100%;" @select="switchOut" background-color="#f0f4f7" default-active="1">-->
<!--        <el-menu-item index="1" style="text-indent: 1em;height: 3rem;line-height: 3rem;">医生端</el-menu-item>-->
<!--&lt;!&ndash;        <el-menu-item index="2" style="text-indent: 1em;height: 3rem;line-height: 3rem;">患者端</el-menu-item>&ndash;&gt;-->
<!--      </el-menu>-->
<!--      <img src="/img/pa1.png" style="width: 100%;position: absolute;bottom: 0;object-fit: contain" alt="">-->
<!--    </div>-->
    <div class="con">
      <div class="title">
        <div>会议记录</div>
        <div style="margin-right: 30px;">
          <el-input @change="change_name" size="mini" placeholder="请输入内容" v-model="keyword_name">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
      </div>
      <div class="content">
        <div style="height: 100%;overflow-y: auto;">
          <div class="history" v-for="(item, index) in order_info" :key="index">
            <div>
              <div style="padding-bottom: 0.5rem;display: flex;align-items: center;">
                <span class="date" style="font-weight: bold;">{{ item.organizer_name }}发起的会议</span>
                <span class="date" style="font-weight: bold;padding-left: 1rem">{{ item.name }}</span>
              </div>
              <span class="date" style="color: #9b9b9b">会议房间: {{ item.id }} </span>
              <span class="date" style="margin-left: 15px;color: #9b9b9b">{{ item.start_time }}</span>
            </div>
            <div style="display: flex;align-items: center;font-weight: bold;font-size: 14px;" v-if="item.transcribe_url">
              <div style="margin-right: 30px;color: #aebfcc;cursor: pointer"
                   v-if="item.is_transcribe === '0'">回放
              </div>
              <div style="margin-right: 30px;color: #0e92dd;cursor: pointer" v-else
                   @click="returning_to(item)">回放
              </div>
              <div style="color: #aebfcc;cursor: pointer" v-if="item.is_download === '0'">下载</div>
              <div style="color: #0e92dd;cursor: pointer" @click="download_file(item.id)" v-else>下载</div>
            </div>
          </div>
        </div>
        <!--            <div style="height: 30%;position: relative;">-->
        <!--                <el-pagination-->
        <!--                    :page-size="5"-->
        <!--                    :pager-count="5"-->
        <!--                    layout="prev, pager, next"-->
        <!--                    :total="30"-->
        <!--                    style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);">-->
        <!--                </el-pagination>-->
        <!--            </div>-->
      </div>
      <div style="width: 100%;height: 10%;display: flex;justify-content: center;align-items: center;">
        <el-pagination :page-size="10" background layout="prev, pager, next" @current-change="order_info_page"
                       :total="order_info_total">
        </el-pagination>
      </div>
      <div class="but">
        <el-button style="width: 20%;font-weight: bold;padding: 8px;" type="primary"
                   @click="sponsor_conference">发起会议
        </el-button>
        <el-button style="width: 20%;font-weight: bold;padding: 8px;" type="primary"
                   @click="join">加入会议
        </el-button>
      </div>

      <!-- //问诊-->
      <el-dialog title="发起会议" append-to-body :visible.sync="dialogMaterial" width="45%">
        <!--            <div style="width: 90%;margin:auto;display: flex;">-->
        <!--                <div style="width: 30%;">-->
        <!--                    <el-input-->
        <!--                        size="mini"-->
        <!--                        placeholder="请输入内容"-->
        <!--                        v-model="keyword">-->
        <!--                        <i slot="prefix" class="el-input__icon el-icon-search"></i>-->
        <!--                    </el-input>-->
        <!--                </div>-->
        <!--                <div style="width: 30%;margin-left: 10px;">-->
        <!--                    <el-button type="primary" size="mini" @click="query">查询</el-button>-->
        <!--                </div>-->
        <!--            </div>-->
        <div class="from_style" style="width: 100%;display: flex;justify-content: center;margin-top: 1rem;">
          <el-form ref="form" :model="form" label-width="80px" style="width: 80%;">
            <el-form-item label="会议主题">
              <el-input placeholder="请填写会议主题" v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="参会人员">
              <el-select style="width: 100%" v-model="form.attendees_id" multiple collapse-tags
                         placeholder="请选择参会人员">
                <el-option v-for="item in doctor_List" :key="item.en_name + '_doctor_' + item.id"
                           :label="(item.getHospital.name ? item.getHospital.name : '') + (item.medicalDepartment ? '-' + item.medicalDepartment.name : '') + '-' + item.name"
                           :value="item.en_name + '_doctor_' + item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="录制">
              <el-radio-group v-model="form.is_transcribe">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="支持下载录制视频" style="display: flex;justify-content: left">
              <el-radio-group v-model="form.is_download">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                    <el-button type="primary" @click="initiate">确认发起</el-button>
                    <el-button @click="dialogMaterial = false"
                               style="background: #c3e6ff;border: 1px #0e92dd solid;color: #0e92dd">取 消</el-button>
                </span>
      </el-dialog>


      <el-dialog title="加入会议" :visible.sync="dialogMaterial1" append-to-body width="45%">
        <div style="width: 100%;height: 330px;margin-top: 10px;overflow-y: auto;font-size: 20px">
          <div v-for="(item, index) in order_info_all" :key="index"
               style="width: 90%;height: 30px;margin: 1rem auto 0;">
            {{ item.organizer_name }}发起的会议 - 房间号:{{ item.id }} - 会议主题:{{ item.name }}
            <el-button
                type="primary" size="mini" @click="participate_in(item)">加入
            </el-button>
          </div>
        </div>
      </el-dialog>
      <!--        <TUICallKit-->
      <!--            style="position: fixed;top: 50%;left: 50%; transform: translate(-50%, -50%); width: 50%;height: 50%;"-->
      <!--            :allowedMinimized="true"-->
      <!--            :allowedFullScreen="true"-->
      <!--        />-->

      <!--        <el-dialog title="视频通话" :visible.sync="testrtcType" width="60%" :close-on-press-escape="false" :close-on-click-modal="false">-->
      <!--            <Testrtc ref="videoim"></Testrtc>-->
      <!--        </el-dialog>-->
      <!--        <draggable-resizable-->
      <!--            w="900"-->
      <!--            :x="centerX"-->
      <!--            :y="centerY"-->
      <!--            @mousedown.native.stop="onMouseDown"-->
      <!--            @touchstart.native.stop="onMouseDown"-->
      <!--            >-->
      <!--            <div v-if="testrtcType" style="width: 100%;position: fixed;top: 15%;left: 50%;transform: translateX(-50%);background-color: #fff;box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.3);border-radius: 10px;">-->
      <!--                <div style="width: 100%;height: 50px;border-bottom: 1px #ccc solid;display: flex;justify-content: space-between;">-->
      <!--                    <div style="display: flex;align-items: center;">-->
      <!--                        <div style="color: #000000;font-weight: bold;padding-left: 20px;">{{ video_room_name }}发起的会议</div>-->
      <!--                        <div style="color: #000000;font-weight: bold;padding-left: 30px;">房间号: {{ video_room_id }}</div>-->
      <!--                    </div>-->
      <!--                    <div style="display: flex;align-items: center;margin-right: 20px;">-->
      <!--                        <div class="con_img"><img src="@/assets/drag1.png" style="width: 100%;height: 100%" alt="Vue Image"></div>-->
      <!--                        <div class="con_img"><img src="@/assets/miniaturization1.png" style="width: 120%;height: 120%" alt="Vue Image"></div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--                <Testrtc ref="videoim"></Testrtc>-->
      <!--            </div>-->
      <!--        </draggable-resizable>-->
    </div>
  </div>
</template>
<script>
import {
  chatDinding,
  config, createMeetingRecordsFile,
  doctor_getList,
  imMeetingGroupCreate,
  meeting_records,
  meeting_recordsGetAll,
  addUserMeetingRecordsList,
  MeetingRecords,
  recordsAdd,
  recordsEdit
} from "@/api";
import userIm from "@/components/userIm.vue";
import {ConferenceMainView, conference} from '@tencentcloud/roomkit-web-vue2.7';
import genTestUserSig from "@/debug/GenerateTestUserSig-es.js";
import doctorIm from "@/components/doctorIm.vue";
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import wsservice from "@/utils/im";

export default {
  name: 'videoConf',
  components: {doctorIm, userIm},
  data() {
    return {
      aaaaaaaaaaa: true,
      form: {
        name: '',
        attendees_id: [],
        is_transcribe: 1,
        is_download: 1,
      },
      testrtcType: false,
      user_id: '',
      order_info: [],
      dialogMaterial: false,
      dialogMaterial1: false,
      keyword: '',
      keyword_name: '',
      doctor_List: [],
      checkedItems: [],
      attendees_id: '',
      attendees_id1: '',
      en_user_name: '',
      en_doctor_name: '',
      user_role: '',
      order_info_total: 0,
      order_page: 1,
      client_id: '',
      order_info_all: [],
      // 获取页面宽度和高度
      pageWidth: window.innerWidth,
      pageHeight: window.innerHeight,
      SDKAppID: undefined,
      SecretKey: undefined,
      userSig: undefined
    }
  },
  computed: {
    // 计算组件在屏幕中央的 x 坐标
    centerX() {
      return -150  // 900 是组件的宽度
    },
    // 计算组件在屏幕中央的 y 坐标
    centerY() {
      return (this.pageHeight) / -1.1; // 0.9 是组件的高度占屏幕高度的比例
    }
  },
  mounted() {
    this.client_id = localStorage.getItem('client_id');
    this.user_id = localStorage.getItem('user_id')
    this.user_role = localStorage.getItem('user_role');
    this.en_user_name = localStorage.getItem('en_user_name');
    this.orderList()
    this.fetchClientId()
  },
  beforeDestroy() {
    document.body.style.userSelect = '';
    document.body.style.webkitUserSelect = '';
    document.body.style.msUserSelect = '';
    document.body.style.mozUserSelect = '';
  },
  methods: {
    async fetchClientId() {
      try {
        const data = await wsservice(this); // 等待 Promise 解析
        if (data && data.data.client_id) {
          this.client_id = data.data.client_id;
          this.init()
          this.init_im()
        }
      } catch (error) {
        console.error("Error fetching client_id:", error);
      }
    },
    switchOut(e) {
      if (e == 2) {
        this.$emit('loginOuts')
      }
    },
    //查看回放信息
    returning_to(array) {
      window.open(array.transcribe_url)
    },
    onMouseDown() {
      document.body.style.userSelect = 'none';
      document.body.style.webkitUserSelect = 'none'; // For Safari
      document.body.style.msUserSelect = 'none'; // For IE
      document.body.style.mozUserSelect = 'none'; // For Firefox
    },
    //下载文件
    download_file(id) {
      createMeetingRecordsFile({
        id: id
      }).then(res => {
        if (res.data.code === 1) {
          window.open(res.data.data.url)
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      })
    },
    join() {
      this.$emit('openChat')
      meeting_recordsGetAll({
        meeting_source: 2,
        attendees_id: this.en_user_name + '_' + 'doctor_' + this.user_id,
      }).then(res => {
        if (res.data.code === 1) {
          this.order_info_all = res.data.data
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      })
      this.dialogMaterial1 = true
    },
    participate_in(item) {
      if (item.is_show === '1') {
        this.$emit('participate_in', item);
        this.dialogMaterial1 = false
      } else {
        this.$message({
          message: '房间已解散',
          type: 'warning'
        });
      }
    },
    decrypt(encodedStr) {
      return atob(encodedStr);
    },
    //初始化视频
    init() {
      config().then(res => {
        if (res.data.code === 1) {
          this.SDKAppID = this.decrypt(res.data.data.sdk_app_id)
          this.SecretKey = this.decrypt(res.data.data.sdk_secret_key)
          try {
            const {userSig} = genTestUserSig({
              userID: this.en_user_name + '_' + this.user_role + '_' + this.user_id.toString(),
              SDKAppID: Number(this.SDKAppID),
              SecretKey: this.SecretKey,
            });

            this.userSig = userSig
            // 初始化
            // let promise = TUICallKitServer.init({
            //     SDKAppID: Number(this.SDKAppID),
            //     userID: this.en_user_name+'_'+this.user_role+'_'+this.user_id.toString(),
            //     userSig,
            //     // tim: this.tim     // 如果工程中已有 tim 实例，需在此处传入
            // }).then(success => {
            //     console.log('success',success)
            // }).catch(error => {
            //     console.log(error)
            // });
            conference.login({
              // 获取 sdkAppId 可参考文档开通服务部分，https://cloud.tencent.com/document/product/647/104842
              sdkAppId: Number(this.SDKAppID),
              // 用户在您业务中的唯一标示 Id
              userId: this.en_user_name + '_' + this.user_role + '_' + this.user_id.toString(),
              // 本地开发调试可在 https://console.cloud.tencent.com/trtc/usersigtool 页面快速生成 userSig, 注意 userSig 与 userId 为一一对应关系
              userSig: userSig,
            });

          } catch (error) {
            this.$message({
              message: error,
              type: 'warning'
            });
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      })
    },
    query() {
      this.retrieval()
    },
    retrieval() {
      doctor_getList({
        keyword: this.keyword,
      }).then(res => {
        if (res.data.code === 1) {
          this.doctor_List = res.data.data
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      })
    },
    //获取自己的以及对方的im_id
    init_im() {
      chatDinding({
        user_id: this.user_id,
        role: this.user_role,
        client_id: this.client_id,
      }).then(res => {
        if (res.data.code === "1") {
          this.user_im_id = res.data.data.im_id
          localStorage.setItem('video_im_id', res.data.data.im_id);
          console.log(this.user_im_id)
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      })
    },
    initiate() {
      const attendees_id = this.form.attendees_id.join(",");
      let array = []
      this.form.attendees_id.forEach((item) => {
        const parts = item.split('_')
        array.push(parts[1] + parts[2])
      });
      const attendee = this.en_user_name + '_' + this.user_role + '_' + this.user_id.toString()
      const initiator = attendees_id
      const names = this.form.name
      const is_downloads = this.form.is_download
      const is_transcribes = this.form.is_transcribe
        MeetingRecords({
          meeting_source: 2,
          organizer_id: this.user_id,
          role: 2,
          attendees_id: attendee + ',' + initiator,
          name: names,
          is_download: is_downloads,
          is_transcribe: is_transcribes,
        }).then(res2 => {
          imMeetingGroupCreate({meeting_records_id: res2.data.data.id, im_id: this.user_im_id})
          let cache_ids = localStorage.getItem('cache_ids');
          if (!cache_ids) {
            let d = 'doctor' + attendee
            localStorage.setItem('cache_ids', d);
            cache_ids = d
          }
          addUserMeetingRecordsList({
            cache_ids: cache_ids,
            user_id: attendee,
            user_sig: this.userSig,
            meeting_records_id: res2.data.data.id,
          })
          if (res2.data.code === 1) {
            localStorage.setItem('roomid', res2.data.data.id);
            localStorage.setItem('userId', attendee);
            this.$emit('testrtcType_change', {
              video_room_id: res2.data.data.id,
              video_room_name: res2.data.data.user_name,
            });
          } else {
            this.$message({
              message: res2.data.msg,
              type: 'warning'
            });
          }
        })
      this.dialogMaterial = false
      this.checkedItems = []
    },
    async createGroupID() {
      const SDKAppID = this.SDKAppID
      const tim = TIM.create({SDKAppID});
      const memberList = [];
      this.userIDList.forEach((userID) => {
        memberList.push({
          userID: userID
        });
      });
      const res = await tim.createGroup({
        type: TIM.TYPES.GRP_PUBLIC, // 必须是 public 群
        name: 'WebSDK',
        memberList
      });
      return res.data.group.groupID;
    },
    //拨打
    async call() {
      try {
        this.videoMore = true
        const hasPermissions = await this.checkMediaPermissions();
        if (hasPermissions === '没有授权摄像头或者没连接设备') {
          this.$message({
            message: '没有授权摄像头或者没连接设备,请到设置-权限中授权',
            type: 'warning'
          });
        } else if (hasPermissions === '没有授权麦克风或者没连接设备') {
          this.$message({
            message: '没有授权麦克风或者没连接设备,请到设置-权限中授权',
            type: 'warning'
          });
          return;
        }
        recordsAdd({
          attendees_id: this.attendees_id,
        }).then(res => {
          if (res.data.code === 1) {
            this.attendees_id = ''
            this.userIDList = res.data.data.userIDList
            const groupID = this.createGroupID();
            groupID.then(result => {
              recordsEdit({
                id: res.data.data.id,
                group_id: result,
                meeting_source: 2,
                organizer_id: this.user_id,
                role: 2,
              }).then(res => {
                if (res.data.code !== 1) {
                  this.$message({
                    message: '网络错误',
                    type: 'warning'
                  });
                } else {
                  // 在 recordsEdit 成功后调用 orderList 获取最新数据
                  this.orderList();
                }
              })
              // const callOptions = {
              //     userIDList: this.userIDList,
              //     groupID: result,
              //     type: TUICallType.VIDEO_CALL,
              //     roomID: res.data.data.roomID,
              //     timeout: res.data.data.timeout,
              //     userData: 'userdata',
              // };
              // let promise = TUICallKitServer.groupCall(callOptions);
              // promise.then(() => {
              //     console.warn('成功', res.data.data.id)
              // }).catch(error => {
              //     console.warn('groupCall error:', error)
              // });
            }).catch(error => {
              console.error(error);
            });
          } else {
            this.$message({
              message: '网络错误',
              type: 'warning'
            });
          }
        })
        // 这个段代码需要请求后端接口生成{userID: 'user'+this.patient_id.toString(), type: TUICallType.VIDEO_CALL,roomID:999}这部分内容。
        // await TUICallKitServer.call({userID: 'user'+this.patient_id.toString(), type: TUICallType.VIDEO_CALL,roomID:999});
        // console.log('发起通话')
      } catch (error) {
        this.videoMore = false
      }
    },
    async checkMediaPermissions() {
      try {
        const videoStream = await navigator.mediaDevices.getUserMedia({video: true});
        videoStream.getTracks().forEach(track => track.stop());
      } catch (videoError) {
        return '没有授权摄像头或者没连接设备'
      }
      try {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        stream.getTracks().forEach(track => track.stop());
        return true;
      } catch (error) {
        return '没有授权麦克风或者没连接设备'
      }
    },
    orderList() {
      meeting_records({
        role: 2,
        meeting_source: 2,
        attendees_id: this.en_user_name + '_' + 'doctor_' + this.user_id,
        page: this.order_page,
        limit: 10,
        keyword: this.keyword_name,
      }).then(res => {
        if (res.data.code === 1) {
          this.order_info = res.data.data.data
          this.order_info_total = Number(res.data.data.total)
          this.video_type = 1
        } else if (res.data.code == 401) {
          this.$emit('loginOuts');
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      })
    },
    order_info_page(e) {
      this.order_page = e
      this.orderList()
    },
    sponsor_conference() {
      this.retrieval()
      this.$emit('openChat')
      this.dialogMaterial = true
    },
    //列表关键字查询
    change_name(e) {
      this.keyword_name = e
      this.orderList()
    },
    videodata(e) {
      if (this.$refs.videoim) {
        this.$refs.videoim.videodata(e)
      }
    }
  }
}
</script>
<style>
.title .el-input__inner {
  background-color: #E2EEF6;
}

.from_style .el-form-item:nth-child(4) .el-form-item__label {
  width: 24% !important;
}

.from_style .el-form-item:nth-child(4) .el-form-item__content {
  margin-left: 1rem !important;
}
</style>
<style scoped>
.con {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #F0F9FF;
  border: 1px #d6d8dc solid;
}

.title {
  font-size: 16px;
  padding: 2% 0 0 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  width: 96%;
  height: 65%;
  margin: 10px auto 0;
  border: 1px #dddddd solid;
  background-color: #fff;
}

.but {
  width: 96%;
  height: 10%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.history {
  width: 90%;
  margin: auto;
  padding: 1rem 0;
  border-bottom: 1px #ccc solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  padding: 2% 0;
  font-size: 1.05rem;
}

.con_img {
  width: 15px;
  height: 15px;
  margin-left: 15px;
}

.list_name {
  position: relative;
  width: 200px;
  height: 100%;
  background: #f0f9ff;
}

.list_name .el-menu-item {
  padding: 0 !important;
  height: 40px;
  line-height: 40px;
  width: 100% !important;
  min-width: 100% !important;
}

.list_name .el-submenu__title {
  padding: 0 !important;
  height: 3rem;
}

.list_name .is-opened .el-submenu__title i {
  color: #fff !important;
}

.list_name .el-menu {
  width: 90%;
  border: none !important;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 95vw;
  height: 70vh;
}
</style>
