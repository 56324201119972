<template>
    <div>
        <div style="width: 90%;height: 400px;margin: auto;border: 1px #dddddd solid;border-radius: 10px;">
            <div class="chat-container" ref="chatcontainer">
                <div v-for="(item,index) in chat_list" :key="index">
                    <div class="chat-item" v-bind:class="{ 'left': item.role === 'user', 'right': item.role === 'doctor' }">
                        <div style="display:flex;align-items:center;"
                             v-if="item.role === 'user' && item.msg_type === 'text'">
                            <img :src="doctor_pic" alt="头像" class="avatar">
                            <div class="message message-left" style="margin-left: 10px;">{{ item.content }}</div>
                        </div>
                        <div style="display:flex;align-items:center;"
                             v-if="item.role === 'doctor' && item.msg_type === 'text'">
                            <div class="message message-right" style="margin-right: 10px;">{{ item.content }}</div>
                            <img :src="user_pic" alt="头像" class="avatar">
                        </div>

                        <div style="display:flex;align-items:center;"
                             v-if="item.role === 'user' && item.msg_type === 'image'">
                            <img :src="doctor_pic" alt="头像" class="avatar">
                            <div class="message message-left" style="margin-left: 10px;">
                                <img :src="item.content" alt="图片" style="max-width: 100%;">
                            </div>
                        </div>
                        <div style="display:flex;align-items:center;"
                             v-if="item.role === 'doctor' && item.msg_type === 'image'">
                            <div class="message message-right" style="margin-right: 10px;">
                                <img :src="item.content" alt="图片" style="max-width: 100%;">
                            </div>
                            <img :src="user_pic" alt="头像" class="avatar">
                        </div>
                    </div>
                    <div class="chat-item" v-bind:class="{'centers': item.content === '问诊已结束,对方已下线'}">
                        <div style="display:flex;align-items:center;" v-if="item.msg_type === 'text' && item.content === '问诊已结束,对方已下线'">
                            <div class="message" style="color: #cccccc">-- 以下是新消息 --</div>
                        </div>
                    </div>
                </div>

            </div>
            <div id="im_sending" style="position: relative;" v-if="order_states === '2'">
                <el-input v-model="input" placeholder="请输入内容" style="width: 100%;" size="mini"></el-input>
                <label for="upload-btn" class="upload-btn">
                    <img src="@/assets/uploadimg.png" alt="图片"
                         style="max-width: 20px;position: absolute;top: 0.3rem;right: 5rem">
                </label>
                <input type="file" id="upload-btn" style="display: none;" ref="fileInput" @change="handleFileUpload">
                <el-button size="mini" style="position: absolute;top: 0;right: 0.1rem" type="primary" @click="sending">
                    发送
                </el-button>
            </div>
        </div>
        <div style="width: 100%;height: 100px;margin: 30px auto;display: flex;justify-content: center;align-items: center;background-color: #dddddd;">
            <el-button size="small" type="primary" @click="Pending" v-if="order_states == '2'">暂时挂起</el-button>
            <el-button size="small" type="primary" @click="endConsultation" v-if="order_states == '2'">结束问诊</el-button>
        </div>
    </div>
</template>
<script>
import {chatDinding, getChatRecordsShow, getReceiverImId, orderEdit, sendMassage, uploadFile} from "@/api";
import wsservice from "@/utils/im";

export default {
    name: 'userIm',
    props: {
        doctor_id: Number,
        order_id: Number,
        doctor_pic: String,
        user_pic: String,
        order_states: String
    },
    data() {
        return {
            input: '',
            user_id: '',
            user_role: '',
            client_id: '',
            user_im_id: '',
            doctor_im_id: '',
            chat_list: [],
            chat_msg_id: '',
        }
    },
    mounted() {
        this.user_id = localStorage.getItem('user_id');
        this.user_role = localStorage.getItem('user_role');
        this.client_id = localStorage.getItem('client_id');
        this.fetchClientId()
        this.chatHistory()
    },
    methods: {
      async fetchClientId() {
        try {
          const data = await wsservice(this); // 等待 Promise 解析
          if (data && data.data.client_id) {
            this.client_id = data.data.client_id;
            this.init()
          }
        } catch (error) {
          console.error("Error fetching client_id:", error);
        }
      },
        Pending(){
            this.$emit('Consultation');
        },
        //结束问诊
        endConsultation(){
            orderEdit({
                order_id: this.order_id,
                user_id: this.doctor_id,
                order_state: 5,
            }).then(res=>{
                if (res.data.code === 1) {
                    sendMassage({
                        role: this.user_role,
                        order_id: this.order_id,
                        msg_type: 'text',
                        sender_im_id: this.user_im_id,
                        receiver_im_id: this.doctor_im_id,
                        content: '问诊已结束,对方已下线',
                    }).then(res => {
                        if (res.data.code === "1") {
                            let array = {
                                id: res.data.data.chat_msg_id,
                                order_id: this.order_id,
                                role: this.user_role,
                                sender_im_id: this.user_im_id,
                                receiver_im_id: this.doctor_im_id,
                                msg_type: "text",
                                content: this.input,
                                create_time: '0',
                                update_time: '0',
                                delete_time: '0',
                            }
                            this.chat_list.push(array)
                            this.input = ''
                            this.scrollToBottom()
                            this.$message({
                                message: '问诊已结束',
                                type: 'success'
                            });
                            this.$emit('Consultation');
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //图片选择
        handleFileUpload(event) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            this.uploadFiles(formData);
        },
        //图片上传
        uploadFiles(formData) {
            console.log(formData, '234')
            uploadFile(formData).then(res => {
                if (res.data.code === 1) {
                    sendMassage({
                        role: this.user_role,
                        order_id: this.order_id,
                        msg_type: 'image',
                        sender_im_id: this.user_im_id,
                        receiver_im_id: this.doctor_im_id,
                        content: res.data.data.file_path,
                    }).then(res1 => {
                        if (res1.data.code === "1") {
                            let array = {
                                id: res1.data.data.chat_msg_id,
                                order_id: this.order_id,
                                role: this.user_role,
                                sender_im_id: this.user_im_id,
                                receiver_im_id: this.doctor_im_id,
                                msg_type: "image",
                                content: res.data.data.url,
                                create_time: '0',
                                update_time: '0',
                                delete_time: '0',
                            }
                            this.chat_list.push(array)
                            this.scrollToBottom()
                            console.log(this.chat_list, '上传')

                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },

        //获取自己的以及对方的im_id
        init() {
            chatDinding({
                user_id: this.user_id,
                role: this.user_role,
                client_id: this.client_id,
            }).then(res => {
                if (res.data.code === "1") {
                    localStorage.setItem('im_id', res.data.data.im_id);
                    this.user_im_id = res.data.data.im_id
                    getReceiverImId({
                        user_id: this.doctor_id,
                        role: 'user',
                    }).then(res => {
                        if (res.data.code === "1") {
                            this.doctor_im_id = res.data.data.im_id
                            this.dialogMaterial = true
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //发送消息
        sending() {
            sendMassage({
                role: this.user_role,
                order_id: this.order_id,
                msg_type: 'text',
                sender_im_id: this.user_im_id,
                receiver_im_id: this.doctor_im_id,
                content: this.input,
            }).then(res => {
                if (res.data.code === "1") {
                    let array = {
                        id: res.data.data.chat_msg_id,
                        order_id: this.order_id,
                        role: this.user_role,
                        sender_im_id: this.user_im_id,
                        receiver_im_id: this.doctor_im_id,
                        msg_type: "text",
                        content: this.input,
                        create_time: '0',
                        update_time: '0',
                        delete_time: '0',
                    }
                    this.chat_list.push(array)
                    this.input = ''
                    this.scrollToBottom()
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //获取聊天记录
        chatHistory() {
            getChatRecordsShow({
                page: 1,
                limit: 200,
                sender_user_id: this.user_id,
                sender_role: this.user_role,
                receiver_user_id: this.doctor_id,
                chat_msg_id: '',
            }).then(res => {
                if (res.data.code === "1") {
                    this.chat_list = res.data.data.data
                    this.scrollToBottom()
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        wssetdata(chat) {
            this.chat_list.push(chat)
            console.log('doctorim', chat)
            this.scrollToBottom()
        },
        //     设置滚动条
        scrollToBottom() {
            this.$nextTick(() => {
                // const scrollContainer = this.$refs.chatcontainer;
                // let height = this.chat_list.length * 55;
                // if (scrollContainer && height) {
                //     scrollContainer.scrollTop = height;
                // }
                const scrollContainer = this.$refs.chatcontainer;
                console.log(scrollContainer,scrollContainer.scrollTop,scrollContainer.scrollHeight)
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollContainer.scrollHeight;
                }
            });
        },
    }
}
</script>
<style>
#im_sending .el-input--mini .el-input__inner {
    padding-right: 23%;
}
</style>
<style scoped>
.left {
    justify-content: left;
}

.right {
    justify-content: right;
}

.centers{
    justify-content: center;
}

.chat-container {
    width: 100%;
    height: 93%;
    overflow-y: auto;
}

.chat-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
}

.chat-item .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.chat-item .message {
    padding: 8px;
    border-radius: 10px;
    max-width: 200px;
}

.chat-item .message-right {
    background-color: #4CAF50;
    color: white;
    align-self: flex-end;
}

.chat-item .message-left {
    background-color: #f1f1f1;
    color: black;
    align-self: flex-start;
}

.chat-input {
    width: 300px;
}

.send-btn {
    background-image: url("https://cdn-icons-png.flaticon.com/512/2931/2931456.png");
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

.upload-btn {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
    border: none;
    cursor: pointer;
}
</style>
