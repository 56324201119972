<template>
  <div style="height: 100vh">
    <div class="head">
      <div class="logo" style="">
        <img
          src="@/assets/myglogo.png"
          style="width: 2vw; height: 2vw"
          alt="Vue Image"
        />
        <span class="logo_text">名医馆</span>
      </div>
      <div style="width: 70%; display: flex; justify-content: flex-end">
        <div
          class="hover-effect"
          style="margin: 10px"
          @click="windowopen(item.value)"
          v-for="(item, index) in config_list_info.outside_the_chain"
          :key="index"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="userinfo" v-if="is_login === 1">
        <div style="display: flex; align-items: center">
          <div style="height: 30px">
            <img
              src="@/assets/doctor.png"
              style="width: 30px; height: 30px"
              alt="Vue Image"
            />
          </div>
          <div>
            <span class="userinfo_text" @click="login_role('doctor')"
              >我是医生</span
            >
          </div>
        </div>
        <div style="display: flex; align-items: center; padding-left: 20px">
          <div style="height: 30px">
            <img
              src="@/assets/patient.png"
              style="width: 30px; height: 30px"
              alt="Vue Image"
            />
          </div>
          <div>
            <span class="userinfo_text" @click="login_role('patient')"
              >我是患者</span
            >
          </div>
        </div>
      </div>
      <div class="userinfo" v-if="is_login === 2 && type === 2">
        <div
          class="quick_admin_login"
          v-if="admin_flag == 1"
          @click="adminLogin"
        >
          <img
            src="@/assets/computer.png"
            style="width: 30px; height: 30px; margin-right: 10px"
            alt="Vue Image"
          />
          后台登录
        </div>
        <el-popover :width="150" trigger="hover">
          <template #reference>
            <div
              style="
                cursor: pointer;
                display: flex;
                align-items: center;
                width: 170px;
              "
            >
              <img
                src="@/assets/people.png"
                style="width: 1.2vw; height: 1.2vw"
                alt="Vue Image"
              />
              <span class="userinfo_text">个人中心</span>
            </div>
          </template>
          <div style="width: 100%">
            <div
              style="
                cursor: pointer;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
              @click="MyIndex"
            >
              <span
                style="
                  margin-top: 2px;
                  font-size: 13px;
                  color: #666;
                  font-weight: 400;
                  padding: 5px;
                "
                >首页</span
              >
            </div>
            <div
              style="
                cursor: pointer;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
              @click="MyProfile"
            >
              <span
                style="
                  margin-top: 2px;
                  font-size: 13px;
                  color: #666;
                  font-weight: 400;
                  padding: 5px;
                "
                >我的资料</span
              >
            </div>
            <div
              style="
                cursor: pointer;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
              @click="PersonalProfile"
            >
              <span
                style="
                  margin-top: 2px;
                  font-size: 13px;
                  color: #666;
                  font-weight: 400;
                  padding: 5px;
                "
                >个人档案</span
              >
            </div>
            <div
              style="
                cursor: pointer;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
              @click="MyConsultation"
            >
              <span
                style="
                  margin-top: 2px;
                  font-size: 13px;
                  color: #666;
                  font-weight: 400;
                  padding: 5px;
                "
                >我的问诊</span
              >
            </div>
            <div
              style="
                cursor: pointer;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
              @click="MyDiagnosis"
            >
              <span
                style="
                  margin-top: 2px;
                  font-size: 13px;
                  color: #666;
                  font-weight: 400;
                  padding: 5px;
                "
                >我的诊疗</span
              >
            </div>
            <div
              style="
                cursor: pointer;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
              @click="MyfollowUp"
            >
              <span
                style="
                  margin-top: 2px;
                  font-size: 13px;
                  color: #666;
                  font-weight: 400;
                  padding: 5px;
                "
                >我的随访</span
              >
            </div>
            <div
              style="
                cursor: pointer;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
              @click="openquestionnaire"
            >
              <span
                style="
                  margin-top: 2px;
                  font-size: 13px;
                  color: #666;
                  font-weight: 400;
                  padding: 5px;
                "
                >完善资料</span
              >
            </div>
            <div
              style="
                cursor: pointer;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
              @click="OutLogin"
            >
              <span
                style="
                  margin-top: 2px;
                  font-size: 13px;
                  color: #666;
                  font-weight: 400;
                  padding: 5px;
                "
                >退出登录</span
              >
            </div>
          </div>
        </el-popover>
      </div>
      <div class="userinfo" v-if="is_login === 2 && type === 1">
        <div
          class="quick_admin_login"
          v-if="admin_flag == 1"
          @click="adminLogin"
        >
          <img
            src="@/assets/computer.png"
            style="width: 30px; height: 30px; margin-right: 10px"
            alt="Vue Image"
          />
          后台登录
        </div>
        <el-popover :width="150" trigger="hover">
          <template #reference>
            <div
              style="
                cursor: pointer;
                display: flex;
                align-items: center;
                width: 170px;
              "
            >
              <img
                src="@/assets/people.png"
                style="width: 1.2vw; height: 1.2vw"
                alt="Vue Image"
              />
              <span class="userinfo_text">个人中心</span>
            </div>
          </template>
          <div style="width: 100%">
            <div
              style="
                cursor: pointer;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
              @click="doctor_info"
            >
              <span
                style="
                  margin-top: 2px;
                  font-size: 13px;
                  color: #666;
                  font-weight: 400;
                  padding: 5px;
                "
                >个人资料</span
              >
            </div>
            <div
              style="
                cursor: pointer;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
              @click="isUpdatePass = true"
            >
              <span
                style="
                  margin-top: 2px;
                  font-size: 13px;
                  color: #666;
                  font-weight: 400;
                  padding: 5px;
                "
                >密码修改</span
              >
            </div>
            <div
              style="
                cursor: pointer;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
              @click="isUpdateMobile = true"
            >
              <span
                style="
                  margin-top: 2px;
                  font-size: 13px;
                  color: #666;
                  font-weight: 400;
                  padding: 5px;
                "
                >电话修改</span
              >
            </div>
            <div
              style="
                cursor: pointer;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
              @click="doctor_out"
            >
              <span
                style="
                  margin-top: 2px;
                  font-size: 13px;
                  color: #666;
                  font-weight: 400;
                  padding: 5px;
                "
                >退出登录</span
              >
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <div class="carousel" v-if="is_login === 1">
      <!--          :height="carouselHeight"-->
      <el-carousel :interval="5000" arrow="always" :height="'16.5vw'">
        <el-carousel-item v-for="(item, index) in images" :key="index">
          <img
            :src="item.image"
            alt="Carousel Image"
            class="full-width-image"
            @click="carousel(item.url)"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="menu-router-box" v-if="is_login === 2 && type === 1">
      <doctor-menu
        @menuIndex="menuIndex"
        :defaultType="defaultType"
      ></doctor-menu>
      <div class="menu-router-bg"></div>
    </div>
    <div class="menu-router-box" v-if="is_login === 2 && type === 2">
      <user-menu @menuIndex="menuIndex" :defaultType="defaultType"></user-menu>
      <div class="menu-router-bg"></div>
    </div>
    <div class="login_content">
      <!-- //没有登陆状态下选择角色的-->
      <not-logged
        v-if="is_login === 1 && click_role === 1"
        @login_role="login_role"
        @idex_doctor_info="idex_doctor_info"
      ></not-logged>

      <!-- //医生端登录-->
      <login-info
        @backInvitation_type="config_list"
        @loginUser="loginUser"
        v-if="is_login === 1 && type === 1 && click_role === 2"
      ></login-info>
      <template v-if="is_login === 2 && type === 1">
        <!-- //医生端菜单-->
        <!-- //医生端问诊资料-->
        <consult-mater
          v-if="menu === 1 && type === 1"
          @testrtcType_change="testrtcType_change"
          @call_assembly="call_assembly"
          ref="consultMater"
          @loginOuts="loginOuts"
        ></consult-mater>
        <!-- //医生端上传登记-->
        <upload-data
          v-if="menu === 2 && type === 1"
          @menuIndex="menuIndex"
          @loginOuts="loginOuts"
        ></upload-data>
        <!-- //医生端患者等级-->
        <patient-reg
          v-if="menu === 3 && type === 1"
          @menuIndex="menuIndex"
          @loginOuts="loginOuts"
        ></patient-reg>
        <!-- //医生端视频会议-->
        <video-conf
          v-if="menu === 4 && type === 1"
          @testrtcType_change="testrtcType_change"
          ref="videoConf"
          @participate_in="participate_in"
          @call_assembly="call_assembly"
          @openChat="openChat"
          @loginOuts="loginOuts"
        ></video-conf>
        <!-- //医生端患者诊疗-->
        <patient-diag
          v-if="menu === 5 && type === 1"
          @loginOuts="loginOuts"
        ></patient-diag>
        <!-- //医生端我的资料-->
        <doctor-profile
          v-if="menu === 99 && type === 1"
          @loginOuts="loginOuts"
        ></doctor-profile>
        <!-- //医生端工作量统计-->
        <work-loads
          v-if="menu === 6 && type === 1"
          @loginOuts="loginOuts"
        ></work-loads>
        <!-- //医生端论坛列表-->
        <!--                <forum-list v-if="menu === 7" @loginOuts="loginOuts"></forum-list>-->
        <!-- //医生端群组聊天-->
        <group-chat
          v-if="menu === 8 && type === 1"
          ref="groupChat"
          @tape="tape"
          @loginOuts="loginOuts"
        ></group-chat>
        <!-- 医生端医学论坛 -->
        <forum-comp
          v-if="menu === 7 && type === 1"
          @loginOuts="loginOuts"
        ></forum-comp>
        <!-- //医生端随访-->
        <follow-up
          v-if="menu === 9 && type === 1"
          @loginOuts="loginOuts"
        ></follow-up>
      </template>

      <!-- //用户端登录-->
      <login-patient
        @patientLogin="patientLogin"
        @loginOuts="loginOuts"
        v-if="is_login === 1 && type === 2 && click_role === 2"
      ></login-patient>

      <template v-if="is_login === 2 && type === 2">
        <!-- //用户端选择-->
        <patient-index
          v-if="choice_type === 2 && type === 2"
          @choice="choice"
        ></patient-index>
        <!-- //用户端中医-->
        <centre-doctor
          @testrtcType_change="testrtcType_change"
          @show-home="show_home"
          v-if="menu === 1 && choice_type === 1 && type === 2"
          @backPage="backPage"
          @call_assembly="call_assembly"
          @choice="choice"
          :menu_id="menu_id"
          ref="consultMater"
          @loginOuts="loginOuts"
        ></centre-doctor>
        <!-- //用户端医技-->
        <medical-technology
          @testrtcType_change="testrtcType_change"
          @show-home="show_home"
          v-if="menu === 2 && choice_type === 1 && type === 2"
          @backPage="backPage"
          @call_assembly="call_assembly"
          @choice="choice"
          :menu_id="menu_id"
          ref="consultMater"
          @loginOuts="loginOuts"
        ></medical-technology>
        <!-- //用户端个人中心-->
        <!--            <patient-login ref="patientLogin" @myDiagnosis="myDiagnosis" @perPro="perPro" @myConsultation="myConsultation" @loginOuts="loginOuts" v-if="menu === 9999 && choice_type === 1"></patient-login>-->
        <!-- //用户端个人档案-->
        <personal-profile
          v-if="menu === 8 && choice_type === 1 && type === 2"
          @loginOuts="loginOuts"
          @backPage="backPage"
        ></personal-profile>
        <!-- //用户端我的问诊-->
        <my-consultation
          @testrtcType_change="testrtcType_change"
          v-if="menu === 9 && choice_type === 1 && type === 2"
          @call_assembly="call_assembly"
          ref="consultMater"
          @loginOuts="loginOuts"
          @backPage="backPage"
        ></my-consultation>
        <!-- //用户端诊疗信息-->
        <diagnosis-info
          v-if="menu === 10 && choice_type === 1 && type === 2"
          :menu_id="menu_id"
          ref="consultMater"
          @loginOuts="loginOuts"
          @backPage="backPage"
        ></diagnosis-info>
        <!-- //用户端我的随访-->
        <follow-up
          v-if="menu === 11 && choice_type === 1 && type === 2"
          @loginOuts="loginOuts"
          @backPage="backPage"
        ></follow-up>
      </template>
    </div>

    <!--        <div style="width: 85%;height: 100%;">-->
    <!--            &lt;!&ndash; //用户端个人档案&ndash;&gt;-->
    <!--            <personal-profile v-if="menu === 8 && type === 2" @loginOuts="loginOuts"></personal-profile>-->
    <!--            &lt;!&ndash; //用户端我的问诊&ndash;&gt;-->
    <!--            <my-consultation @call_assembly="call_assembly" v-if="menu === 9 && type === 2" ref="consultMater" @loginOuts="loginOuts"></my-consultation>-->
    <!--            &lt;!&ndash; //用户端医技&ndash;&gt;-->
    <!--            <medical-technology @call_assembly="call_assembly" :menu_id="menu_id" v-if="menu === 2 && type === 2" ref="consultMater" @loginOuts="loginOuts"></medical-technology>-->
    <!--            &lt;!&ndash; //用户端中医&ndash;&gt;-->
    <!--            <centre-doctor @call_assembly="call_assembly" :menu_id="menu_id" v-if="menu === 1 && type === 2" ref="consultMater" @loginOuts="loginOuts"></centre-doctor>-->
    <!--            &lt;!&ndash; //用户端诊疗信息&ndash;&gt;-->
    <!--            <diagnosis-info :menu_id="menu_id" v-if="menu === 10 && type === 2" ref="consultMater" @loginOuts="loginOuts"></diagnosis-info>-->
    <!--        </div>-->
    <div class="ICP">
      <a class="a_style" :href="config_list_info.icp_record_num_url">{{
        config_list_info.icp_record_num
      }}</a>
      <a
        class="a_style"
        :href="config_list_info.web_public_security_record_url"
        >{{ config_list_info.web_public_security_record }}</a
      >
    </div>

    <!--        <el-dialog title="登录/注册" :visible.sync="userLogin" width="20%">-->
    <!--            <div style="width: 100%;height: 100px;margin: 30px auto;display: flex;justify-content: space-evenly;align-items: center;">-->
    <!--                <el-button size="small" style="width: 30%" type="primary" @click="login = true">登录</el-button>-->
    <!--                <el-button size="small" style="width: 30%" type="primary" @click="register = true">注册</el-button>-->
    <!--            </div>-->
    <!--        </el-dialog>-->
    <!--        <el-dialog title="账号登录" :visible.sync="login" width="30%">-->
    <!--            <div style="margin-top: 10%;text-align: center">-->
    <!--                <el-form ref="form">-->
    <!--                    <div v-if="login_type === 1">-->
    <!--                        <el-form-item class="no-border">-->
    <!--                            <el-input v-model="tels" placeholder="请输入手机号" prefix-icon="el-icon-phone-outline"-->
    <!--                                      style="width: 75%; border: none;"></el-input>-->
    <!--                        </el-form-item>-->
    <!--                        <el-form-item style="margin: 10% 0 0 0;border: none; padding: 0;">-->
    <!--                            <div style="display: flex;justify-content: center">-->
    <!--                                <el-input v-model="codes" placeholder="请输入验证码" prefix-icon="el-icon-mobile"-->
    <!--                                          style="width: 45%; border: none;"></el-input>-->
    <!--                                <el-button style="width: 30%;font-weight: bold;padding: 5px 10px;" type="primary"-->
    <!--                                           @click="startCountdown(1)">{{ codeText }}-->
    <!--                                </el-button>-->
    <!--                            </div>-->
    <!--                        </el-form-item>-->
    <!--                    </div>-->
    <!--                    <div v-else>-->
    <!--                        <el-form-item class="no-border">-->
    <!--                            <el-input v-model="tels1" placeholder="请输入手机号" prefix-icon="el-icon-phone-outline"-->
    <!--                                      style="width: 75%; border: none;" @blur="fill_password"></el-input>-->
    <!--                        </el-form-item>-->
    <!--                        <el-form-item style="margin: 10% 0 0 0;border: none; padding: 0;">-->
    <!--                            <el-input v-model="password1" placeholder="请输入密码" :show-password="true" prefix-icon="el-icon-lock" style="width: 75%; border: none;"></el-input>-->
    <!--                        </el-form-item>-->
    <!--                    </div>-->
    <!--                    <div style="margin: 10%;position: relative;">-->
    <!--                        <span style="text-align: left;position: absolute;right: 5%;top: 5%;cursor: pointer;font-size: 0.8rem;"><el-checkbox v-model="remember_password" :checked="remember_password" @change="is_password">记住密码</el-checkbox></span>-->
    <!--                    </div>-->
    <!--                    <el-form-item style="margin: 20% 0;">-->
    <!--                        <el-button style="width: 60%;font-weight: bold" type="primary" @click="determineLogin">-->
    <!--                            确定登陆-->
    <!--                        </el-button>-->
    <!--                    </el-form-item>-->
    <!--                </el-form>-->
    <!--            </div>-->
    <!--        </el-dialog>-->

    <!--        <el-dialog title="注册账号" :visible.sync="register" width="30%">-->
    <!--            <div style="margin-top: 10%;text-align: center">-->
    <!--                <el-form ref="form" :model="form">-->
    <!--                    <el-form-item class="no-border">-->
    <!--                        <el-input v-model="form.name" placeholder="请输入真实姓名" prefix-icon="el-icon-user"-->
    <!--                                  style="width: 75%; border: none;"></el-input>-->
    <!--                    </el-form-item>-->
    <!--                    <el-form-item class="no-border">-->
    <!--                        <el-input v-model="form.tel" placeholder="请输入手机号" prefix-icon="el-icon-phone-outline"-->
    <!--                                  style="width: 75%; border: none;"></el-input>-->
    <!--                    </el-form-item>-->
    <!--                    <el-form-item class="no-border">-->
    <!--                        <el-input v-model="form.password" placeholder="请输入密码" :show-password="true" prefix-icon="el-icon-lock" style="width: 75%; border: none;"></el-input>-->
    <!--                    </el-form-item>-->
    <!--&lt;!&ndash;                    <el-form-item style="margin: 5% 0 0 0;border: none; padding: 0;">&ndash;&gt;-->
    <!--&lt;!&ndash;                        <div style="display: flex;justify-content: center">&ndash;&gt;-->
    <!--&lt;!&ndash;                            <el-input v-model="form.code" placeholder="请输入验证码" prefix-icon="el-icon-mobile"&ndash;&gt;-->
    <!--&lt;!&ndash;                                      style="width: 45%; border: none;"></el-input>&ndash;&gt;-->
    <!--&lt;!&ndash;                            <el-button style="width: 30%;font-weight: bold;padding: 5px 10px;" type="primary"&ndash;&gt;-->
    <!--&lt;!&ndash;                                       @click="startCountdown(2)">{{ codeText }}&ndash;&gt;-->
    <!--&lt;!&ndash;                            </el-button>&ndash;&gt;-->
    <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                    </el-form-item>&ndash;&gt;-->
    <!--                    <el-form-item style="margin: 5% 0 0 0;border: none; padding: 0;">-->
    <!--                        <el-input v-model="form.id_number" placeholder="请输入身份证号码完成实名认证"-->
    <!--                                  prefix-icon="el-icon-bank-card" style="width: 75%; border: none;"></el-input>-->
    <!--                    </el-form-item>-->
    <!--                    <el-form-item style="margin: 10% 0;">-->
    <!--                        <el-button style="width: 60%;font-weight: bold" type="primary" @click="perfect">下一步-->
    <!--                        </el-button>-->
    <!--                    </el-form-item>-->
    <!--                </el-form>-->
    <!--            </div>-->
    <!--        </el-dialog>-->

    <el-dialog
      title="个人档案"
      append-to-body
      :visible.sync="perfectInfo"
      width="40%"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <!-- //完善个人档案|添加个人档案-->
      <add-files
        @loginOuts="loginOuts"
        @filesAddComplete="filesAddComplete"
      ></add-files>
    </el-dialog>

    <el-dialog
      title="视频会议邀请"
      :visible.sync="InviteType"
      width="30%"
      append-to-body
    >
      <div
        style="
          width: 100%;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          :src="user_pic"
          style="
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 50%;
          "
        />
      </div>
      <div
        style="
          width: 100%;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        {{ user_name }}的视频会议
      </div>
      <div
        style="
          width: 100%;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0 0 12px 12px;
        "
      >
        <div style="text-align: center" @click="cancel">
          <div
            style="
              width: 50px;
              height: 50px;
              background: #fa5151;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <i
              class="el-icon-close"
              style="font-size: 45px; color: #ffffff"
            ></i>
          </div>
          <div>取消</div>
        </div>
        <div style="text-align: center; margin-left: 3rem" @click="answer">
          <div
            style="
              width: 50px;
              height: 50px;
              background: #00b578;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <i
              class="el-icon-check"
              style="font-size: 45px; color: #ffffff"
            ></i>
          </div>
          <div>进入</div>
        </div>
      </div>
    </el-dialog>

    <!--//接起视频会议-->
    <draggable-resizable
      w="1100"
      :h="pageHeight / 2"
      :x="centerX"
      :y="centerY"
      v-if="InviteTypes"
      style="position: relative; z-index: 100"
      @mousedown.native.stop="onMouseDown"
      @touchstart.native.stop="onMouseDown"
      id="dragRef1"
      :class="minimize_type === 2 ? 'video_style' : ''"
    >
      <div
        v-if="InviteTypes"
        :class="minimize_type === 2 ? 'video_style' : ''"
        style="
          width: 100%;
          height: 95%;
          position: relative;
          background-color: #ffffff;
          box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
        "
      >
        <div
          style="
            width: 100%;
            height: 50px;
            border-bottom: 1px #ccc solid;
            display: flex;
            justify-content: space-between;
          "
        >
          <div style="display: flex; align-items: center">
            <div style="color: #000000; font-weight: bold; padding-left: 20px">
              {{ video_room_name }}发起的会议
            </div>
            <div style="color: #000000; font-weight: bold; padding-left: 30px">
              房间号: {{ video_room_id }}
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-right: 20px">
            <div class="con_img" @click="fullScreen('dragRef1')">
              <img
                src="@/assets/drag1.png"
                style="width: 100%; height: 100%"
                alt="Vue Image"
              />
            </div>
            <div class="con_img" @click="minimize" v-if="!isFullScreen">
              <img
                src="@/assets/miniaturization1.png"
                style="width: 120%; height: 120%"
                alt="Vue Image"
              />
            </div>
            <div class="con_img" @click="close_video">
              <img
                src="@/assets/close_video.png"
                style="width: 120%; height: 120%"
                alt="Vue Image"
              />
            </div>
          </div>
        </div>
        <!--              自己创建的视频会议-->
        <ConferenceMainView></ConferenceMainView>
        <!--                <Invite ref="videoConf1" :isEnter="testrtcType"></Invite>-->
      </div>
    </draggable-resizable>

    <!--//发起视频会议-->
    <draggable-resizable
      w="1100"
      :h="pageHeight / 2"
      :x="centerX"
      :y="centerY"
      v-if="testrtcType"
      id="dragRef2"
      style="position: relative; z-index: 100"
      @mousedown.native.stop="onMouseDown"
      @touchstart.native.stop="onMouseDown"
      :class="minimize_type_v === 2 ? 'video_style' : ''"
    >
      <div
        v-if="testrtcType"
        :class="minimize_type_v === 2 ? 'video_style' : ''"
        style="
          width: 100%;
          height: 95%;
          position: absolute;
          top: 0;
          background-color: #ffffff;
          box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
        "
        ref="dragRef2"
      >
        <div
          style="
            width: 100%;
            height: 50px;
            border-bottom: 1px #ccc solid;
            display: flex;
            justify-content: space-between;
          "
        >
          <div style="display: flex; align-items: center">
            <div style="color: #000000; font-weight: bold; padding-left: 20px">
              {{ video_room_name }}发起的会议
            </div>
            <div style="color: #000000; font-weight: bold; padding-left: 30px">
              房间号: {{ video_room_id }}
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-right: 20px">
            <div class="con_img" @click="fullScreen('dragRef2')">
              <img
                src="@/assets/drag1.png"
                style="width: 100%; height: 100%"
                alt="Vue Image"
              />
            </div>
            <div class="con_img" @click="minimize" v-if="!isFullScreen">
              <img
                src="@/assets/miniaturization1.png"
                style="width: 120%; height: 120%"
                alt="Vue Image"
              />
            </div>
            <div class="con_img" @click="close_video">
              <img
                src="@/assets/close_video.png"
                style="width: 120%; height: 120%"
                alt="Vue Image"
              />
            </div>
          </div>
        </div>
        <ConferenceMainView></ConferenceMainView>
        <!--                <Testrtc ref="videoim"></Testrtc>-->
      </div>
    </draggable-resizable>
    <div
      @click="maximum"
      v-if="InviteTypes && minimize_type === 2"
      style="
        z-index: 101;
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 15%;
        right: 0;
        background-color: #0e92dd;
        border-radius: 10px 0 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <img src="@/assets/video_mini.png" style="width: 50%; height: 50%" />
    </div>

    <el-dialog
      :visible.sync="isJoinRoom"
      title="进入会议室"
      width="30%"
      append-to-body
    >
      <span>是否马上进入会议房间？</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="enterMeeting" type="primary">马上进入</el-button>
        <el-button @click="closeDialog">稍后进入</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="isJoinRoomIn"
      title="进入会议室"
      width="30%"
      append-to-body
    >
      <span>是否马上进入会议房间？</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="enterMeetingIn" type="primary">马上进入</el-button>
        <el-button @click="closeDialogIn">稍后进入</el-button>
      </div>
    </el-dialog>

    <div
      @click="maximum"
      v-if="testrtcType && minimize_type_v === 2"
      style="
        z-index: 101;
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 15%;
        right: 0;
        background-color: #0e92dd;
        border-radius: 10px 0 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <img src="@/assets/video_mini.png" style="width: 50%; height: 50%" />
    </div>
    <!--        <el-dialog title="视频会议" :visible.sync="InviteTypes" width="60%" :close-on-press-escape="false" :close-on-click-modal="false">-->
    <!--            <Invite ref="videoConf"></Invite>-->
    <!--        </el-dialog>-->

    <el-dialog
      title="视频通话11111"
      style="z-index: 4"
      :visible.sync="InviteTypes_user"
      width="50%"
      append-to-body
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <Invites ref="videoConf"></Invites>
    </el-dialog>

    <!--//医生端修改密码-->
    <el-dialog
      title="修改密码"
      :visible.sync="isUpdatePass"
      width="30%"
      append-to-body
    >
      <el-form ref="form">
        <div style="text-align: center">
          <el-form-item class="no-border">
            <el-input
              v-model="tels"
              placeholder="请输入手机号"
              prefix-icon="el-icon-phone-outline"
              style="width: 75%; border: none"
            ></el-input>
          </el-form-item>
          <!--                    <el-form-item style="margin: 10% 0 0 0;border: none; padding: 0;">-->
          <!--                        <div style="display: flex;justify-content: center">-->
          <!--                            <el-input v-model="codes" placeholder="请输入验证码" prefix-icon="el-icon-mobile" style="width: 45%; border: none;"></el-input>-->
          <!--                            <el-button style="width: 30%;font-weight: bold;padding: 5px 10px;font-size: 1.2rem" type="primary" @click="startCountdown(2)">{{ codeText }}</el-button>-->
          <!--                        </div>-->
          <!--                    </el-form-item>-->
          <el-form-item style="margin: 10% 0 0 0; border: none; padding: 0">
            <el-input
              v-model="id_number"
              placeholder="请输入身份证号"
              prefix-icon="el-icon-user"
              style="width: 75%; border: none"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin: 10% 0 0 0; border: none; padding: 0">
            <el-input
              v-model="passWord"
              placeholder="请输入密码"
              :show-password="true"
              prefix-icon="el-icon-lock"
              style="width: 75%; border: none"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item style="margin: 5% 0; text-align: center">
          <el-button
            style="width: 60%; font-weight: bold"
            type="primary"
            @click="doctor_update"
            >确定修改</el-button
          >
        </el-form-item>
      </el-form>
      <div style="width: 100%; height: 20px"></div>
    </el-dialog>
    <!--//医生端修改手机号码-->
    <el-dialog
      title="修改手机号码"
      :visible.sync="isUpdateMobile"
      width="30%"
      append-to-body
    >
      <el-form ref="form">
        <div style="text-align: center">
          <el-form-item class="no-border">
            <el-input
              v-model="tel"
              placeholder="请输入手机号"
              prefix-icon="el-icon-phone-outline"
              style="width: 75%; border: none"
            ></el-input>
          </el-form-item>
          <!--                    <el-form-item style="margin: 10% 0 0 0;border: none; padding: 0;">-->
          <!--                        <div style="display: flex;justify-content: center">-->
          <!--                            <el-input v-model="code" placeholder="请输入验证码" prefix-icon="el-icon-mobile" style="width: 45%; border: none;"></el-input>-->
          <!--                            <el-button style="width: 30%;font-weight: bold;padding: 5px 10px;" type="primary" @click="startCountdown(1)">{{ codeText }}</el-button>-->
          <!--                        </div>-->
          <!--                    </el-form-item>-->
        </div>
        <el-form-item style="margin: 5% 0; text-align: center">
          <el-button
            style="width: 60%; font-weight: bold"
            type="primary"
            @click="doctor_tel"
            >确定修改</el-button
          >
        </el-form-item>
      </el-form>
      <div style="width: 100%; height: 20px"></div>
    </el-dialog>

    <!-- 用户端我的资料-->
    <el-dialog
      title="个人资料"
      :visible.sync="user_perfectInfo"
      append-to-body
      width="30%"
    >
      <div style="margin-top: 1%" id="userinfo">
        <div
          style="
            width: 90%;
            height: 300px;
            border: 1px #dddddd solid;
            border-radius: 10px;
            margin: auto;
            text-align: left;
          "
        >
          <div
            style="
              width: 95%;
              height: 20%;
              display: flex;
              align-items: center;
              justify-content: center;
              border-bottom: 1px #dddddd solid;
              margin: auto;
            "
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <span style="width: 40px; font-size: 12px; white-space: nowrap"
                >姓名：</span
              >
              <el-input
                v-model="name"
                placeholder="请输入姓名"
                style="width: 60%; height: 70%; padding: 0 3px !important"
                size="mini"
              ></el-input>
            </div>
            <div
              style="
                margin-left: 2px;
                width: 15%;
                height: 70%;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <el-select
                v-model="value"
                placeholder="性别"
                style="width: 80px"
                size="mini"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <span style="width: 40px; font-size: 12px; white-space: nowrap"
                >年龄：</span
              >
              <el-input
                v-model="age"
                placeholder="请输入年龄"
                style="width: 40%; height: 70%"
                size="mini"
              ></el-input>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80%;
              "
            >
              <span style="width: 60px; font-size: 12px; white-space: nowrap"
                >身份证：</span
              >
              <el-input
                v-model="up_number"
                placeholder="请输入年龄"
                style="width: 80%; height: 70%"
                size="mini"
              ></el-input>
            </div>
          </div>
          <div style="margin: 2% 0 2% 2%; text-align: left">
            <span
              style="
                padding: 1%;
                font-size: 15px;
                font-weight: 400;
                border-left: 2px #0e92dd solid;
              "
              >个人档案信息</span
            >
          </div>
          <div style="height: 200px; overflow-y: auto">
            <div
              style="
                font-size: 15px;
                padding: 5px;
                word-wrap: break-word;
                overflow-wrap: break-word;
              "
              v-for="(item, index) in userFilesList"
              :key="index"
            >
              <span>{{ index + 1 }}. </span>
              <span> {{ item.create_time }} </span>
              <span> {{ item.disease.name }} </span>
              <span>
                <el-button
                  style="
                    width: 12%;
                    font-weight: bold;
                    margin: 3px 0;
                    padding: 3px 0;
                  "
                  type="primary"
                  @click="informationDetail(item.disease)"
                  >详情</el-button
                >
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 95%; height: 150px; margin: auto">
        <div
          style="
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
          "
        >
          <div
            style="width: 26%; height: 85%"
            v-for="(item, index) in userAnnexList.slice(0, 2)"
            :key="index"
          >
            <!--                        <img style="width: 100%;height: 100%;" :src="item.file_url">-->
            <el-image
              style="width: 100%; height: 100%"
              :src="item.file_url"
              :preview-src-list="[item.file_url]"
            >
            </el-image>
          </div>
          <div
            style="
              width: 26%;
              height: 85%;
              border: 1px #dddddd solid;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              justify-content: center;
            "
            v-if="userAnnexList.length >= 3"
          >
            <div
              style="
                font-size: 15px;
                font-weight: bolder;
                width: 100%;
                text-align: center;
              "
              @click="look_more"
            >
              查看更多
            </div>
            <div @click="look_more">
              <i
                class="el-icon-plus"
                style="font-size: 40px; font-weight: bolder"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 20px 0;
        "
      >
        <el-button
          style="
            width: 20%;
            font-weight: bold;
            margin: 10px 1%;
            padding: 5px 0;
            color: #0e92dd;
            background-color: #e6f1fc;
          "
          @click="updateUser"
          >修改</el-button
        >
      </div>
    </el-dialog>

    <!--//查看更多附件-->
    <el-dialog title="查看更多" :visible.sync="lookMore" width="30%">
      <div
        style="
          width: 90%;
          height: 500px;
          overflow-y: auto;
          padding: 20px;
          white-space: normal;
          margin: auto;
        "
      >
        <div
          style="width: 150px; height: 150px; float: left; padding: 10px"
          v-for="(item, index) in userAnnexList"
          :key="index"
        >
          <el-image
            style="width: 100%; height: 100%"
            :src="item.file_url"
            :preview-src-list="[item.file_url]"
          >
          </el-image>
        </div>
      </div>
    </el-dialog>

    <!--//档案信息详情-->
    <el-dialog
      title="疾病信息详情"
      :visible.sync="informationDetails"
      append-to-body
      width="30%"
    >
      <div
        style="
          width: 90%;
          height: 500px;
          overflow-y: auto;
          padding: 20px;
          white-space: normal;
          margin: auto;
        "
        v-html="filesText"
      ></div>
    </el-dialog>

    <!--//档案信息详情-->
    <el-dialog
      title="医生个人信息"
      :visible.sync="doctor_info_type"
      width="30%"
    >
      <div style="margin: 2rem; padding-bottom: 2rem">
        <div style="padding-bottom: 0.6rem">
          姓名: {{ doctor_info_array.name }}
        </div>
        <div style="padding-bottom: 0.6rem">
          手机号: {{ doctor_info_array.tel }}
        </div>
        <div style="padding-bottom: 0.6rem">
          称谓: {{ doctor_info_array.title }}
        </div>
        <div style="padding-bottom: 0.6rem">
          简介:
          <div style="padding-bottom: 0.6rem" v-html="doctor_info_array.intro">
            简介
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 完善资料 -->
    <el-dialog
      title="个人资料"
      label-position="left"
      :visible.sync="user_questionnaire"
      append-to-body
      width="60%"
    >
      <el-form
        :model="questList"
        class="questionnaire_form"
        ref="questionnaire_form"
      >
        <el-form-item :label="v.name" v-for="(v, k) of questList" :key="k">
          <el-form-item
            :prop="`${k}.children.${key}.value`"
            :rules="{
              required: true,
              message: `请${parseInt(val.type) === 1 ? '选择' : '输入'}${
                val.label
              }`,
              trigger: 'blur',
            }"
            v-for="(val, key) of v.children"
            :key="key"
            :label="val.label"
            class="questionnaire_form_items"
          >
            <template v-if="parseInt(val.type) === 1">
              <el-radio-group v-model="val.value">
                <el-radio
                  v-for="(qv, qk) of JSON.parse(val.content)"
                  :key="qk"
                  :label="qv.label"
                  >{{ qv.label }}</el-radio
                >
              </el-radio-group>
            </template>
            <template v-else-if="parseInt(val.type) === 2">
              <el-input
                v-model="val.value"
                autocomplete="off"
                placeholder="请输入"
                clearable
              />
            </template>
          </el-form-item>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="user_questionnaire = false">取 消</el-button>
        <el-button type="primary" @click="submitQuestionnaireForm"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 首页医生详情 -->
    <el-dialog
      title="个人资料"
      label-position="left"
      :visible.sync="doctorName_type"
      append-to-body
      width="60%"
    >
      <div
        style="
          width: 95%;
          height: 80%;
          margin: 3% auto;
          display: flex;
          align-items: center;
        "
      >
        <div style="width: 30%; height: 90%; border-radius: 12px">
          <img
            :src="doctorName.pic"
            alt="Carousel Image"
            class="full-width-image"
            style="width: 100%; object-fit: cover; border-radius: 12px 12px 0 0"
          />
        </div>
        <div style="width: 70%; height: 90%">
          <div>
            <span
              style="font-size: 18px; color: #0e92dd; margin-left: 1.5rem"
              >{{ doctorName.name }}</span
            >
            <span
              style="font-size: 13px; font-weight: bold; margin-left: 1.5rem"
              >{{ doctorName.title }}</span
            >
          </div>
          <div style="display: flex; margin-top: 1rem; margin-left: 1rem">
            <div style="">
              <span class="tag" v-for="item in doctorName.label">{{
                item
              }}</span>
            </div>
          </div>
          <div style="width: 100%; height: 60%">
            <div style="height: 1rem"></div>
            <div
              style="margin-left: 1.5rem; font-size: 15px; padding: 1rem 0"
              v-if="doctorName.intro !== ''"
              v-html="doctorName.intro"
            ></div>
            <div style="margin-left: 1.5rem; font-size: 10px" v-else>
              暂无简介
            </div>
          </div>

          <div
            style="
              margin: 2rem;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <span class="badge" v-if="doctorName.is_appointment == '1'">
              <div>
                <img
                  src="@/assets/one.png"
                  alt="Carousel Image"
                  class="full-width-image"
                  style="
                    width: 15px;
                    height: 15px;
                    object-fit: cover;
                    border-radius: 12px 12px 0 0;
                  "
                />
              </div>
              <div>在线问诊</div>
            </span>
            <span class="badge" v-if="doctorName.is_ol_diagnose == '1'">
              <div>
                <img
                  src="@/assets/two.png"
                  alt="Carousel Image"
                  class="full-width-image"
                  style="
                    width: 15px;
                    height: 15px;
                    object-fit: cover;
                    border-radius: 12px 12px 0 0;
                  "
                />
              </div>
              <div>视频问诊</div>
            </span>
            <span class="badge" v-if="doctorName.is_video_diagnose == '1'">
              <div>
                <img
                  src="@/assets/treen.png"
                  alt="Carousel Image"
                  class="full-width-image"
                  style="
                    width: 15px;
                    height: 15px;
                    object-fit: cover;
                    border-radius: 12px 12px 0 0;
                  "
                />
              </div>
              <div>预约</div>
            </span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="doctorName_type = false">取 消</el-button>
      </div>
    </el-dialog>

    <div
      v-if="is_audio === 1"
      style="
        width: 100%;
        height: 100vh;
        opacity: 0.8;
        background-color: black;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
      "
    >
      <div
        style="
          position: fixed;
          bottom: 10%;
          left: 50%;
          transform: translateX(-50%);
          width: 50%;
          margin: auto;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 30%;
            margin: auto;
          "
        >
          <div
            @click="close_tape"
            style="
              width: 40px;
              height: 40px;
              background: #ffffff;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <i class="el-icon-close" style="font-size: 30px"></i>
          </div>
          <div style="text-align: center; width: 70%; height: 10%">
            <div
              style="
                position: relative;
                width: 100px;
                height: 100px;
                background-color: #5dc1ff;
                border-radius: 50%;
                border: 8px #d3ecff solid;
                margin: auto;
              "
            >
              <img
                src="@/assets/yyy.gif"
                style="
                  width: 90%;
                  height: auto;
                  position: absolute;
                  left: 50%;
                  top: 20%;
                  transform: translateX(-50%);
                "
                alt="Vue Image"
              />
              <img
                src="@/assets/mai1.png"
                style="
                  width: 50%;
                  height: auto;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translateX(-50%);
                "
                alt="Vue Image"
              />
            </div>
            <!--  <img src="@/assets/yin.png" style="width: 50%;height: 100%" alt="Vue Image">-->
          </div>
          <div
            @click="send_tape"
            style="
              width: 40px;
              height: 40px;
              background: #ffffff;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <i class="el-icon-check" style="font-size: 30px"></i>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Invite from "@/views/Invite.vue";
import Invites from "@/views/Invites.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import consultMater from "@/components/consultMater.vue";
import loginInfo from "@/components/loginInfo.vue";
import doctorMenu from "@/components/doctorMenu.vue";
import customMenu from "@/components/customMenu.vue";
import userInfo from "@/components/userInfo.vue";
import workLoads from "@/components/workLoads.vue";
import uploadData from "@/components/uploadData.vue";
import patientReg from "@/components/patientReg.vue";
import videoConf from "@/components/videoConf.vue";
import patientDiag from "@/components/patientDiag.vue";
import patientLogin from "@/components/patientLogin.vue";
import patientUser from "@/components/patientUser.vue";
import patientUser_img from "@/components/patientUser_img.vue";
import patientMenu from "@/components/patientMenu.vue";
import patientMenu_img from "@/components/patientMenu_img.vue";
import personalProfile from "@/components/personalProfile.vue";
import myProfiles from "@/components/myProfiles.vue";
import myConsultation from "@/components/myConsultation.vue";
import medicalTechnology from "@/components/medicalTechnology.vue";
import centreDoctor from "@/components/centreDoctor.vue";
import addFiles from "@/components/addFiles.vue";
import diagnosisInfo from "@/components/diagnosisInfo.vue";
import notLogged from "@/components/notLogged.vue";
import loginPatient from "@/components/loginPatient.vue";
import patientIndex from "@/components/patientIndex.vue";
import forumList from "@/components/forumList.vue";
import groupChat from "@/components/groupChat.vue";
import forumComp from "@/components/forumComp.vue";
import followUp from "@/components/followUp.vue";
import userMenu from "@/components/userMenu.vue";
import DraggableResizable from "vue-draggable-resizable";
import { Back_Web_Url } from "@/api";
import {
  ConferenceMainView,
  conference,
  RoomEvent,
  FeatureButton,
} from "@tencentcloud/roomkit-web-vue2.7";

import {
  getMenuList,
  pwdLogin,
  register,
  sendSmS,
  telLogin,
  user,
  carouselImages,
  config,
  getUserMeetingRecordsList,
  delUserMeetingRecordsList,
  loginOut,
  resetPassword,
  editTel,
  medicalRecords,
  attachment,
  userEdit,
  chatDinding,
  doctorInfo,
  meeting_recordsShow,
  recordsEdit,
  orderShow,
  getQuestList,
  editUserQuest,
} from "@/api";
import wsservice from "@/utils/im.js";
import genTestUserSig from "@/debug/GenerateTestUserSig-es.js";
import DoctorProfile from "@/components/DoctorProfile.vue";
import ws from "@/components/mixins/ws.js";
export default {
  name: "home-index",
  components: {
    Breadcrumb,
    DraggableResizable,
    followUp,
    groupChat,
    forumComp,
    forumList,
    patientIndex,
    loginPatient,
    notLogged,
    Invite,
    Invites,
    DoctorProfile,
    consultMater,
    loginInfo,
    doctorMenu,
    customMenu,
    userInfo,
    workLoads,
    uploadData,
    patientReg,
    videoConf,
    patientDiag,
    patientLogin,
    patientUser,
    patientUser_img,
    patientMenu,
    patientMenu_img,
    personalProfile,
    myProfiles,
    myConsultation,
    medicalTechnology,
    centreDoctor,
    addFiles,
    diagnosisInfo,
    userMenu,
    ConferenceMainView,
  },
  data() {
    return {
      doctorName: [], //医生详情
      questList: [],
      user_questionnaire: false,
      doctorName_type: false,
      isFullScreen: false,
      isOpenChat: false,
      isInviteLoaded: false,
      path: "http://localhost:5173",
      admin_flag: 0,
      is_audio: 2,
      value: "",
      up_number: "",
      name: "",
      age: "",
      user_info: [],
      username: "",
      userFilesList: [],
      userAnnexList: [],
      lookMore: false,
      informationDetails: false,
      options: [
        {
          value: "0",
          label: "保密",
        },
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
      ],
      filesText: "",
      user_perfectInfo: false,

      choice_type: 2, // 用户端选择 1为点击,2为未点击
      isUpdateMobile: false, //修改医生端手机号的弹窗
      tel: "", //修改医生端手机号的新手机号
      isUpdatePass: false, //修改医生端密码的弹窗
      id_number: "", //修改医生端密码的手机号
      passWord: "", //修改医生端密码的新密码
      InviteType: false,
      InviteTypes: false,
      tels1: "",
      password1: "",
      login_type: 2,
      images: [],
      carouselHeight: "400px",
      type_user_index: 0,
      type_user_index_zhong: 0,
      type: 0, //1为医生, 2为患者
      click_role: 1, //1为未点击,2为已点击  选择医生还是患者
      menu: 1, //1为医生端问诊资料
      is_login: 1, // 1：未登录，2：已登录
      userLogin: false,
      login: false,
      register: false,
      perfectInfo: false,
      form: {
        name: "",
        tel: "",
        id_number: "",
        password: "",
      },
      tels: "",
      codes: "",
      project1: "",
      project2: "",
      project3: "",
      project4: "",
      project5: "",
      token: "",
      //定时器
      timer: null,
      count: 60,
      //发送验证码按钮内容
      codeText: "获取验证码",
      codeButType: 1,
      //用户类型
      user_role: "",
      //一级菜单id
      menu_id: 1,
      im: null,
      defaultType: "1",
      SDKAppID: "",
      SecretKey: "",
      before_type: 1,
      call_assembly_type: 1,
      aaa: 0,
      en_user_name: "",
      video_type: 1,
      remember_password: false,
      user_id: "",
      client_id: "",
      user_name: "",
      user_pic: "",
      InviteTypes_user: false,
      doctor_info_type: false,
      doctor_info_array: [],
      // 获取页面宽度和高度
      pageWidth: window.innerWidth,
      pageHeight: window.innerHeight,
      video_room_id: "",
      video_room_name: "",
      //接起视频会议最小化
      minimize_type: 1,
      minimize_type_v: 1,
      testrtcType: false,
      isJoinRoom: false,
      isJoinRoomIn: false,
      config_list_info: [],
      is_lookInfo: true, //是否显示网站信息
    };
  },
  watch: {
    is_login(newVal, oldVal) {
      if (newVal === 2) {
        user().then((res) => {
          if (res.data.code === 1) {
            const age = this.calculateAge(res.data.data.id_number);
            if (age >= 60) {
                document.documentElement.style.setProperty('--fonts', '25px');
                this.$confirm('是否切换老年模式', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        document.documentElement.style.setProperty('--fonts', '25px');
                        this.$message({
                            type: 'success',
                            message: '已成功切换老年模式!'
                        });
                    }).catch(() => {
                        document.documentElement.style.setProperty('--fonts', 'none');  
                    });
            }
            } else {
                document.documentElement.style.setProperty('--fonts', 'none');
            }
          
        });
      }
    },
  },
  mounted() {
    this.im = wsservice(this);
    // 获取菜单激活值
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      const menuValue = urlParams.get("menu");
      this.menuIndex(menuValue);
      this.defaultType = menuValue;
    }
    // 这里模拟一些异步加载 Invite 组件的操作
    setTimeout(() => {
      this.isInviteLoaded = true;
    }, 1000); // 设置一个适当的延迟，以确保组件加载完成
    this.admin_flag = localStorage.getItem("admin_flag");
    this.updateCarouselHeight();
    this.user_id = localStorage.getItem("user_id");
    this.client_id = localStorage.getItem("client_id");
    this.click_role = localStorage.getItem("click_role");
    this.user_role = localStorage.getItem("user_role");
    this.en_user_name = localStorage.getItem("en_user_name");
    if (this.user_role !== null && this.user_role !== undefined) {
      if (this.user_role === "user") {
        this.type = 2;
      } else {
        this.type = 1;
      }
    }
    this.token = localStorage.getItem("token");
    if (this.token !== null && this.token !== undefined) {
      this.is_login = 2;
    } else {
      this.is_login = 1;
    }
    this.menuList();
    this.Carousel();
    console.log(
      this.is_login,
      this.type,
      this.click_role,
      888,
      this.user_role,
      this.type
    );
    this.config_list();
    console.log(
      this.is_login,
      this.type,
      this.click_role,
      888,
      this.user_role,
      this.type
    );
  },
  created() {
    document.title = "名医馆";
    this.remember_password = localStorage.getItem(this.tels1 + "pass");
    const user_id = localStorage.getItem("user_id");
    const intervalId = setInterval(() => {
      getUserMeetingRecordsList({
        cache_ids: this.user_role + user_id,
        type: 1,
      }).then((res) => {
        if (res.data.code === 1) {
          if (res.data.data.meeting_records_id !== undefined) {
            this.user_name = res.data.data.user_name;
            this.user_pic = res.data.data.user_pic;
            this.video_room_id = res.data.data.meeting_records_id;
            this.video_room_name = res.data.data.user_name;
            localStorage.setItem("is_type", 1);
            localStorage.setItem("userSig", res.data.data.user_sig);
            localStorage.setItem("userId", res.data.data.user_id);
            localStorage.setItem("roomid", res.data.data.meeting_records_id);
            if (res.data.data.type === 1) {
              this.video_type = 1;
            } else {
              this.video_type = 2;
            }
            this.InviteType = true;
          }
        }
      });
    }, 7500);
  },
  computed: {
    // 计算组件在屏幕中央的 x 坐标
    centerX() {
      // return this.pageWidth / 2 - 450;  // 900 是组件的宽
      return this.pageWidth / 2 - 550; // 900 是组件的宽
    },
    // 计算组件在屏幕中央的 y 坐标
    centerY() {
      // return (this.pageHeight) / 2 - 250;
      return this.pageHeight / -2 - 650 / 2;
    },
    // 计算组件在屏幕中央的 y 坐标
    centerY_v() {
      return this.pageHeight / -1.2;
    },
  },
  methods: {
    ...ws,
    fullScreen(e) {
      const el = document.querySelector(`#${e}`);
      // if (this.isFullScreen) {
      //   this.exitFullScreen(el)
      //   this.isFullScreen = false;
      // } else {
      //   this.enterFullScreen(el)
      //   this.isFullScreen = true;
      // }
      if (!this.isFullScreen) {
        el.style.width = "100vw";
        el.style.height = "100vh";
        el.requestFullscreen().then((res) => {
          this.isFullScreen = true;
        });
        // this.enterFullScreen(el)
      } else {
        el.style.width = "1100px";
        el.style.height = `${this.pageHeight / 2}px`;
        document.exitFullscreen().then((res) => {
          this.isFullScreen = false;
        });
        // this.exitFullScreen(el)
      }
    },
    enterFullScreen(el) {
      var rfs =
          el.requestFullscreen ||
          el.webkitRequestFullscreen ||
          el.mozRequestFullscreen ||
          el.msRequestFullscreen,
        wscript;
      if (typeof rfs != "undefined" && rfs) {
        rfs.call(el);
        return;
      }
      if (typeof window.ActiveXObject != "undefined") {
        wscript = new ActiveXObject("WScript.Shell");
        if (wscript) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    exitFullScreen() {
      const el = document;
      var cfs =
          el.cancelFullscreen ||
          el.webkitCancelFullscreen ||
          el.mozCancelFullscreen ||
          el.exitFullscreen,
        wscript;
      if (typeof cfs != "undefined" && cfs) {
        cfs.call(el);
        return;
      }
      if (typeof window.ActiveXObject != "undefined") {
        wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    openChat() {
      this.isOpenChat = true;
    },
    show_home() {
      this.menu = 1;
      if (this.type == 1) {
      } else if (this.type == 2) {
        this.choice_type = 2;
      }
    },
    config_list() {
      if (this.user_role === "user") {
        this.patientLogin();
      } else {
        this.loginUser();
      }
      config().then((res) => {
        if (res.data.code === 1) {
          this.SDKAppID = this.decrypt(res.data.data.sdk_app_id);
          this.SecretKey = this.decrypt(res.data.data.sdk_secret_key);
          localStorage.setItem("SDKAppIDs", this.SDKAppID);
          localStorage.setItem("SecretKeys", this.SecretKey);
          console.log(111, this.user_role);
          this.backInvitation_type();
          this.config_list_info = res.data.data;
          if (this.user_id === null) {
            this.is_lookInfo = false;
            return;
          } else {
            this.is_lookInfo = true;
          }
          try {
            const { userSig } = genTestUserSig({
              userID:
                this.en_user_name +
                "_" +
                this.user_role +
                "_" +
                this.user_id.toString(),
              SDKAppID: Number(this.SDKAppID),
              SecretKey: this.SecretKey,
            });

            this.userSig = userSig;
            // 初始化
            conference.login({
              // 获取 sdkAppId 可参考文档开通服务部分，https://cloud.tencent.com/document/product/647/104842
              sdkAppId: Number(this.SDKAppID),
              // 用户在您业务中的唯一标示 Id
              userId:
                this.en_user_name +
                "_" +
                this.user_role +
                "_" +
                this.user_id.toString(),
              userSig: userSig,
            });
          } catch (error) {
            this.$message({
              message: error,
              type: "warning",
            });
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    adminLogin() {
      pwdLogin({
        tel: localStorage.getItem("tel"),
        password: localStorage.getItem("password"),
        flag: localStorage.getItem("admin_flag"),
        role: "doctor",
        doctorId: localStorage.getItem("user_id"),
      }).then((res) => {
        if (res.data.code === 1) {
          const token = res.data.data.token;
          const userinfo = res.data.data.userinfo;
          window.open(
            Back_Web_Url + "/admin/#/login?test=1&token=" + token,
            "_blank"
          );
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    // 马上进入会议
    async enterMeeting() {
      // 添加进入会议的逻辑
      this.isJoinRoom = false; // 关闭弹出框
      this.testrtcType = true;
      conference.hideFeatureButton(FeatureButton.SwitchTheme);
      conference.hideFeatureButton(FeatureButton.SwitchLayout);
      conference.hideFeatureButton(FeatureButton.SwitchLanguage);
      conference.hideFeatureButton(FeatureButton.Invitation);

      await conference.start(String(this.video_room_id), {
        roomName: this.video_room_name,
        isSeatEnabled: false,
        isOpenCamera: true,
        isOpenMicrophone: true,
      });

      // await conference.join(String(this.video_room_id), {
      //     isOpenCamera: false,
      //     isOpenMicrophone: false,
      // });
      // 退出会议回调
      conference.on(RoomEvent.ROOM_LEAVE, this.roomloginout);
      conference.on(RoomEvent.ROOM_DISMISS, this.roomloginout);
    },
    roomloginout() {
      this.testrtcType = false;
      this.InviteTypes = false;
      this.isOpenChat = false;
      conference.leave();
    },
    // 关闭弹出框
    closeDialog() {
      this.isJoinRoom = false; // 关闭弹出框
      this.testrtcType = true;
    },
    //医生端发起视频会议
    testrtcType_change(array) {
      if (array.video_room_type === 1) {
        this.video_room_id = array.video_room_id + 1;
      } else {
        this.video_room_id = array.video_room_id;
      }
      this.video_room_name = array.video_room_name;
      setTimeout(() => this.enterMeeting(), 1000);
    },
    //接起视频会议最小化
    minimize() {
      this.minimize_type = 2;
      this.minimize_type_v = 2;
    },
    //接起视频最大化
    maximum() {
      this.minimize_type = 1;
      this.minimize_type_v = 1;
    },
    //关闭视频窗口
    close_video() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.testrtcType = false;
          this.InviteTypes = false;
          this.isOpenChat = false;
          window.location.reload();
        })
        .catch((_) => {});
    },
    onMouseDown() {
      document.body.style.userSelect = "none";
      document.body.style.webkitUserSelect = "none"; // For Safari
      document.body.style.msUserSelect = "none"; // For IE
      document.body.style.mozUserSelect = "none"; // For Firefox
    },
    //医生端个人资料
    doctor_info() {
      doctorInfo({
        id: localStorage.getItem("user_id"),
      }).then((res) => {
        if (res.data.code === 1) {
          this.doctor_info_type = true;
          this.doctor_info_array = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        }
      });
    },
    //接听
    answer() {
      this.InviteType = false;
      this.InviteTypes = true;
      setTimeout(() => this.enterMeetingIn(), 1000);
    },
    //取消接听
    cancel() {
      this.InviteType = false;
    },
    //群组组件录音
    tape(e) {
      this.is_audio = e;
    },
    //修改个人资料
    updateUser() {
      userEdit({
        name: this.name,
        sex: this.value,
        age: this.age,
        id_number: this.up_number,
        user_id: this.user_id,
      }).then((res) => {
        if (res.data.code === 1) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        } else if (res.data.code == 401) {
          this.loginOuts();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //点击我是医生或者我是患者
    login_role(e) {
      this.click_role = 2;
      localStorage.setItem("click_role", this.click_role);
      if (e === "doctor") {
        this.type = 1;
      } else {
        this.type = 2;
      }
    },
    //获取当前时间
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // 月份从 0 开始，所以加 1
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    //首页医生详情
    idex_doctor_info(id) {
      doctorInfo({
        id: id,
        date: this.getCurrentDate(),
      }).then((res) => {
        if (res.data.code === 1) {
          this.doctorName = res.data.data;
          this.doctorName_type = true;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    //用户端选择
    choice(e) {
      this.choice_type = 1;
      this.type = 2;
      if (e === "chinese_doctor") {
        this.menu = 1;
        this.menu_id = 1;
      } else if (e === "doctor_technology") {
        this.menu = 2;
        this.menu_id = 2;
      } else {
        //妇幼
      }
    },
    //中医或医技返回首页
    backPage(target) {
      this.choice_type = 2;
      if (target && typeof target !== "object") {
        this.choice(target);
      }
    },
    carousel(url) {
      if (url !== "") {
        window.open(url, "_blank");
      }
    },
    call_assembly(e) {
      this.call_assembly_type = e;
      this.aaa = e + 1;
    },
    // 马上进入会议
    async enterMeetingIn() {
      try {
        // 在这里可以添加进入会议的逻辑
        // 比如跳转到会议页面等
        this.isJoinRoomIn = false; // 关闭弹出框
        this.InviteTypes = true;
        conference.hideFeatureButton(FeatureButton.SwitchTheme);
        conference.hideFeatureButton(FeatureButton.SwitchLayout);
        conference.hideFeatureButton(FeatureButton.SwitchLanguage);
        conference.hideFeatureButton(FeatureButton.Invitation);
        await conference.join(String(this.video_room_id), {
          isOpenCamera: false,
          isOpenMicrophone: false,
        });
        // 退出会议回调
        conference.on(RoomEvent.ROOM_LEAVE, this.roomloginout);
        conference.on(RoomEvent.ROOM_DISMISS, this.roomloginout);
      } catch (error) {
        console.log(error, "为什么", this.video_room_id);

        // this.InviteTypes = false
        this.$message({
          message: "正在加入房间请等待!",
          type: "success",
        });
        // location.reload();
      }
    },
    // 关闭弹出框
    closeDialogIn() {
      this.isJoinRoomIn = false; // 关闭弹出框
      this.InviteTypes = true;
    },
    // 加入会议
    participate_in(e) {
      localStorage.setItem("userSig", e.meeting_info.user_sig);
      localStorage.setItem("userId", e.meeting_info.user_id);
      localStorage.setItem("roomid", e.id);
      this.video_room_id = e.id;
      this.video_room_name = e.organizer_name;
      // this.isJoinRoomIn = true

      setTimeout(() => this.enterMeetingIn(), 1000);
    },
    // chinese_doctor(){
    //     this.type_user_index = 1
    //     this.type_user_index_zhong = 1
    // },
    // doctor_technology(){
    //     this.type_user_index = 2
    //     this.type_user_index_zhong = 2
    // },
    Carousel() {
      this.images = [];
      carouselImages().then((res) => {
        if (res.data.code === 1) {
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].is_show === "1") {
              const array = {
                image: res.data.data[i].image_url,
                url: res.data.data[i].links,
              };
              this.images.push(array);
            }
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    // 医生端退出登录
    doctor_out() {
      loginOut({
        role: "doctor",
      }).then((res) => {
        if (res.data.code === 1) {
          this.loginOuts();
          localStorage.removeItem("token");
          localStorage.removeItem("user_id");
          localStorage.removeItem("user_role");
        } else if (res.data.code == 401) {
          this.loginOuts();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    // 医生端修改密码
    doctor_update() {
      resetPassword({
        tel: this.tels,
        id_number: this.id_number,
        new_password: this.passWord,
      }).then((res) => {
        if (res.data.code === 1) {
          this.loginOuts();
          localStorage.removeItem("token");
          localStorage.removeItem("user_id");
          localStorage.removeItem("user_role");
          localStorage.removeItem(this.tels);
          localStorage.removeItem(this.tels + "pass");
          this.$message({
            message: "重置成功",
            type: "success",
          });
        } else if (res.data.code == 401) {
          this.loginOuts();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 医生端修改手机号
    doctor_tel() {
      editTel({
        tel: this.tel,
        code: this.code,
      }).then((res) => {
        if (res.data.code === 1) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.userInfo();
          this.isUpdateMobile = false;
          this.code = "";
          this.tel = "";
        } else if (res.data.code == 401) {
          this.loginOuts();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //我的资料
    MyProfile() {
      this.annexList();
      this.user_perfectInfo = true;
      this.filesList();
    },

    openquestionnaire() {
      getQuestList().then((res) => {
        if (res.data.code === 1) {
          this.questList = res.data.data || [];
          this.user_questionnaire = true;
        }
      });
    },
    submitQuestionnaireForm() {
      this.$refs.questionnaire_form.validate((valid) => {
        if (valid) {
          const submitData = JSON.stringify(
            Object.values(this.questList).map((v) => ({
              id: v.id,
              name: v.name,
              children: v.children.map((val) => {
                return { id: val.id, label: val.label, value: val.value };
              }),
            }))
          );

          const user_id = localStorage.getItem("user_id");

          editUserQuest({ user_id, info: submitData }).then((res) => {
            this.user_questionnaire = false;
          });
        }
      });
    },

    //用户端回到首页
    MyIndex() {
      this.choice_type = 2;
    },
    //获取个人档案列表
    filesList() {
      medicalRecords().then((res) => {
        if (res.data.code === 1) {
          this.userFilesList = res.data.data.data;
        } else if (res.data.code == 401) {
          this.loginOuts();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //获取患者附件列表
    annexList() {
      attachment({
        user_id: this.user_id,
      }).then((res) => {
        if (res.data.code === 1) {
          this.userAnnexList = res.data.data.data;
        } else if (res.data.code == 401) {
          this.loginOuts();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    look_more() {
      this.lookMore = true;
    },
    informationDetail(item) {
      this.informationDetails = true;
      this.filesText = item.intro;
    },
    //个人档案
    PersonalProfile() {
      this.menu = 8;
      this.choice_type = 1;
    },
    //我的问诊
    MyConsultation() {
      this.menu = 9;
      this.choice_type = 1;
    },
    forumInit() {
      this.menu = 7;
      this.choice_type = 1;
    },
    MyDiagnosis() {
      this.menu = 10;
      this.choice_type = 1;
    },
    //退出登录
    OutLogin() {
      loginOut({
        role: "user",
      }).then((res) => {
        if (res.data.code === 1) {
          this.loginOuts();
          localStorage.removeItem("token");
          localStorage.removeItem("user_id");
          localStorage.removeItem("user_role");
        } else if (res.data.code == 401) {
          this.loginOuts();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    menuList() {
      getMenuList().then((res) => {
        if (res.data.code === 1) {
          this.MenuList = res.data.data;
          this.selectedId = this.MenuList[0].id;
        } else if (res.data.code == 401) {
          this.loginOuts();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    decrypt(encodedStr) {
      return atob(encodedStr);
    },
    //初始化视频
    init(user_id, en_name, user_role) {
      config().then((res) => {
        if (res.data.code === 1) {
          this.SDKAppID = this.decrypt(res.data.data.sdk_app_id);
          this.SecretKey = this.decrypt(res.data.data.sdk_secret_key);
          try {
            const { userSig } = genTestUserSig({
              userID: en_name + "_" + user_role + "_" + user_id.toString(),
              SDKAppID: Number(this.SDKAppID),
              SecretKey: this.SecretKey,
            });
          } catch (error) {
            this.$message({
              message: error,
              type: "warning",
            });
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    //用户注册
    perfect() {
      register(this.form).then((res) => {
        if (res.data.code === 1) {
          this.userLogin = false;
          this.register = false;
          this.perfectInfo = true;
          this.is_login = 2;
          this.type = 2;
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("user_id", res.data.data.user_id);
          localStorage.setItem("user_role", res.data.data.user_role);
          localStorage.setItem("en_user_name", res.data.data.en_name);
          this.init(
            res.data.data.user_id,
            res.data.data.en_name,
            res.data.data.user_role
          );
        } else if (res.data.code == 401) {
          this.loginOuts();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    //获取验证码
    startCountdown(type) {
      if (this.codeButType === 1) {
        this.codeButType = 2;
        let phone = "";
        if (type === 1) {
          phone = this.tels;
        } else {
          phone = this.form.tel;
        }
        sendSmS({
          tel: phone,
        }).then((res) => {
          if (res.data.code === 1) {
            this.timer = setInterval(() => {
              this.count--;
              if (this.count <= 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.count = 60;
                this.codeText = "获取验证码";
                this.codeButType = 1;
              } else {
                this.codeText = `${this.count} 秒后重发`;
              }
            }, 1000);
          } else if (res.data.code == 401) {
            this.loginOuts();
          } else {
            this.codeButType = 1;
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      }
    },
    roletype(type) {
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_role");
      localStorage.removeItem("en_user_name");
      this.is_login = 1;
      this.type = type;
      this.type_user_index = 0;
      this.type_user_index_zhong = 0;
    },
    updateCarouselHeight() {
      this.carouselHeight = window.innerHeight / 3 + "px";
    },
    workload() {
      this.menu = 6;
    },
    myProfile() {
      this.menu = 99;
    },
    go_switch(e) {
      this.before_type = e;
      this.type_user_index = e;
      this.type_user_index_zhong = e;
    },
    menuIndex(message) {
      if (message == "Patient1") {
        return this.MyProfile();
      }
      if (message == 8) {
        return this.PersonalProfile();
      }
      if (message == 9) {
        return this.MyConsultation();
      }
      if (message == 10) {
        return this.MyDiagnosis();
      }
      if (message == 11) {
        return this.MyfollowUp();
      }
      if (message == 7) {
        this.forumInit();
      }
      // if(Number(message) === 7 && this.type === 1 && this.is_login === 2){
      //     // this.$router.push('/forum');
      //     window.open('/#/forum', '_blank');
      //     return
      // }

      this.menu = Number(message);
      // this.choice_type = 1;
      this.defaultType = String(message);
    },
    menuId(id) {
      this.menu_id = id;
    },
    //医生端登录
    loginUser() {
      this.is_login = 2;
      this.type = 1;
      this.menu = 1;
      this.click_role = 2;
    },
    //邀请入会
    backInvitation_type() {
      const roomId = this.$route.query.roomId;
      this.video_room_id = roomId;
      if (roomId !== undefined) {
        const token = localStorage.getItem("token");
        const user_id = localStorage.getItem("user_id");
        localStorage.setItem("Invitation_type", 2);
        if (token === null || user_id === null) {
          this.$message({
            message: "请先登录医生账号",
            type: "success",
          });
          return;
        } else {
          this.SDKAppID = localStorage.getItem("SDKAppIDs");
          this.SecretKey = localStorage.getItem("SecretKeys");
          meeting_recordsShow({
            id: roomId,
          }).then((res) => {
            if (res.data.code === 1) {
              this.video_room_id = roomId;
              this.video_room_name = res.data.data.organizer_name;
              setTimeout(() => this.enterMeetingIn(), 2000);
            } else {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
            }
          });
        }
      }
    },
    // 患者端登录
    patientLogin(type = false) {
      this.is_login = 2;
      this.type = 2;
      this.click_role = 2;
      if (type) {
        this.openquestionnaire();
      }
    },
    loginOut() {
      this.is_login = 1;
    },
    lookDoctor() {
      if (this.is_login === 1) {
        this.userLogin = true;
      }
    },
    lookDoctor1() {
      if (this.is_login === 1) {
        this.userLogin = true;
      }
    },
    loginOuts() {
      this.is_login = 1;
      this.click_role = 1;
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_role");
      localStorage.removeItem("en_user_name");
      localStorage.removeItem("client_id");
      localStorage.removeItem("cache_ids");
      this.config_list_info = [];
      this.roomloginout();
    },
    //完善档案成功回调
    filesAddComplete() {
      this.userLogin = false;
      this.register = false;
      this.login = false;
      this.perfectInfo = false;
      this.$message({
        message: "添加成功",
        type: "success",
      });
    },
    //我的随访
    MyfollowUp() {
      this.menu = 11;
      this.choice_type = 1;
    },
    //打开新页面
    windowopen(e) {
      window.open(e, "_blank");
    },
    //根据身份证号得到年龄
    calculateAge(idNumber) {
      const birthYear = parseInt(idNumber.substring(6, 10));
      const birthMonth = parseInt(idNumber.substring(10, 12));
      const birthDay = parseInt(idNumber.substring(12, 14));
      const today = new Date();
      let age = today.getFullYear() - birthYear;
      if (
        today.getMonth() + 1 < birthMonth ||
        (today.getMonth() + 1 === birthMonth && today.getDate() < birthDay)
      ) {
        age--;
      }
      return age;
    },
  },
};
</script>
<style>
.el-dialog {
  border-radius: 12px;
}

.el-dialog__header {
  border-radius: 12px 12px 0 0;
  background-color: #017ed8;
}

.el-dialog__title {
  color: #ffffff;
}

.el-dialog__body {
  padding: 0;
}

.questionnaire_form {
  padding: 10px;
  max-height: 400px;
  overflow-y: auto;
}

.questionnaire_form > .el-form-item {
  display: flex !important;
  flex-direction: column;
}

.questionnaire_form > .el-form-item > .el-form-item__label {
  text-align: left !important;
  font-weight: bold !important;
  font-size: 16px;
}

.questionnaire_form_items {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
</style>
<style scoped>
.el-menu {
  height: 100% !important;
}

.head {
  width: 100%;
  height: 3.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 65px;
  width: 7.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.8vw;
}

.logo_text {
  color: #0e92dd;
  font-size: 1.2vw;
  font-weight: 500;
}

.userinfo {
  height: 65px;
  width: 302px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*margin-right: 100px;*/
}

.userinfo .quick_admin_login {
  width: 170px;
  margin-right: 20px;
  color: #333;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.userinfo_text {
  color: #333;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.carousel {
  width: 100%;
  height: 10vw;
  z-index: 1;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.full-width-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login_content {
  margin-top: 17px;
  width: 100%;
  height: 33vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.ICP {
  margin-top: 20px;
  text-align: center;
}

.login_from {
  width: 19%;
  height: 96%;
  background-color: white;
  /*border: 1px #bbbbbb solid;*/
  text-align: center;
}

.con_img {
  width: 15px;
  height: 15px;
  margin-left: 15px;
}

.video_style {
  /*display: none;*/
  position: absolute;
  left: -9999px; /* 移出可视范围 */
}

.a_style {
  text-decoration: none;
  color: inherit;
  /* 或者指定你想要的颜色 */
}

.menu-router-box {
  position: relative;
}

.menu-router-bg {
  z-index: -1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 306px;
  background-image: url("../assets/image/top-bg.png");
  background-size: 100% 100%;
}
.badge {
  margin-left: 2%;
  background-color: #0e92dd;
  border-radius: 3px;
  padding: 2px 5px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: right;
}

.hover-effect {
  cursor: default; /* 默认鼠标样式 */
  color: initial; /* 默认文字颜色 */
  transition: color 0.3s ease; /* 添加颜色变化的过渡效果 */
}

.hover-effect:hover {
  cursor: pointer; /* 鼠标移入后变成小手样式 */
  color: blue; /* 鼠标移入后文字变成蓝色 */
}

.priority-message {
  z-index: 301 !important; /* 设置更高的 z-index 并使用 !important 确保覆盖默认值 */
}
</style>
<style>
:root {
    --fonts: none;
}
body {
    font-size: var(--fonts) !important;
}
.el-menu-item * {
    font-size: var(--fonts) !important;
}
th {
    font-size: var(--fonts) !important;
}
.el-menu-item {
    font-size: var(--fonts) !important;
}
div {
    font-size: var(--fonts) !important;
}
span {
    font-size: var(--fonts) !important;
}
label {
    font-size: var(--fonts) !important;
}
li {
    font-size: var(--fonts) !important;
}
.el-message-box__close {
    font-size: var(--fonts) !important;
}
</style>
