<template>
    <div class="content">
        <div class="list">
            <div class="list_name" style="overflow-y: auto">
                <el-menu
                    v-if="user_role !== 'user'"
                    style="width: 100%;"
                    @select="selectMenu"
                    background-color="#F0F9FF"
                    default-active="2"
                    class=""
                    @open="handleOpen"
                    @close="handleClose">
                    <el-submenu index="1">
                        <template slot="title">
                            <div style="text-indent: 1em;height: 3rem;line-height: 3rem;" :class="is_select_style === 2 ? 'select_style' : 'default_menu_type'">
                                <span>方案</span>
                            </div>
                        </template>
                        <el-menu-item-group>
                            <template slot="title"></template>
                            <el-menu-item index="1-1" style="text-indent: 2em;padding-left: 20px;width: 50%;">随访方案库</el-menu-item>
                            <el-menu-item index="1-2">
                                <span style="width: 75%;display: block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;text-indent: 2em;">
                                    新建自定义随访方案
                                </span>
                            </el-menu-item>
                            <div style="width: 96%;height: 3rem;display: flex;align-items: center;border-top: 2px #ABC2D3 solid;padding-left: 33px;margin-left: 10px; color: #305672; font-size: 14px;">我的方案</div>
                            <el-menu-item :index="'my-'+item.id" v-for="(item,index) in followUp_listAll" :key="index" style="text-indent: 2em;">
                                <span style="width: 75%;display: block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                                    {{ item.name }}
                                </span>
                            </el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-submenu index="2">
                        <template slot="title">
                            <div style="text-indent: 1em;height: 3rem;line-height: 3rem;" :class="is_select_style === 3 ? 'select_style' : 'default_menu_type'">
                                <span>患者</span>
                            </div>
                        </template>
                        <el-menu-item-group>
                            <template slot="title"></template>
                            <el-menu-item index="2-1" style="text-indent: 2em;">随访病人</el-menu-item>
                            <el-menu-item index="2-2" style="text-indent: 2em;">添加患者</el-menu-item>
                            <el-menu-item index="2-3" style="text-indent: 2em;">群发通知</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-menu-item style="text-indent: 1em;height: 3rem;line-height: 3rem;" index="3">
                        <template slot="title">
                            <div style="text-indent: 1em;height: 3rem;line-height: 3rem;" :class="is_select_style === 4 ? 'select_style' : 'default_menu_type'">
                                <span>患教</span>
                            </div>
                        </template>
                    </el-menu-item>
                </el-menu>
                <el-menu
                    v-if="user_role === 'user'"
                    style="width: 100%;"
                    @select="selectMenu"
                    background-color="#f0f4f7"
                    default-active="2"
                    class=""
                    @open="handleOpen"
                    @close="handleClose">
                    <el-menu-item index="3-1" style="text-indent: 1em;height: 3rem;line-height: 3rem;">我的随访</el-menu-item>
                    <el-menu-item index="3-2" style="text-indent: 1em;height: 3rem;line-height: 3rem;">我的消息</el-menu-item>
                </el-menu>
            </div>
            <div class="safe"></div>
        </div>
        <div class="con">
            <div style="width: 100%;height: 100%;" v-if="follow_type === 1">
                <div class="con_top" style="justify-content: space-between;">
                    <div class="sf-title">随访方案库</div>
                    <el-input
                        class="sf-search"
                        placeholder="搜索"
                        v-model="follow_Keywords"
                        @change="follow_search"
                        >
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                </div>
                <div class="con_below">
                    <div class="con_below_text" @click="select_plan(ite.id)" v-for="(ite,inde) in followUp_list" :key="inde">
                        <div style="font-weight: bold;font-size: 14px;width: 30vw;">
                            {{ ite.name }}
                        </div>
                        <div style="font-size: 14px;color: #919191;margin-left: auto">{{ ite.disease.name }} | {{ ite.doctor.name }}</div>
                        <div style="margin-left: auto">
                            <div style="color: #9d9d9d;font-weight: bold;" v-if="ite.pid === 0">未选用</div>
                            <div style="color: #0e92dd;font-weight: bold;" v-else>已选用</div>
                        </div>
                    </div>
                    <div class="con_below_page">
                        <el-pagination
                            :page-size="8"
                            :pager-count="4"
                            background
                            layout="prev, pager, next"
                            @current-change="follow_page"
                            :total="followUp_total">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div style="width: 100%;height: 100%;" v-if="follow_type === 2">
                <div class="sf-fangan-title">
                    随访方案
                </div>
                <div class="sf-fangan-body">
                    <div class="con1_top">
                        <i class="el-icon-arrow-left" style="font-size: 20px;"></i> <span style="font-size: 16px;font-weight: bold;">{{ followShow.name }}</span>
                    </div>
                    <div class="con1_middle">
                        <div style="width: 30%;"><span style="font-weight: bold;">疾病: </span><span style="color: #6c6c6c">{{ followShow.disease.name }}</span></div>
                        <div style="width: 45%;"><span style="font-weight: bold;">来源: </span><span style="color: #6c6c6c">{{ followShow.doctor.getHospital.name }}{{ followShow.doctor.medicalDepartment.name }}{{ followShow.doctor.name }}</span></div>
                        <div style="width: 20%;"><span style="font-weight: bold;">使用条件: </span><span style="color: #6c6c6c">{{ followShow.usage_condition }}</span></div>
                        <div style="width: 100%;"><span style="font-weight: bold;">方案介绍: </span><span style="color: #6c6c6c">{{ followShow.description }}</span></div>
                    </div>
                    <div class="con1_bottom">
                        <div class="con1_bottom_text" v-for="(item,index) in followConfigList" :key="index" @click="scale_education(item.id,followShow.id)">
                            <div style="width: 20%;background-color: #f0f4f7;padding: 1rem 1.5rem;text-align: center;border: 1px #eeeeee solid;">
                                {{ item.name }}
                            </div>
                            <div style="width: 85%;background-color: #fff;padding: 1rem 2rem;text-align: center;display: flex;justify-content: space-between;align-items: center;border: 1px #eeeeee solid;">
                                <div>量表/患教</div>
                                <div style="color: #0e92dd;font-weight: bold;">{{ item.description }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="add_patient" style="width: 100%;height: 100%;" v-if="follow_type === 3">
                <div class="add-patient-title">
                    添加患者
                </div>
                <div style="box-sizing: border-box;width: 100%;height: 800px;margin: auto;background-color: #FFFFFF; padding: 42px 62px 42px 105px;">
                    <div class="con1_top" style="justify-content: space-between;">
                        <div>
                            <i class="el-icon-arrow-left" style="font-size: 20px;"></i> <span style="font-size: 16px;font-weight: bold;">添加患者</span>
                        </div>
                        <div>
                            <el-select v-model="follow_up_scheme_id" placeholder="请选择方案" size="mini" @change="choice_follow_up_scheme">
                                <el-option
                                    v-for="item in followUp_listAll"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div style="width: 100%;margin: auto;height: 75%;">
                        <div style="width: 100%;height: 100%;border-bottom: 1px #f0f0f0 solid;">
                            <div style="height: 16%;display: flex;align-items: center;">
                                <div style="font-size: 15px;font-weight: bold;border-left: 3px #0e92dd solid;padding-left: 0.5rem;margin-left: 0.5rem;">
                                    方式一: 手机号添加
                                </div>
                            </div>
                            <div style="width:100%;margin:auto;display: flex;justify-content: space-between;align-items: center;">
                                <div style="width: 45%;">
                                    <el-input size="small" v-model="name_input" placeholder="请输入患者姓名"></el-input>
                                </div>
                                <div style="width: 45%;">
                                    <el-input size="small" v-model="tel_input" placeholder="请输入患者手机号"></el-input>
                                </div>
                            </div>
                            <div style="font-size: 15px;font-weight: bold;width:100%;align-items: center;margin: 0.5rem auto;">
                                联系人方式:
                                <el-radio-group v-model="contac_information" style="margin-left: 1rem;" disabled>
                                    <el-radio label="1">患者本人</el-radio>
                                    <el-radio label="2">患者亲友</el-radio>
                                </el-radio-group>
                            </div>
                            <div style="display: flex;justify-content: center;align-items: center;margin-top: 2rem;">
                                <el-button type="primary" size="small" style="width: 50%;" @click="confirm_sending">确认发送</el-button>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;margin: auto;height: 15%;border-bottom: 1px #f0f0f0 solid;">
                        <div style="height: 100%;display: flex;align-items: center;justify-content: space-between;">
                            <div style="font-size: 15px;font-weight: bold;border-left: 3px #0e92dd solid;padding-left: 0.5rem;margin-left: 0.5rem;">
                                方式二: 二维码添加
                            </div>
                            <div style="color: #0e92dd;font-size: 14px;margin-right: 1rem;text-decoration:underline;cursor: pointer;" @click="generate_code">
                                点击查看大图
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 100%;height: 100%;" v-if="follow_type === 4">
                <div class="con1_top">
                    <span style="font-size: 26px;font-weight: 700; color: #333;">自定义随访方案</span>
                </div>
                <div class="con3_middle">
                    <div class="con3_middle_top">
                        <i class="el-icon-arrow-left" style="font-size: 20px;"></i> <span style="font-size: 16px;font-weight: bold;">自定义随访方案</span>
                    </div>
                    <div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap;width: 100%;">
                        <div style="margin-top: 1.5rem;display: flex;align-items: center;justify-content: center;width: 100%;">
                            <div style="width: 10%;text-align: right;margin-right: 10px;"><span style="color: red;">*</span>名称: </div>
                            <div>
                                <el-input v-model="Follow_name" style="height: 66px !important; line-height: 66px !important; background: #F7F7F7 !important;" class="con3_middle_input" placeholder="请输入方案名称"></el-input>
                            </div>
                        </div>
                        <div style="margin-top: 1.5rem;display: flex;align-items: center;justify-content: center;width: 100%;">
                            <div style="width: 10%;text-align: right;margin-right: 10px;">疾病: </div>
                            <div>
                                <el-select v-model="Follow_diseases" style="height: 66px !important; line-height: 66px !important; background: #F7F7F7 !important;" class="con3_middle_input" placeholder="请选择" size="mini">
                                    <el-option
                                        v-for="item in diseaseListAll"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div style="margin-top: 1.5rem;display: flex;align-items: center;justify-content: center;width: 100%;">
                            <div style="width: 10%;text-align: right;margin-right: 10px;">使用条件: </div>
                            <div>
                                <el-input v-model="Follow_condition"  style="height: 66px !important; line-height: 66px !important; background: #F7F7F7 !important;" class="con3_middle_input" placeholder="请输入方案名称"></el-input>
                            </div>
                        </div>
                        <div style="margin-top: 1.5rem;display: flex;align-items: center;justify-content: center;width: 100%;">
                            <div style="width: 10%;text-align: right;margin-right: 10px;">方案介绍: </div>
                            <div>
                                <el-input
                                    class="con3_middle_textarea"
                                    resize="none"
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    v-model="Follow_introduce">
                                </el-input>
                            </div>
                        </div>
<!--                        <div style="margin-top: 1.5rem;display: flex;align-items: center;justify-content: center;width: 100%;">-->
<!--                            <div style="width: 10%;text-align: right;margin-right: 10px;">量表: </div>-->
<!--                            <div style="width: 100%;">-->
<!--                                <el-input style="width: 100%;" size="mini" placeholder="请输入方案名称"></el-input>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div style="margin-top: 1.5rem;display: flex;align-items: center;justify-content: center;width: 100%;">-->
<!--                            <div style="width: 10%;text-align: right;margin-right: 10px;">患教: </div>-->
<!--                            <div style="width: 100%;">-->
<!--                                <el-input style="width: 100%;" size="mini" placeholder="请输入方案名称"></el-input>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                    <div style="width: 100%;text-align: center;margin: 2rem 0;">
                        <el-button type="primary" size="mini" style="width: 287px; height: 40px;background-color: #017ED8; border-radius: 6px;" @click="Follow_add">保存方案</el-button>
                    </div>
                </div>
            </div>
            <div style="width: 100%;height: 100%;" v-if="follow_type === 5">
                <div class="con4_top">
                    <div class="con4-title">
                      <div class="title_left" @click="backPage">
                        <i class="el-icon-back"></i>
                      </div>
                      随访病人
                    </div>
                    <el-select  class="con4-select" @change="follow_patients_disease_change" placeholder="请选择"  v-model="follow_patients_disease_id">
                        <el-option
                            v-for="item in diseaseListAll"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                    <el-input
                        @change="follow_patients_change"
                         class="con4-input"
                        placeholder="搜索"
                        v-model="follow_patients_name">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                </div>
                <div class="con4_middle">
                    <div v-for="(list_data_item,list_data_index) in follow_patients_list_data" :key="list_data_index">
                        <div style="display: flex;justify-content: left;align-items: center;padding: 2rem;">
                            <div style="width: 3px;height: 20px;background-color: #0e92dd;"></div>
                            <div style="font-size: 16px;font-weight: bold;margin-left: 10px;">{{ list_data_index }}</div>
                        </div>
                        <div style="width: 1172px;margin: 0 46px 0 35px;display: flex;justify-content: space-between;align-items: center;padding: 0.5rem 0;border-bottom: 1px #eeeeee solid;" v-for="(items,indexs) in list_data_item" :key="indexs" @click="follow_patients_info(items.id)">
                            <div>
                                <span style="font-weight: bold;font-size: 15px;" v-if="user_role === 'user'">{{ items.followUpScheme.doctor.name }}</span>
                                <span style="font-weight: bold;font-size: 15px;" v-else>{{ items.user.name }}</span>
                                <span style="color: #99a9bf;font-size: 15px;margin-left: 2rem;">{{ items.followUpScheme.disease.name }}</span>
                            </div>
                            <div style="display: flex;align-items: center;">
                                <div style="font-weight: bold;font-size: 15px;color: #0e92dd">{{ items.followUpScheme.doctor.medicalDepartment.name }}</div>
                                <div><i class="el-icon-arrow-right" style="font-size: 20px;"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;height: 10%;margin:auto;display: flex;justify-content: center;align-items: center;">
                    <el-pagination
                        :page-size="8"
                        :pager-count="4"
                        background
                        layout="prev, pager, next"
                        @current-change="follow_patients_list_page"
                        :total="follow_patients_list_data_total">
                    </el-pagination>
                </div>
            </div>
            <div style="width: 100%;height: 100%;" v-if="follow_type === 6">
                <div class="sf-fangan-title">
                    随访方案
                </div>
                <div class="sf-fangan-body">
                    <div class="con1_top" @click="follow_search">
                        <i class="el-icon-arrow-left" style="font-size: 20px;"></i> <span style="font-size: 16px;font-weight: bold;">{{ followShow.name }}</span>
                    </div>
                    <div class="con1_middle">
                        <div style="width: 30%;"><span style="font-weight: bold;">疾病: </span><span style="color: #6c6c6c">{{ followShow.disease.name }}</span></div>
                        <div style="width: 45%;"><span style="font-weight: bold;">来源: </span><span style="color: #6c6c6c">{{ followShow.doctor.getHospital.name }}{{ followShow.doctor.medicalDepartment.name }}{{ followShow.doctor.name }}</span></div>
                        <div style="width: 20%;"><span style="font-weight: bold;">使用条件: </span><span style="color: #6c6c6c">{{ followShow.usage_condition }}</span></div>
                        <div style="width: 100%;"><span style="font-weight: bold;">方案介绍: </span><span style="color: #6c6c6c">{{ followShow.description }}</span></div>
                    </div>
                    <div class="con1_bottom">
                        <div class="con1_bottom_text" v-for="(item,index) in followUpShow_list" :key="index" @click="scale_education_info(item.follow_up_scheme_id,followShow.doctor.id,item.id,item.follow_up_scheme_config_id)">
                            <div style="width: 20%;background-color: #f0f4f7;padding: 1rem 1.5rem;text-align: center;border: 1px #eeeeee solid;">
                                {{ item.followUpSchemeConfig.name }}
                            </div>
                            <div style="width: 85%;background-color: #fff;padding: 1rem 2rem;text-align: center;display: flex;justify-content: space-between;align-items: center;border: 1px #eeeeee solid;">
                                <div>
                                    <span v-if="item.patient_education_id !== null && item.medical_scales_id === null">患教</span>
                                    <span v-if="item.patient_education_id === null && item.medical_scales_id !== null">量表</span>
                                    <span v-if="item.patient_education_id !== null && item.medical_scales_id !== null">量表、患教</span>
                                </div>
                                <div style="color: #0e92dd;font-weight: bold;">{{ item.followUpSchemeConfig.description }}</div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;text-align: center;margin: 1rem 0;">
                        <el-button type="primary" size="mini" style="width: 40%;background-color: #027ed8;" v-if="followShow.doctor.id !== Number(user_id)" @click="choicePlan(followShow.id)">选为我的方案</el-button>
                    </div>
                </div>
            </div>
            <div style="width: 100%;height: 100%;" v-if="follow_type === 7">
                <div class="qunfa-notice">
                    随访病人
                </div>
                <div class="message_style" style="box-sizing: border-box;width: 100%;height: 800px;background-color: #FFFFFF;margin: auto;padding: 45px 62px 45px 106px;">
                    <div class="con1_top">
                        <i class="el-icon-arrow-left" style="font-size: 20px;"></i> <span style="font-size: 16px;font-weight: bold;">群发通知</span>
                    </div>
                    <div style="display: flex;justify-content: space-between;padding: 1rem 0 0 1.5rem;">
                        <div style="font-size: 15px;font-weight: bold;width: 25%">短信发送样式: </div>
                        <div style="width: 100%;margin-right: 1rem">
                            <el-input
                                resize="none"
                                type="textarea"
                                :rows="5"
                                placeholder="请输入内容"
                                v-model="textarea">
                            </el-input>
                        </div>
                    </div>
                    <div style="display: flex;justify-content: space-between;padding: 1rem 0 0 1.5rem;margin-top: 1rem">
                        <div style="font-size: 15px;font-weight: bold;width: 25%">发送时间: </div>
                        <div style="width: 100%;margin-right: 1rem">
                            <el-checkbox-group v-model="sending_time" style="display: flex;align-items: center;flex-wrap: wrap;">
                                <el-checkbox style="width: 20%;padding-bottom: 1rem" :label="item.id" v-for="(item,index) in followConfigList" :key="index">{{ item.name }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div style="width: 100%;text-align: center;margin: 1rem 0;">
                        <el-button type="primary" size="mini" style="width: 40%;background-color: #027ed8;" @click="send_sms">确认发送</el-button>
                    </div>
                </div>
            </div>
            <div style="width: 100%;height: 100%;" v-if="follow_type === 8">
                <div class="con1_top" @click="backUser">
                    <i class="el-icon-arrow-left" style="font-size: 20px;"></i> <span style="font-size: 16px;font-weight: bold;">随访病人详情</span>
                </div>
                <div style="width: 100%;height: 85%;background-color: #FFFFFF;margin: auto;">
                    <div style="width: 100%;margin: auto;border-bottom: 2px #f2f2f2 solid;">
                        <div style="display: flex;justify-content: left;align-items: center;padding-top: 0.5rem;">
                            <div style="width: 3px;height: 10px;background-color: #0e92dd;margin-right: 0.5rem;"></div>
                            <div style="font-weight: bold;">基本信息</div>
                        </div>
                        <div style="display: flex;justify-content: space-between;align-items: center;padding-top: 1rem;padding-bottom: 1rem;color: #808080;">
                            <div>姓名: {{ user_follow_up_scheme_info.user.name }}</div>
                            <div>性别: {{ user_follow_up_scheme_info.user.sex === '1' ? '男' : '女' }}</div>
                            <div>出生日期: {{ user_follow_up_scheme_info.user.birthday }}</div>
                            <div>手机号: {{ user_follow_up_scheme_info.user.tel }}</div>
                        </div>
                    </div>
                    <div style="width: 100%;margin: auto;height: 82%;overflow-y: auto">
                        <div style="display: flex;justify-content: left;align-items: center;padding-top: 0.5rem;">
                            <div style="width: 3px;height: 10px;background-color: #0e92dd;margin-right: 0.5rem;"></div>
                            <div style="font-weight: bold;">病程记录</div>
                        </div>
                        <div v-for="(itemss,indexss) in user_follow_up_scheme_info.diagnosis_order" :key="indexss">
                            <div style="color: #0e92dd;padding-top: 0.5rem;" @click="put_away">
                                <span>{{ indexss }}</span>
                                <span style="padding-left: 0.5rem">
                                <i class="el-icon-arrow-up" style="color: #0e92dd;font-weight: bold;" v-if="put_away_type === 1"></i>
                                <i class="el-icon-arrow-down" style="color: #0e92dd;font-weight: bold;" v-else></i>
                            </span>
                            </div>
                            <div style="width: 100%;" v-if="put_away_type === 1">
                                <div v-for="(itemsss,indexsss) in itemss" :key="indexsss" style="width: 100%;margin: auto;border-bottom: 2px #f2f2f2 solid;">
                                    <div style="padding: 1rem 0;display: flex;justify-content: space-between;align-items: flex-end;color: #808080;">
                                        <div style="text-align: left;">
                                            <div>{{ itemsss.month }}-{{ itemsss.day }} 复诊</div>
                                            <div>[诊断]</div>
                                        </div>
                                        <div>{{ itemsss.remark }}</div>
<!--                                        <div>[现病史]</div>-->
                                        <div style="text-align: center;">
                                            <div style="font-size: 10px;color: #b4b4b4">{{ itemsss.doctor.name }} {{ itemsss.create_time }} 创建</div>
                                            <div>[既往史]</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="con8_bottom">
                            <div class="con8_bottom_text" v-for="(item,index) in user_follow_up_scheme_array" :key="index" @click="doctors_check_patient_answers(item.followUpSchemeConfig.id)">
                                <div style="width: 20%;background-color: #f0f4f7;padding: 1rem 1.5rem;text-align: center;border: 1px #eeeeee solid;">
                                    {{ item.followUpSchemeConfig.name }}
                                </div>
                                <div style="width: 85%;background-color: #fff;padding: 1rem 2rem;text-align: center;display: flex;justify-content: space-between;align-items: center;border: 1px #eeeeee solid;">
                                    <div>
                                        <span v-if="item.patient_education_id !== null && item.medical_scales_id === null">患教</span>
                                        <span v-if="item.patient_education_id === null && item.medical_scales_id !== null">量表</span>
                                        <span v-if="item.patient_education_id !== null && item.medical_scales_id !== null">量表、患教</span>
                                    </div>
                                    <div style="color: #0e92dd;font-weight: bold;">{{ item.followUpSchemeConfig.description }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 100%;height: 100%;" v-if="follow_type === 9">
                <div class="con_top" style="justify-content: right;">
                    <el-input
                        style="width: 40%;"
                        size="mini"
                        placeholder="搜索"
                        v-model="follow_Keywords"
                        @change="follow_search"
                    >
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                </div>
                <div class="con_below">
                    <div class="con_below_text_user" @click="privateMessages_info(ite1)" style="padding: 1rem 0 0.3rem" v-for="(ite1,inde1) in privateMessages_list_array" :key="inde1">
                        <div style="display: flex;justify-content: space-between;align-items: center">
                            <div style="font-weight: bold;font-size: 14px;">{{ ite1.title }}</div>
                            <div style="color: red;font-size: 14px;" v-if="ite1.is_read === '0'">未读</div>
                        </div>
                        <div style="margin-top: 0.5rem">
                            <div style="color: #99a9bf;">{{ ite1.content }}</div>
                        </div>
                    </div>
                </div>
                <div class="con_below_page">
                    <el-pagination
                        :page-size="6"
                        :pager-count="4"
                        background
                        layout="prev, pager, next"
                        @current-change="privateMessages_page"
                        :total="privateMessages_list_total">
                    </el-pagination>
                </div>
            </div>
            <div style="width: 100%;height: 100%;" v-if="follow_type === 10">
                <div class="con_top" style="justify-content: space-between;">
                    <div style="color:#0e92dd;cursor: pointer;" @click="publish">
                        发布文章
                    </div>
                    <div style="width: 50%;">
                        <el-input
                            style="width: 100%;"
                            size="mini"
                            placeholder="搜索"
                            v-model="Keywords_title"
                            @change="title_search"
                        >
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </div>
                </div>
                <div class="con_below">
                    <div class="con_below_text" style="justify-content: space-between" @click="article_plan(article_ite.id)" v-for="(article_ite,article_inde) in article_list_arr" :key="article_inde">
                        <div style="width: 50%;display: flex;justify-content: space-between;align-items: center;">
                            <div style="font-weight: bold;font-size: 14px;">{{ article_ite.title }}</div>
                            <div style="font-size: 11px;color: #ccc;margin-left: 5px;">创建时间: {{ article_ite.create_time }}</div>
                        </div>
                        <div>
                            <div style="color: #9d9d9d;font-weight: bold;">详情</div>
                        </div>
                    </div>
                    <div class="con_below_page">
                        <el-pagination
                            :page-size="8"
                            :pager-count="4"
                            background
                            layout="prev, pager, next"
                            @current-change="article_page"
                            :total="article_list_total">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div style="width: 100%;height: 100%;" v-if="follow_type === 11">
                <div class="con1_top" @click="title_search">
                    <i class="el-icon-arrow-left" style="font-size: 20px;"></i> <span style="font-size: 16px;font-weight: bold;"></span>
                </div>
                <div class="con1_middle" style="height: 10%;">
                    <div style="width: 30%;"><span style="font-weight: bold;">科室: </span><span style="color: #6c6c6c">{{ article_list_arr_info.medicalDepartment.name }}</span></div>
                    <div style="width: 45%;"><span style="font-weight: bold;">标题: </span><span style="color: #6c6c6c">{{ article_list_arr_info.title }}</span></div>
                </div>
                <div style="width: 100%;height: 75%;background-color: #fff;margin: 0.1rem auto 0;overflow-y: auto;">
                    <div class="info_div_con_one" v-html="article_list_arr_info.content"></div>
                </div>
            </div>
            <div style="width: 100%;height: 100%;" v-if="follow_type === 12">
                <div class="con1_top" @click="title_search">
                    <i class="el-icon-arrow-left" style="font-size: 20px;"></i> <span style="font-size: 16px;font-weight: bold;">患教文章列表</span>
                </div>
                <div class="con3_middle">
                    <div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap;width: 100%;">
                        <div style="margin-top: 1.5rem;display: flex;align-items: center;justify-content: center;width: 90%;">
                            <div style="width: 10%;text-align: right;margin-right: 10px;"><span style="color: red;">*</span>标题: </div>
                            <div style="width: 90%;">
                                <el-input v-model="publish_title" style="width: 90%;" size="mini" placeholder="请输入方案名称"></el-input>
                            </div>
                        </div>
                        <div style="margin-top: 1.5rem;display: flex;align-items: center;justify-content: center;width: 90%;">
                            <div style="width: 10%;text-align: right;margin-right: 10px;">内容: </div>
                            <div style="width: 90%;">
                                <el-input
                                    resize="none"
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    v-model="publish_con">
                                </el-input>
                            </div>
                        </div>
                        <div style="margin-top: 1.5rem;display: flex;align-items: center;justify-content: center;width: 90%;">
                            <div style="width: 10%;text-align: right;margin-right: 10px;">图片: </div>
                            <div style="width: 90%;">
                                <el-upload
                                    class="upload-demo"
                                    :action="Upload_Api_urls"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :headers="{ token: this.token }"
                                    :file-list="fileList"
                                    :limit="3"
                                    :on-exceed="handleExceed">
                                    <el-button size="small" type="primary">上传图片</el-button>
                                    <div slot="tip" class="el-upload__tip"></div>
                                </el-upload>
                            </div>
                        </div>
                        <div style="margin-top: 1.5rem;display: flex;align-items: center;justify-content: center;width: 90%;">
                            <div style="width: 10%;text-align: right;margin-right: 10px;">视频: </div>
                            <div style="width: 90%;">
                                <el-upload
                                    class="upload-demo"
                                    :action="Upload_Api_urls"
                                    :on-success="video_handleSuccess"
                                    :before-upload="video_beforeUpload"
                                    :headers="{ token: this.token }"
                                    :file-list="video_fileList"
                                    :limit="2"
                                    :on-exceed="video_handleExceed">
                                    <el-button size="small" type="primary">上传视频</el-button>
                                    <div slot="tip" class="el-upload__tip"></div>
                                </el-upload>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;text-align: center;margin: 2rem 0;">
                    <el-button type="primary" size="mini" style="width: 40%;background-color: #027ed8;" @click="publish_article">发布</el-button>
                </div>
            </div>
        </div>

        <el-dialog title="选择患教/量表" :visible.sync="scale_education_type" append-to-body>
            <div style="width: 90%;height:60vh;margin: auto;">
                <div style="margin-top: 20px;width: 100%;height: 30%;display: flex;justify-content: space-between">
                    <div style="width: 50%;">
                        <div style="height: 10%;padding-bottom: 0.5rem">患教: </div>
                        <div>
                            <el-select v-model="education_id" placeholder="请选择分类" size="mini" @change="educationChange">
                                <el-option
                                    v-for="item in educationList"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div style="width: 50%;height: 60%;display: flex;justify-content: space-between;">
                        <div style="height: 100%;width: 300px;border: 2px #eeeeee solid;border-radius: 12px;overflow-y: auto;padding: 0.5rem" v-html="educationText"></div>
                    </div>
                </div>
                <div style="width: 100%;margin: auto;height: 68%;overflow-y: auto;">
                    <div style="height: 5%;padding-bottom: 0.5rem;display: flex;align-items: center;">量表: </div>
                    <div style="margin-bottom: 2%;" v-for="(scale_item,scale_index) in scaleList" :key="scale_index">
                        <el-checkbox v-model="caleChecked[scale_item.id]">{{ scale_index + 1 }}: {{ scale_item.question }}</el-checkbox>
                        <div style="display: flex;justify-content: left;align-items: center;">
                            <span>选项: </span>
                            <div style="padding: 0 1%;" v-for="(scale_item_option,scale_item_option_index) in scale_item.option" :key="scale_item_option_index">
                                {{ scale_item_option_index + 1 }}:{{ scale_item_option }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 20px;"></div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button type="primary" @click="scale_education_but(followShow_id)">确 定</el-button>
                <el-button @click="scale_education_type = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog title="详情" :visible.sync="scale_education_info_type">
            <div style="width: 90%;height:60vh;margin: auto;">
                <div style="margin-top: 20px;width: 100%;height: 30%;display: flex;justify-content: space-between">
                    <div style="width: 50%;">
                        <div style="height: 10%;padding-bottom: 0.5rem">患教: </div>
                        <div>
                            <el-input
                                placeholder="请输入内容"
                                v-model="educationInputs"
                                :disabled="true">
                            </el-input>
                        </div>
                    </div>
                    <div style="width: 50%;height: 60%;display: flex;justify-content: space-between;">
                        <div style="height: 100%;width: 300px;border: 2px #eeeeee solid;border-radius: 12px;overflow-y: auto;padding: 0.5rem" v-html="educationTexts"></div>
                    </div>
                </div>
                <div style="width: 100%;margin: auto;height: 68%;overflow-y: auto;">
                    <div style="height: 5%;padding-bottom: 0.5rem;display: flex;align-items: center;">量表: </div>
                    <div style="margin-bottom: 2%;" v-for="(medicalScales_item,medicalScales_index) in scale_education_info_list.medicalScales" :key="medicalScales_index">
                        <div>{{ medicalScales_index + 1 }}: {{ medicalScales_item.question }}</div>
                        <div style="display: flex;justify-content: left;align-items: center;">
                            <span>选项: </span>
                            <div style="padding: 0 1%;" v-for="(scale_items_option,scale_items_option_index) in medicalScales_item.option" :key="scale_items_option_index">
                                {{ scale_items_option_index + 1 }}:{{ scale_items_option }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 20px;"></div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button @click="scale_education_info_type = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog title="修改" :visible.sync="scale_education_edit_type">
            <div style="width: 90%;height:60vh;margin: auto;">
                <div style="margin-top: 20px;width: 100%;height: 30%;display: flex;justify-content: space-between">
                    <div style="width: 50%;">
                        <div style="height: 10%;padding-bottom: 0.5rem">患教: </div>
                        <div>
                            <el-select v-model="educations_id" placeholder="请选择分类" size="mini" @change="educationChange">
                                <el-option
                                    v-for="item in educationList"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div style="width: 50%;height: 60%;display: flex;justify-content: space-between;">
                        <div style="height: 100%;width: 300px;border: 2px #eeeeee solid;border-radius: 12px;overflow-y: auto;padding: 0.5rem" v-html="educationTexts"></div>
                    </div>
                </div>
                <div style="width: 100%;margin: auto;height: 68%;overflow-y: auto;">
                    <div style="height: 5%;padding-bottom: 0.5rem;display: flex;align-items: center;">量表: </div>
                    <div style="margin-bottom: 2%;" v-for="(scale_item,scale_index) in scaleList" :key="scale_index">
                        <el-checkbox v-model="caleChecked[scale_item.id]">{{ scale_index + 1 }}: {{ scale_item.question }}</el-checkbox>
                        <div style="display: flex;justify-content: left;align-items: center;">
                            <span>选项: </span>
                            <div style="padding: 0 1%;" v-for="(scale_item_option,scale_item_option_index) in scale_item.option" :key="scale_item_option_index">
                                {{ scale_item_option_index + 1 }}:{{ scale_item_option }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 20px;"></div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button type="primary" @click="scale_education_edit_but(followShow_id)">确 定</el-button>
                <el-button @click="scale_education_edit_type = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog title="添加患者信息" :visible.sync="add_user_type" append-to-body>
            <div class="add_user" style="width: 70%;margin: auto;">
                <el-form ref="form" :model="form" label-width="80px" style="margin-top: 1rem">
                    <el-form-item label="患者姓名:">
                        <el-input size="small" v-model="form.name" placeholder="输入患者姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="患者身份证号码:">
                        <el-input size="small" v-model="form.id_number" placeholder="输入患者身份证号码"></el-input>
                    </el-form-item>
                    <el-form-item label="患者手机号:">
                        <el-input size="small" v-model="form.tel" placeholder="输入患者手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="患者密码:">
                        <el-input size="small" v-model="form.password" placeholder="输入患者密码"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div style="height: 20px;"></div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button type="primary" @click="add_user_but()">确 定</el-button>
                <el-button @click="add_user_type = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog title="编辑患者信息" :visible.sync="edit_user_type" append-to-body>
            <div class="add_user" style="width: 70%;margin: auto;">
                <el-form ref="form" :model="form_edit" label-width="80px" style="margin-top: 1rem">
                    <el-form-item label="患者姓名:">
                        <el-input size="small" v-model="form_edit.name" placeholder="输入患者姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="患者身份证号码:">
                        <el-input size="small" v-model="form_edit.id_number" placeholder="输入患者身份证号码"></el-input>
                    </el-form-item>
                    <el-form-item label="患者手机号:">
                        <el-input size="small" v-model="form_edit.tel" placeholder="输入患者手机号"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div style="height: 20px;"></div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button type="primary" @click="edit_user_but()">确 定</el-button>
                <el-button @click="edit_user_type = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog title="查看二维码" :visible.sync="generate_code_type" append-to-body>
            <div class="add_user" style="width: 70%;margin: 1rem auto;display: flex;justify-content: center">
                <img :src="generate_code_image" style="width: 200px;height: 200px">
            </div>
            <div style="height: 20px;"></div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button @click="generate_code_type = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog title="查看患者答题情况" :visible.sync="user_education_config_type" append-to-body>
            <div class="add_user" style="width: 70%;margin: 1rem auto;">
                <div style="padding: 1rem;border: 1px #99a9bf solid;border-radius: 10px;margin-bottom: 1rem;" v-for="(item_d,index_d) in user_education_config_array.medical_scales" :key="index_d">
                    <div>题干: {{item_d.question}}</div>
                    <div>
                        选项:
                        <span style="padding-right: 1rem" v-for="(item_d_option,index_d_option) in item_d.option" :key="index_d_option">{{ index_d_option + 1 }}:{{item_d_option}}</span>
                    </div>
                    <div>答案: {{item_d.answer}}</div>
                </div>

            </div>
            <div style="height: 20px;"></div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button @click="user_education_config_type = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog title="答题" :visible.sync="user_education_config_user_type" append-to-body>
            <div class="add_user" style="width: 70%;margin: 1rem auto;">
                <div style="padding: 1rem;border: 1px #99a9bf solid;border-radius: 10px;margin-bottom: 1rem;" v-for="(item_d,index_d) in user_education_config_array.medical_scales" :key="index_d">
                    <div>题干: {{item_d.question}}</div>
                    <div>
                        选项:
                        <el-radio-group v-model="user_education_config_array_region[index_d]">
                            <el-radio :label="item_d_option+'-'+item_d.id+'-'+index_d_option" v-for="(item_d_option,index_d_option) in item_d.option" :key="index_d_option">{{ index_d_option + 1 }}:{{item_d_option}}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div style="height: 20px;"></div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button @click="user_education_config_user_but">提 交</el-button>
                <el-button @click="user_education_config_user_type = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>

import {
    config,
    diseaseGetListAll, doctorAttachmentAdd,
    followUpScheme,
    followUpSchemeAdd,
    followUpSchemeConfig,
    followUpSchemeCopy,
    followUpSchemeGetListAll,
    followUpSchemeShow,
    getFollowUpSchemeWxQRcode,
    medicalScales,
    patientEducation, patientEducationAdd,
    patientEducationShow,
    privateMessages,
    privateMessagesAdd,
    privateMessagesAddList,
    privateMessagesEdit,
    schemeScalesEducationConfig,
    schemeScalesEducationConfigEdit,
    schemeScalesEducationConfigList,
    schemeScalesEducationConfigShow,
    user,
    userAdd,
    userEdit,
    userFollowUpScheme,
    userFollowUpSchemeAdd,
    userFollowUpSchemeShow,
    UserMedicalScalesAnswerEdit,
    UserMedicalScalesAnswerShow,
} from "@/api";
import { Upload_Api_url } from '@/api/index.js';
export default {
    name: 'followUp',
    data() {
        return {
            user_education_config_user_type: false,
            user_education_config_type: false,
            put_away_type: 2,
            sending_time: [],
            form: {
                name: '',
                id_number: '',
                tel: '',
                password: '',
            },
            form_edit: {
                name: '',
                id_number: '',
                tel: '',
                user_id: '',
            },
            generate_code_image: '',
            generate_code_type: false,
            add_user_type: false,
            edit_user_type: false,
            educations_id: '',
            scale_education_info_type: false,
            scale_education_edit_type: false,
            scale_education_info_list: [],
            scale_education_type: false,
            education_ids: '',
            educationTexts: '',
            educationInputs: '',
            Config_id: '',
            scale_id: '',
            Keywords: '',
            follow_Keywords: '',
            find_group_text: '',
            find_group_type: false,
            add_group_type: false,
            follow_type: 1,
            textarea: '',
            Follow_name: '',
            Follow_diseases: '',
            Follow_condition: '',
            Follow_introduce: '',
            diseaseListAll: [],
            user_id: '',
            medical_department_id: '',
            followConfigList: [],
            followShow: [],
            scaleList: [],
            educationList: [],
            education_id: '',
            educationText: '',
            caleChecked: [],
            followShow_id: '',
            followUp_list: [],
            followUp_page: 1,
            followUp_limit: 7,
            followUpShow_list: [],
            is_add: [],
            followUp_total: 0,
            edit_follow_up_scheme_id: '',
            edit_follow_up_scheme_config_id: '',
            Association_id: '',
            followUp_listAll: [],
            name_input: '',
            tel_input: '',
            contac_information: '',
            //添加患者页的选择方案
            follow_up_scheme_id: '',
            follow_patients_disease_id: '',
            follow_patients_name: '',
            follow_patients_list_data: [],
            follow_patients_list_data_total: [],
            follow_patients_list_data_page: 1,
            //患者方案关联表id
            user_follow_up_scheme_id: '',
            //患者id
            user_follow_up_user_id: '',
            //随访方案库表id
            follow_up_scheme_user_id: '',
            //配置项id
            user_education_config_id: '',
            user_follow_up_scheme_info: [],
            user_follow_up_scheme_array: [],
            //答案
            user_education_config_array: [],
            user_follow_up_scheme_sm_style: '',
            user_follow_up_scheme_sm_name: '',
            user_role: '',
            my_privateMessages_list: 1,
            privateMessages_list_array: [],
            privateMessages_list_total: [],
            user_education_config_array_user_follow_up_scheme_id: '',
            user_education_config_array_follow_up_scheme_config_id: '',
            user_education_config_array_region: [],
            Keywords_title: '',//患教文章标题搜索
            title_page: 1,//患教文章标题搜索
            article_list_arr: [],//患教文章列表
            article_list_total: '',
            article_list_arr_info: [],
            is_select_style: 2,
            publish_title: '', //添加患教文章标题
            publish_con: '', //添加患教文章内容
            Upload_Api_urls: '',
            token: '',
            fileList: [],
            fileList_arr: [],
            video_fileList: [],
            video_fileList_arr: [],
        }
    },
    mounted() {
        this.user_id = localStorage.getItem('user_id');
        this.user_role = localStorage.getItem('user_role');
        this.token = localStorage.getItem('token');
        this.Upload_Api_urls = Upload_Api_url
        this.medical_department_id = localStorage.getItem('medical_department_id');
        this.followUpList()
        this.followUpScheme_init()
        this.disease_list()
        this.follow_patients_list()
        this.config_init()
        if(this.user_role === 'user'){
            this.follow_type = 5
            this.follow_patients_lists()
        }else{
            this.follow_type = 1
        }
    },
    methods: {
        backPage() {
            this.$emit('backPage')
        },
        publish(){
            this.follow_type = 12
        },
        beforeUpload(file) {
            const isImage = file.type.startsWith('image/');
            if (!isImage) {
                this.$message.error('只能上传图片文件!');
                return false; // 阻止文件上传
            }
            // 可以在这里添加更多其他验证逻辑，比如大小限制等
            return true; // 允许文件上传
        },
        handleSuccess(e, file){
            this.fileList.push(file);
            this.fileList_arr.push(file.response.data.url);
            doctorAttachmentAdd({
                doctor_id: this.user_id,
                file_name: e.data['name'],
                file_url: e.data['file_path'],
                file_size: e.data['file_size'],
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '上传成功',
                        type: 'success'
                    });
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        handleExceed() { // 当文件超出限制时触发
            this.$message.warning(`最多上传三张图片`);
        },
        video_beforeUpload(file) {
            const isVideo = file.type.startsWith('video/');
            if (!isVideo) {
                this.$message.error('只能上传视频文件！');
                return false;
            }
            return true;
        },
        video_handleSuccess(e, file){
            this.video_fileList.push(file);
            this.video_fileList_arr.push(file.response.data.url);
            doctorAttachmentAdd({
                doctor_id: this.user_id,
                file_name: e.data['name'],
                file_url: e.data['file_path'],
                file_size: e.data['file_size'],
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '上传成功',
                        type: 'success'
                    });
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        video_handleExceed() { // 当文件超出限制时触发
            this.$message.warning(`最多上传两个视频`);
        },
        //发布患教文章
        publish_article(){
            let richText = `<p>${this.publish_con}</p>\n`;
            // 处理图片
            if (this.fileList_arr.length > 0) {
                this.fileList_arr.forEach((item, index) => {
                    richText += `<p><img src="${item}" alt="图片${index + 1}" style="max-width: 100%; height: auto;"></p>\n`;
                });
            }
            // 处理视频
            if (this.video_fileList_arr.length > 0) {
                this.video_fileList_arr.forEach((video, index) => {
                    richText += `<p><video controls src="${video}" style="max-width: 100%; height: auto;"></video></p>\n`;
                });
            }
            console.log(richText,this.publish_title,'请求参数')
            patientEducationAdd({
                doctor_id: this.user_id,
                title: this.publish_title,
                content: richText,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.title_search()
                    this.fileList_arr = []
                    this.video_fileList_arr = []
                    this.video_fileList = []
                    this.fileList = []
                    this.publish_title = ''
                    this.publish_con = ''
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        title_search(){
            this.follow_type = 10
            this.title_page = 1
            this.article_list()
        },
        article_page(e){
            this.title_page = e
            this.article_list()
        },
        article_list(){
            patientEducation({
                page: this.title_page,
                limit: 8,
                title: this.Keywords_title,
            }).then(res=>{
                if (res.data.code === 1){
                    console.log(res.data.data.data,'数据')
                    this.article_list_arr = res.data.data.data
                    this.article_list_total = Number(res.data.data.total)
                }else{
                    this.$message({
                        message: '网络错误',
                        type: 'error'
                    });
                }
            })
        },
        article_plan(id){
            this.follow_type = 11
            patientEducationShow({
                id: id,
            }).then(res=>{
                if (res.data.code === 1){
                    this.article_list_arr_info = res.data.data
                }else{
                    this.$message({
                        message: '网络错误',
                        type: 'error'
                    });
                }
            })
        },
        user_education_config_user_but(){
            const optionsObject = {};
            this.user_education_config_array_region.forEach(option => {
                const [value, key] = option.split('-');
                optionsObject[key] = value;
            });
            const jsonString = JSON.stringify(optionsObject);
            UserMedicalScalesAnswerEdit({
                user_follow_up_scheme_id: this.user_education_config_array_user_follow_up_scheme_id,
                follow_up_scheme_config_id: this.user_education_config_array_follow_up_scheme_config_id,
                answer: jsonString
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    });
                    this.user_education_config_user_type = false
                    this.follow_type = 5
                    this.follow_patients_lists()
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        config_init() {
            config().then(res=>{
                if(res.data.code === 1){
                    this.user_follow_up_scheme_sm_style = res.data.data.user_follow_up_scheme_sm_style
                    console.log(this.user_follow_up_scheme_sm_style)
                }
            })
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
            if(key == 1){
                this.is_select_style = 2
            }else if(key == 2){
                this.is_select_style = 3
            }else if(key == 3){
                this.is_select_style = 4
            }
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        selectMenu(e) {
            e = String(e)
            console.log(e,'夺嫂1')
            if(e === "1-1"){
                this.follow_type = 1
                this.followUpList()
                this.is_select_style = 2
            }else if(e === "1-2"){
                this.disease_list()
                this.follow_type = 4
                this.is_select_style = 2
            }else if(e === "2-1"){
                this.disease_list()
                this.follow_patients_list()
                this.follow_type = 5
                this.is_select_style = 3
            }else if(e === "2-2"){
                this.follow_type = 3
                this.is_select_style = 3
            }else if(e === "2-3"){
                this.follow_type = 7
                this.followConfig()
                this.textarea = this.user_follow_up_scheme_sm_style
                this.is_select_style = 3
            }else if(e === "3-1"){
                this.disease_list()
                this.follow_patients_lists()
                this.follow_type = 5
                this.is_select_style = 4
            }else if(e === "3-2"){
                this.privateMessages_list()
                this.follow_type = 9
                this.is_select_style = 4
            }else if(e === "3"){
                this.article_list()
                this.follow_type = 10
                this.is_select_style = 4
            }else{
                const my_id = e.split('-')[1];
                this.select_plan(my_id)
            }
        },
        select_plan(id){
            this.follow_type = 6
            this.followUpShow(id)
            this.followUpShowList(id)
        },
        add_plan(){
            this.follow_type = 3
        },
        //获取疾病列表
        disease_list(){
            diseaseGetListAll().then(res=>{
                if(res.data.code === 1){
                    this.diseaseListAll = res.data.data
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //保存随访方案
        Follow_add(){
            followUpSchemeAdd({
                disease_id: this.Follow_diseases,
                name: this.Follow_name,
                doctor_id: this.user_id,
                usage_condition: this.Follow_condition,
                description: this.Follow_introduce,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.follow_type = 2
                    this.Follow_diseases = ''
                    this.Follow_name = ''
                    this.Follow_condition = ''
                    this.Follow_introduce = ''
                    this.followConfig()
                    this.followUpShow(res.data.data.id)
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //配置项列表
        followConfig(){
            followUpSchemeConfig().then(res=>{
                if(res.data.code === 1){
                    this.followConfigList = res.data.data
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //随访方案详情
        followUpShow(id){
            followUpSchemeShow({
                id: id
            }).then(res=>{
                if(res.data.code === 1){
                    this.followShow = res.data.data
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //选择患教还是列表
        scale_education(id,followShow_id){
            console.log(this.is_add,id)
            if (this.is_add.includes(id)) {
                this.$message({
                    message: '已添加,请添加其他',
                    type: 'success'
                });
                return
            }
            this.Config_id = id
            this.followShow_id =  followShow_id
            this.scale_education_type = true
            medicalScales({
                medical_department_id: this.medical_department_id
            }).then(res=>{
                if(res.data.code === 1){
                    this.scaleList = res.data.data
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
            patientEducation({
                medical_department_id: this.medical_department_id
            }).then(res=>{
                if(res.data.code === 1){
                    this.educationList = res.data.data
                    const array ={
                        id: '',
                        title: '请选择',
                        content: '',
                    }
                    this.educationList.unshift(array)
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //选择患教下拉框
        educationChange(e){
            const foundItem = this.educationList.find(item => item.id === e);
            this.educationText = foundItem.content
            this.educationTexts = foundItem.content
        },
        //确认选择
        scale_education_but(id){
            const arr = []
            this.caleChecked.forEach((item, index) => {
                if(item === true){
                    arr.push(index)
                }
            });
            const scheme_id = arr.join(',')
            schemeScalesEducationConfig({
                follow_up_scheme_id: id,
                follow_up_scheme_config_id: this.Config_id,
                medical_scales_id: scheme_id,
                patient_education_id: this.education_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.is_add.push(this.Config_id)
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.caleChecked = this.caleChecked.map(item => {
                        // 遍历每个对象，将所有的 true 值改为 false
                        const newItem = {};
                        for (const key in item) {
                            newItem[key] = false;
                        }
                        return newItem;
                    });
                    console.log(this.caleChecked,'黄教1')
                    this.education_id = ''
                    this.scale_education_type = false
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //随访方案列表
        followUpList(){
            followUpScheme({
                page: this.followUp_page,
                limit: this.followUp_limit,
                name: this.follow_Keywords,
            }).then(res=>{
                if(res.data.code === 1){
                    this.followUp_list = res.data.data.data
                    this.followUp_total = Number(res.data.data.total)
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //列表分页
        follow_page(e){
            this.followUp_page = e
            this.followUpList()
        },
        //搜索随访方案
        follow_search(){
            this.follow_type = 1
            this.followUp_page = 1
            this.followUpList()
        },
        //随访方案详情列表
        followUpShowList(id){
            schemeScalesEducationConfigList({
                follow_up_scheme_id: id
            }).then(res=>{
                if(res.data.code === 1){
                    this.followUpShow_list = res.data.data
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                    this.followUpShow_list = []
                }
            })
        },
        //选择方案
        choicePlan(id){
            followUpSchemeCopy({
                id: id,
                doctor_id: this.user_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '选择成功',
                        type: 'success'
                    });
                    this.follow_search()
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //随访详情/修改 术前 第一天等查看
        scale_education_info(id,doctor,Association_id,con_id){
            this.edit_follow_up_scheme_id = id
            this.Association_id = Association_id
            this.edit_follow_up_scheme_config_id = con_id
            this.patient_init()
            this.infos(id)
            if(doctor !== Number(this.user_id)){
                console.log(id,111,'详情')
                this.scale_education_info_type = true
            }else{
                this.scale_education_edit_type = true
            }
        },
        //获取患教下拉
        patient_init(){
            patientEducation({
                medical_department_id: this.medical_department_id
            }).then(res=>{
                if(res.data.code === 1){
                    this.educationList = res.data.data
                    const array ={
                        id: '',
                        title: '请选择',
                        content: '',
                    }
                    this.educationList.unshift(array)
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //详情
        infos(id){
            schemeScalesEducationConfigShow({
                id: id
            }).then(res=>{
                if(res.data.code === 1){
                    this.scale_education_info_list = res.data.data
                    this.education_ids = res.data.data.patient_education_id
                    this.educations_id = ''
                    this.educationTexts = ''
                    if( res.data.data.patientEducation !== null ){
                        this.educationTexts = res.data.data.patientEducation.content
                        this.educationInputs = res.data.data.patientEducation.title
                        this.educations_id = res.data.data.patientEducation.id
                    }
                    medicalScales({
                        medical_department_id: this.medical_department_id
                    }).then(res1=>{
                        if(res1.data.code === 1){
                            this.scaleList = res1.data.data
                            this.caleChecked = this.caleChecked.map(item => {
                                // 遍历每个对象，将所有的 true 值改为 false
                                const newItem = {};
                                for (const key in item) {
                                    newItem[key] = false;
                                }
                                return newItem;
                            });
                            if( res.data.data.medical_scales_id !== null ){
                                const array_id = res.data.data.medical_scales_id.split(',').map(Number)
                                this.scaleList.forEach(scaleItem => {
                                    if (array_id.includes(scaleItem.id)) {
                                        this.$set(this.caleChecked, scaleItem.id, true);
                                    }
                                });
                            }
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })

                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //修改
        scale_education_edit_but(){
            const arr = []
            this.caleChecked.forEach((item, index) => {
                if(item === true){
                    arr.push(index)
                }
            });
            const scheme_id = arr.join(',')
            schemeScalesEducationConfigEdit({
                id: this.Association_id,
                follow_up_scheme_id: this.edit_follow_up_scheme_id,
                follow_up_scheme_config_id: this.edit_follow_up_scheme_config_id,
                medical_scales_id: scheme_id,
                patient_education_id: this.educations_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.caleChecked = this.caleChecked.map(item => {
                        // 遍历每个对象，将所有的 true 值改为 false
                        const newItem = {};
                        for (const key in item) {
                            newItem[key] = false;
                        }
                        return newItem;
                    });
                    this.followUpShowList(this.Association_id)
                    console.log(this.caleChecked,'黄教1')
                    this.educations_id = ''
                    this.scale_education_edit_type = false
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //获取我的方案
        followUpScheme_init(){
            followUpSchemeGetListAll({
                doctor_id: this.user_id
            }).then(res=>{
                if(res.data.code === 1){
                    this.followUp_listAll = res.data.data
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //添加患者 确认发送
        confirm_sending(){
            user({
                name: this.name_input,
                tel: this.tel_input,
            }).then(res=>{
                if(res.data.code === 1){
                    this.contac_information = '1'
                    this.form_edit.name = res.data.data.name
                    this.form_edit.id_number = res.data.data.id_number
                    this.form_edit.tel = res.data.data.tel
                    this.form_edit.user_id = res.data.data.id
                    this.edit_user_type = true
                }else{
                    this.add_user_type = true
                    this.contac_information = '2'
                }
            })
        },
        //添加患者
        add_user_but(){
            userAdd(this.form).then(res=>{
                if(res.data.code === 1){
                    userFollowUpSchemeAdd({
                        user_id: res.data.data.user_id,
                        follow_up_scheme_id: this.follow_up_scheme_id,
                    }).then(res1=>{
                        if(res1.data.code === 1){
                            this.add_user_type = false
                            this.privateMessages_init(res.data.data.user_id,res1.data.data.id)
                        }else{
                            this.$message({
                                message: '请先选择方案',
                                type: 'warning'
                            });
                        }
                    })
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //修改患者信息
        edit_user_but(){
            userEdit(this.form_edit).then(res=>{
                if(res.data.code === 1){
                    userFollowUpSchemeAdd({
                        user_id: this.form_edit.user_id,
                        follow_up_scheme_id: this.follow_up_scheme_id,
                    }).then(res=>{
                        if(res.data.code === 1){
                            this.edit_user_type = false
                            this.privateMessages_init(this.form_edit.user_id,res.data.data.id)
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //单个发送
        privateMessages_init(user_id,follow_up_scheme_id){
            const redirect_array = {
                'user_follow_up_scheme_id':follow_up_scheme_id
            }
            const redirect_data = JSON.stringify(redirect_array)
            privateMessagesAdd({
                user_id: user_id,
                redirect_data: redirect_data,
                content: this.user_follow_up_scheme_sm_style,
                title: this.user_follow_up_scheme_sm_name,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '发送成功',
                        type: 'success'
                    });
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //选择方案
        choice_follow_up_scheme(e){
            this.follow_up_scheme_id = e
            const selectedItem = this.followUp_listAll.find(item => item.id === e);
            this.user_follow_up_scheme_sm_name = selectedItem.name
        },
        //点击生成二维码
        generate_code(){
            getFollowUpSchemeWxQRcode({
                id: this.follow_up_scheme_id
            }).then(res=>{
                if(res.data.code === 1){
                    this.generate_code_image = res.data.data.url
                    this.generate_code_type = true
                }else{
                    this.$message({
                        message: '请先选择方案',
                        type: 'warning'
                    });
                }
            })
        },
        //群发通知 确认发送
        send_sms(){
            const redirect_array = {
                'follow_up_scheme_config_id':this.sending_time
            }
            const redirect_data = JSON.stringify(redirect_array)
            privateMessagesAddList({
                redirect_data: redirect_data,
                content: this.user_follow_up_scheme_sm_style,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '发送成功',
                        type: 'success'
                    });
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //选择疾病促发
        follow_patients_disease_change(e){
            this.follow_patients_disease_id = e
            if(this.user_role === 'user'){
                this.follow_patients_lists()
            }else{
                this.follow_patients_list()
            }
        },
        //搜索内容按下回车或者改变
        follow_patients_change(){
            if(this.user_role === 'user'){
                this.follow_patients_lists()
            }else{
                this.follow_patients_list()
            }
        },
        //获取 随访病人列表
        follow_patients_list(){
            userFollowUpScheme({
                page: this.follow_patients_list_data_page,
                limit: 8,
                doctor_id: this.user_id,
                disease_id: this.follow_patients_disease_id,
                name: this.follow_patients_name,
            }).then(res=>{
                if(res.data.code === 1){
                    this.follow_patients_list_data = res.data.data.data
                    this.follow_patients_list_data_total = Number(res.data.data.total)
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //用户端 获取 随访病人列表
        follow_patients_lists(){
            userFollowUpScheme({
                page: this.follow_patients_list_data_page,
                limit: 8,
                user_id: this.user_id,
                disease_id: this.follow_patients_disease_id,
                name: this.follow_patients_name,
            }).then(res=>{
                if(res.data.code === 1){
                    this.follow_patients_list_data = res.data.data.data
                    this.follow_patients_list_data_total = Number(res.data.data.total)
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //随访病人列表分页
        follow_patients_list_page(e){
            this.follow_patients_list_data_page = e
            if(this.user_role === 'user'){
                this.follow_patients_lists()
            }else{
                this.follow_patients_list()
            }
        },
        //随访病人列表详情
        follow_patients_info(id){
            //患者方案关联表id
            this.user_follow_up_scheme_id = id
            userFollowUpSchemeShow({
                id: id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.follow_type = 8
                    this.user_follow_up_scheme_info = res.data.data
                    this.user_follow_up_user_id = res.data.data.user.id
                    this.follow_up_scheme_user_id = res.data.data.follow_up_scheme_id
                    schemeScalesEducationConfigList({
                        follow_up_scheme_id: res.data.data.follow_up_scheme_id,
                    }).then(res=>{
                        if(res.data.code === 1){
                            this.user_follow_up_scheme_array = res.data.data
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //点击收起
        put_away(){
            if(this.put_away_type === 1){
                this.put_away_type = 2
            }else{
                this.put_away_type = 1
            }
        },
        //返回患者随访列表
        backUser(){
            this.follow_type = 5
            if(this.user_role === 'user'){
                this.follow_patients_lists()
            }else{
                this.follow_patients_list()
            }
        },
        //医生查看患者答题情况
        doctors_check_patient_answers(id){
            this.user_education_config_id = id
            UserMedicalScalesAnswerShow({
                user_follow_up_scheme_id: this.user_follow_up_scheme_id,
                follow_up_scheme_config_id: id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.user_education_config_array = res.data.data
                    this.user_education_config_array_user_follow_up_scheme_id = res.data.data.user_follow_up_scheme_id
                    this.user_education_config_array_follow_up_scheme_config_id = res.data.data.follow_up_scheme_config_id
                    if( this.user_role === 'user' ){
                        this.user_education_config_user_type = true
                    }else{
                        this.user_education_config_type = true
                    }
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //用户端我的消息列表
        privateMessages_list(){
            privateMessages({
                page: this.my_privateMessages_list,
                limit: 6,
                user_id: this.user_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.privateMessages_list_array = res.data.data.data
                    this.privateMessages_list_total = Number(res.data.data.total)
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //分页
        privateMessages_page(e){
            this.my_privateMessages_list = e
            this.privateMessages_list()
        },
        //详情
        privateMessages_info(array){
            const id = JSON.parse(array.redirect_data);
            console.log(array,id,id.user_follow_up_scheme_id)
            this.follow_patients_info(id.follow_up_scheme_id)
            privateMessagesEdit({
                id: array.id,
                is_read: 1,
            })
        }
    }
}
</script>
<style>
.list_search .el-input__inner {
    background-color: #e2eaf3;
}

.con_top .el-input__inner {
    background-color: #E2EEF6;
    height: 46px;
    line-height: 46px;
}

.list_name .el-menu-item{
    padding: 0 !important;
    height: 40px;
    line-height: 40px;
    width: 100% !important;
    min-width: 100% !important;
}

.list_name .el-submenu__title{
    padding: 0 !important;
    height: 3rem;
}
.list_name .is-opened .el-submenu__title i {
    color: #fff !important;
}
.list_name .el-menu{
    width: 90%;
    border: none !important;
}
.content .el-input-group__append{
    background-color: #0e92dd;
    color: #fff;
    font-size: 15px;
}
.con2_bottom .el-textarea__inner{
    font-size: 12px;
}
.con3_middle  .el-textarea__inner{
    font-size: 12px;
    width: 90%;
    color: #606266 !important;
    padding: 0.1rem;
    font-weight: normal !important;
    border-radius: 4px !important;
    border: 1px #DCDFE6 solid !important;
}
.add-patient-title {
    margin-bottom: 26px;
    font-weight: 700;
    font-size: 26px;
    color: #333333;
    line-height: 24px;
    text-align: left;
    font-style: normal;
}
.add_patient .el-input__inner {
    background-color: #f7f7f7;
}
.add_patient .el-textarea__inner{
    font-size: 12px !important;
    color: #a9a9a9 !important;
    background-color: #f7f7f7;
    border: 2px #d8d8d8 solid !important;
}
.add_user .el-form-item__label{
    width: 30% !important;
}
.add_user .el-input__inner{
    width: 100% !important;
    background-color: #e2eaf3;
}
.add_user .el-form-item__content{
    display: flex;
    align-items: center;
}
.message_style .el-textarea__inner{
    font-size: 12px !important;
    color: #a9a9a9 !important;
    background-color: #f7f7f7;
    border: 2px #d8d8d8 solid !important;
}
</style>
<style scoped>
.content{
    z-index: 1;
    height: 100%;
    width: 95vw;
    display: flex;
}
.list{
    width: 200px;
    height: 100%;
    background-color: #F0F9FF;
    position: relative;
}
.safe {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 231px;
    background-image: url("../assets/image/safe.png");
}
.con{
    width: 100%;
    margin-left: 19px;
    height: 100%;
    background: #F0F9FF;
    box-sizing: border-box;
    padding: 16px;
}

.list_name{
    width: 100%;
    height: 100%;
    background-color: #F0F9FF;
    margin:auto;
}
.con_top{
    width: 100%;
    margin: auto;
    height: 10%;
    display: flex;
    align-items: center;
}
.con_below{
    height: 84%;
    margin: auto;
    border: 1px #efefef solid;
    background-color: #fff;
    overflow-y: auto;
    padding: 0 43px 0 29px;
}
.con_below_page{
    width: 100%;
    height: 10%;
    margin: 10px auto 0;
    display: flex;
    justify-content: center;
}
.con_below_text{
    width: 100%;
    height: 65px;
    margin: 0 auto;
    border-bottom: 1px #ccc solid;
    display: flex;
    //justify-content: space-between;
    align-items: flex-end;
    cursor: pointer;
    padding-bottom: 5px;
}
.con_below_text_user{
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px #ccc solid;
    cursor: pointer;
}
.con1_top{
    width: 100%;
    margin: auto;
    height: 10%;
    display: flex;
    align-items: center;
}
.con1_middle{
    width: 90%;
    margin: auto;
    height: 20%;
    background-color: #FFFFFF;
    border: 1px #eeeeee solid;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 2.5%;
}
.con1_bottom{
    width: 100%;
    margin: 1rem auto 0;
    height: 50%;
    overflow-y: auto;
}
.con1_bottom_text{
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.con8_bottom{
    width: 100%;
    margin: 1rem auto 0;
    height: 50%;
}
.con8_bottom_text{
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.con2_middle{
    width: 100%;
    margin: auto;
    background-color: #FFFFFF;
    border: 1px #eeeeee solid;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.con2_bottom{
    width: 100%;
    margin: 0.5rem auto;
    height: 63%;
    background-color: #fff;
}
.con3_middle{
    width: 100%;
    margin: auto;
    height: 70%;
    overflow-y: auto;
    background-color: #FFFFFF;
}
.con4_top{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
  position: relative;
}
.con4_middle{
    width: 100%;
    margin: auto;
    height: 75%;
    overflow-y: auto;
    background-color: #FFFFFF;
}
.info_div_con_one{
    width: 100%;
    margin: 0.2rem auto;
    color: #bfbfbf;
    font-size: 15px;
    padding: 2px;
    //text-align-last:justify;
    text-align:justify;
    text-justify:distribute-all-lines;

}
.info_div_image_one{
    width: 100%;
    height: 100px;
    margin: auto;
    color: #bfbfbf;
    font-size: 15px;
    display: flex;
    align-items: center;
    padding-bottom: 1%;
}
.info_div_video_one{
    width: 100%;
    margin: auto;
    padding-bottom: 1%;
}
.info_div_file_one{
    width: 100%;
    margin: auto;
    padding-bottom: 1%;
    border-bottom: 1px #ccc solid;
}
.comment_one{
    width: 100%;
    height: 100px;
    margin: 10px auto;
}
.default_menu_type {
    background-color: #D5E7F3;
    /* border-bottom: 1px solid #5C9BC4; */
}
.select_style{
    background-color: #017ED8;
    color: #fff;
}
.sf-search {
    width: 281px;
    height: 46px;
    background-color: #E2EEF6;
    border-radius: 4px;
    border: 1px solid #C5D4DE;
}
.sf-title {
    font-weight: 700;
    font-size: 26px;
    color: #333333;
    line-height: 24px;
    font-style: normal;
}
.con3_middle_top {
    margin: 43px 0 0px 50px;
}
.con3_middle_input {
    width: 1042px;
    height: 66px;
    background: #F7F7F7;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #D6D6D6;
}
.con3_middle_input ::v-deep .el-input__inner {
    height: 66px !important;
    line-height: 66px !important;
    background: #F7F7F7 !important;
}
.con3_middle_textarea {
    width: 1042px;
    height: 117px;
    background: #F7F7F7;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #D6D6D6;
}
.con3_middle_textarea ::v-deep .el-textarea__inner {
    width: 100% !important;
    min-height: 117px !important;
    background: #F7F7F7 !important;
}
.con4-title {
    font-weight: 700;
    font-size: 26px;
    color: #333333;
    line-height: 24px;
    text-align: left;
    font-style: normal;
}
.con4-select {
    width: 384px;
    height: 46px;
    line-height: 46px;
    background: #E2EEF6;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #C5D4DE;
    margin-left: auto;
    margin-right: 20px;
}
.con4-select ::v-deep .el-input__inner,.con4-input ::v-deep .el-input__inner {
    width: 384px;
    height: 46px;
    line-height: 46px;
    background: #E2EEF6;
}
.con4-input ::v-deep .el-input__inner {
    width: 281px;
}
.con4-input {
    width: 281px;
    height: 46px;
    line-height: 46px;
    background: #E2EEF6;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #C5D4DE;
}
.qunfa-notice, .sf-fangan-title {
    margin-bottom: 26px;
    font-weight: 700;
    font-size: 26px;
    color: #333333;
    line-height: 24px;
    text-align: left;
    font-style: normal;
}
.sf-fangan-body {
    background-color: #fff;
    height: 800px;
    padding: 42px 26px 42px 40px;
}
.title_left {
  //position: absolute;
  //left: 20px;
  //top: 50%;
  //transform: translateY(-20%);
  display: inline-block;
  font-size: 30px;
  font-weight: bolder;
  &:hover {
    color: #017ed8;
  }
}
</style>
