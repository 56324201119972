<template>
  <div class="wrapper">
<!--    <div class="list_name" style="overflow-y: auto">-->
<!--      <el-menu-->
<!--          style="width: 100%;"-->
<!--          @select="switchOut"-->
<!--          background-color="#f0f4f7"-->
<!--          default-active="1"-->

<!--      >-->
<!--        <el-menu-item index="1" style="text-indent: 1em;height: 3rem;line-height: 3rem;">医生端</el-menu-item>-->
<!--&lt;!&ndash;        <el-menu-item index="2" style="text-indent: 1em;height: 3rem;line-height: 3rem;">患者端</el-menu-item>&ndash;&gt;-->
<!--      </el-menu>-->
<!--      <img src="/img/pa1.png" style="width: 100%;position: absolute;bottom: 0;object-fit: contain"  alt="">-->
<!--    </div>-->
    <div class="con">
      <div class="title">诊疗查询</div>
      <div style="width: 90%;font-size: 15px;padding: 10px;position: relative;margin: auto;">
        <el-input class="border-none" v-model="keyword" placeholder="请输入患者的身份证号码" prefix-icon="el-icon-search" style="width: 100%; border: none;"></el-input>
        <div style="padding: 2%;width: fit-content;position: absolute;top: 50%;right: 0;transform: translateY(-50%);">
          <el-button style="width: 120px;font-weight: bold;margin: 10px 0;padding: 3px 0;" type="primary" @click="retrieval">检索</el-button>
        </div>
      </div>
      <div class="condition">
        <div class="condition1" style="width: 20%;font-size: 15px;padding: 10px 5px;display: flex; align-items: center;">
          <el-input v-model="patient_name" style="width: 100%; border: none;">
            <template slot="prefix"><div style="color: black; padding-right: 5px;margin-top: 25%;">姓名:</div></template>
          </el-input>
        </div>
        <div class="condition1" style="width: 20%;font-size: 15px;padding: 10px 5px;display: flex; align-items: center;">
          <el-input v-model="patient_sex" style="width: 100%; border: none;">
            <template slot="prefix"><div style="color: black; padding-right: 5px;margin-top: 25%;">性别:</div></template>
          </el-input>
        </div>
        <div class="condition1" style="width: 20%;font-size: 15px;padding: 10px 5px;display: flex; align-items: center;">
          <el-input v-model="patient_age" style="width: 100%; border: none;">
            <template slot="prefix"><div style="color: black; padding-right: 5px;margin-top: 25%;">年龄:</div></template>
          </el-input>
        </div>
        <div class="condition1" id="sfz" style="width: 30%;font-size: 15px;padding: 10px 5px;display: flex; align-items: center;">
          <el-input v-model="patient_number" style="width: 100%; border: none;">
            <template slot="prefix"><div style="color: black; padding-right: 5px;margin-top: 20%;">身份证:</div></template>
          </el-input>
        </div>
      </div>
      <div class="info" v-loading="listLoading">
        <div style="width: 95%;height:fit-content;padding: 10px 0;border-radius:10px;margin: 1.5rem auto;font-size: 1rem;border: 2px #ddd solid;display: flex;align-items: center;position: relative;cursor: pointer" v-for="(item,index) in diagnosisLists" :key="index" @click="order_info(item)">
          <div style="width: 65px;height: 20px;background-color: #0e92dd;border-radius: 3px;text-align: center;line-height: 20px;color: #ffffff;position: absolute;top: -15%;left: 3rem;">
            {{ item.order_type === '1' ? '诊疗' : '处方' }}
          </div>
          <div style="width: 160px;height: 20px;background-color: #ccc;border-radius: 3px;text-align: center;line-height: 20px;color: #ffffff;position: absolute;top: -15%;right: 3rem;">
            {{ item.create_time }}
          </div>
          <div style="width: 90%;margin: auto;">
            <div class="text" style="text-align: left;font-size: 16px;">医生: {{ item.doctor.name }} 诊断: <span v-for="(item2,index2) in item.userDiagnose" :key="index2"> {{ item2.diagnose.name }}  </span> </div>
            <div class="text" style="text-align: left;font-size: 16px;">{{ item.order_type === '1' ? '诊疗' : '处方' }}: <span v-for="(item1,index1) in item.userPrescription" :key="index1"> {{ item1.prescription.name }}x{{ item1.number }}{{ item1.unit }} </span></div>
          </div>
        </div>
        <!--            <div style="width: 100%;margin: 0.1rem 0;" v-for="(item,index1) in prescriptionLists" :key="index1">-->
        <!--                <span class="text">{{ item.create_time }}</span>-->
        <!--                <span class="text">{{ item.prescription.name }}</span>-->
        <!--                <span class="text" v-html="item.prescription.intro"></span>-->
        <!--            </div>-->
      </div>
      <div style="width: 15%;margin: auto;" v-if="submitType !== 1">
        <el-button size="small" style="padding: 5px;height: 60%;width: 100%;background-color: #e6f1fc;color: #0e92dd;" type="primary" @click="nextStep">下一步</el-button>
      </div>
      <div style="width: 100%;height: 10px;"></div>

      <div id="pop_frame">
        <el-dialog title="" :visible.sync="dialogMaterial" append-to-body width="800px" :show-close="false">
          <!--            <div class="but">-->
          <!--                <div style="display: flex;align-items: center;justify-content: center;width: 100%;margin-top: 8%;">-->
          <!--                    <div style="width: 15%;font-size: 16px;">诊疗</div>-->
          <!--                    <div style="width: 30%;">-->
          <!--                        <el-select v-model="valueDiagnosis" placeholder="请选择" style="width: 100px;" size="mini">-->
          <!--                            <el-option-->
          <!--                                v-for="item in diagnosis_list"-->
          <!--                                :key="item.id"-->
          <!--                                :label="item.name"-->
          <!--                                :value="item.id">-->
          <!--                            </el-option>-->
          <!--                        </el-select>-->
          <!--                    </div>-->
          <!--                    <div style="width: 30%;">-->
          <!--                        <el-button size="small" style="padding: 5px;height: 60%;width: 70%;background-color: #e6f1fc;color: #0e92dd;" type="primary" @click="diagnosisSubmit">确定</el-button>-->
          <!--                    </div>-->
          <!--                </div>-->
          <!--                <div style="display: flex;align-items: center;justify-content: center;width: 100%;margin-top: 8%;">-->
          <!--                    <div style="width: 15%;font-size: 16px;">处方</div>-->
          <!--                    <div style="width: 30%;">-->
          <!--                        <el-select v-model="valuePrescription" placeholder="请选择" style="width: 100px;" size="mini">-->
          <!--                            <el-option-->
          <!--                                v-for="item in prescription_list"-->
          <!--                                :key="item.id"-->
          <!--                                :label="item.name"-->
          <!--                                :value="item.id">-->
          <!--                            </el-option>-->
          <!--                        </el-select>-->
          <!--                    </div>-->
          <!--                    <div style="width: 30%;">-->
          <!--                        <el-button size="small" style="padding: 5px;height: 60%;width: 70%;background-color: #e6f1fc;color: #0e92dd;" type="primary" @click="prescriptionSubmit">确定</el-button>-->
          <!--                    </div>-->
          <!--                </div>-->
          <!--            </div>-->
          <!--            <div style="width: 100%;height: 5rem;"></div>-->
          <div class="switch_titles">
            <div class="diagnosis_treatment" :class="{ 'blue_style': isDiagnosisSelected_diagnosis }" @click="diagnosis_treatment">诊疗</div>
            <div class="prescription" :class="{ 'blue_style': isDiagnosisSelected_prescription }" @click="prescription">处方</div>
          </div>
          <div class="diagnosis_treatment_text" v-if="switch_type === 1">
            <div class="diagnosis_treatment_text_id">
              <span>编号: {{ number_id }}</span>
              <span>日期: {{ toDate }}</span>
            </div>
            <div class="diagnosis_treatment_text_user">
                        <span class="span_style" style="margin-left: 2%;">
                            <span style="padding: 10px 10px 10px 0;">姓名:{{ patient_name }}</span>
                            <span style="padding: 10px;">性别:{{ patient_sex }}</span>
                            <span style="padding: 10px;">年龄:{{ patient_age }}</span>
                        </span>
              <span class="span_style" style="margin-right: 2%;">医生:{{ doctor_array.name }}</span>
            </div>
            <div class="diagnosis_treatment_text_diagnosis">
              <div class="diagnosis">
                <div class="diagnosis_select">
                  <div style="display: flex;">
                    <div style="padding-top: 3%;width: 8%;">诊断: </div>
                    <div style="width: 92%;">
                      <div style="margin-top: 1%;display: flex;" v-for="(item,index) in diagnosis_number" :key="index">
                        <div class="block">
                          <el-cascader
                              style="width: 200%;"
                              v-model="value[index]"
                              :options="options"
                              :props="{ expandTrigger: 'hover',checkStrictly: true }"
                              @change="handleChange(value,options)"></el-cascader>
                        </div>
                        <div style="margin-left: 48%;">
                          <img src="@/assets/triangle.png" style="width: 25px;height: 25px;margin-top: 30%;">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 3%;margin-left: 9%">
                    <el-button type="primary" icon="el-icon-plus" @click="diagnosis_add">添加</el-button>
                    <el-button type="info" icon="el-icon-close" @click="diagnosis_del">删除</el-button>
                  </div>
                </div>
                <div class="diagnosis_input">
                  <div style="width: 90%;height: 90%;margin: 3% auto;">
                    <el-input
                        style="font-size: 15px;"
                        @blur="blur_textarea"
                        type="textarea"
                        resize="none"
                        :rows="5.5"
                        placeholder="请输入内容"
                        v-model="textarea">
                    </el-input>
                  </div>
                </div>
              </div>
              <div class="treatment">
                <div class="treatment_select">
                  <div style="display: flex;width: 100%">
                    <div style="padding-top: 3%;width: 8%;">诊疗: </div>
                    <div style="width: 92%;">
                      <div style="margin-top: 1%;display: flex;" v-for="(item,index) in treatment_number" :key="index">
                        <div class="block" style="width: 30%;">
                          <el-cascader
                              style="width: 200%;"
                              v-model="value_diagnosis[index]"
                              :options="prescription_list"
                              :props="{ expandTrigger: 'hover',checkStrictly: true }"
                              @change="handleChange1(value_diagnosis,prescription_list)"></el-cascader>
                        </div>
                        <div class="block" style="width: 10%;margin-left: 35%;">
                          <el-input v-model="number[index]" placeholder="数量" @blur="blur_number(index)"></el-input>
                        </div>
                        <div class="block" style="width: 10%;margin-left: 5%;">
                          <el-select v-model="units[index]" placeholder="单位" @change="handleChange_unit(units)">
                            <el-option
                                v-for="item in unit_array"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 3%;margin-left: 9%">
                    <el-button type="primary" icon="el-icon-plus" @click="treatment_add">添加</el-button>
                    <el-button type="info" icon="el-icon-close" @click="treatment_del">删除</el-button>
                  </div>
                </div>
                <div class="treatment_input">
                  <div style="padding-top: 1%;width: 7%;">备注: </div>
                  <div style="width: 90%;height: 90%;margin-top: 0.5%;">
                    <el-input
                        style="font-size: 15px;"
                        @blur="blur_memo"
                        type="textarea"
                        resize="none"
                        :rows="2"
                        placeholder="备注信息"
                        v-model="memo">
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="diagnosis_treatment_text_but">
              <div style="width: 20%;margin: auto;">
                <el-button size="small" style="padding: 5px;height: 70%;width: 100%;background-color: #0e92dd;color: white;" type="primary" @click="diagnosis_treatment_next(1)">下一步</el-button>
              </div>
            </div>
          </div>

          <div class="diagnosis_treatment_text" v-if="switch_type === 2">
            <div class="diagnosis_treatment_text_id">
              <span>编号: {{ number_id }}</span>
              <span>日期: {{ toDate }}</span>
            </div>
            <div class="diagnosis_treatment_text_user">
                        <span class="span_style" style="margin-left: 2%;">
                            <span style="padding: 10px 10px 10px 0;">姓名: {{ patient_name }}</span>
                            <span style="padding: 10px;">性别: {{ patient_sex }}</span>
                            <span style="padding: 10px;">年龄: {{ patient_age }}</span>
                        </span>
              <span class="span_style" style="margin-right: 2%;">医生: {{ doctor_array.name }}</span>
            </div>
            <div class="diagnosis_treatment_text_diagnosis">
              <div class="diagnosis">
                <div class="diagnosis_select">
                  <div style="display: flex;">
                    <div style="padding-top: 3%;width: 8%;">诊断: </div>
                    <div style="width: 92%;">
                      <div style="margin-top: 1%;display: flex;" v-for="(item,index) in diagnosis_number" :key="index">
                        <div class="block">
                          <el-cascader
                              style="width: 200%;"
                              v-model="value[index]"
                              :options="options"
                              :props="{ expandTrigger: 'hover',checkStrictly: true }"
                              @change="handleChange(value,options)"></el-cascader>
                        </div>
                        <div style="margin-left: 48%;">
                          <img src="@/assets/triangle.png" style="width: 25px;height: 25px;margin-top: 30%;">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 3%;margin-left: 9%">
                    <el-button type="primary" icon="el-icon-plus" @click="diagnosis_add">添加</el-button>
                    <el-button type="info" icon="el-icon-close" @click="diagnosis_del">删除</el-button>
                  </div>
                </div>
                <div class="diagnosis_input">
                  <div style="width: 90%;height: 90%;margin: 3% auto;">
                    <el-input
                        @blur="blur_textarea"
                        style="font-size: 15px;"
                        type="textarea"
                        resize="none"
                        :rows="5.5"
                        placeholder="请输入内容"
                        v-model="textarea">
                    </el-input>
                  </div>
                </div>
              </div>
              <div class="treatment">
                <div class="treatment_select">
                  <div style="display: flex;width: 100%">
                    <div style="padding-top: 3%;width: 8%;">处方: </div>
                    <div style="width: 92%;">
                      <div style="margin-top: 1%;display: flex;" v-for="(item,index) in treatment_number" :key="index">
                        <div class="block">
                          <el-cascader
                              style="width: 200%;"
                              v-model="value_diagnosis[index]"
                              :options="prescription_list"
                              :props="{ expandTrigger: 'hover',checkStrictly: true }"
                              @change="handleChange1(value_diagnosis,prescription_list)"></el-cascader>
                        </div>
                        <div class="block" style="width: 10%;margin-left: 35%;">
                          <el-input v-model="number[index]" placeholder="数量" @blur="blur_number(index)"></el-input>
                        </div>
                        <div class="block" style="width: 10%;margin-left: 5%;">
                          <el-select v-model="units[index]" placeholder="单位" @change="handleChange_unit(units)">
                            <el-option
                                v-for="item in unit_array"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 3%;margin-left: 9%">
                    <el-button type="primary" icon="el-icon-plus" @click="treatment_add">添加</el-button>
                    <el-button type="info" icon="el-icon-close" @click="treatment_del">删除</el-button>
                  </div>
                </div>
                <div class="treatment_input">
                  <div style="padding-top: 1%;width: 7%;">备注: </div>
                  <div style="width: 90%;height: 90%;margin-top: 0.5%;">
                    <el-input
                        @blur="blur_memo"
                        style="font-size: 15px;"
                        type="textarea"
                        resize="none"
                        :rows="2"
                        placeholder="备注信息"
                        v-model="memo">
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="diagnosis_treatment_text_but">
              <div style="width: 20%;margin: auto;">
                <el-button size="small" style="padding: 5px;height: 70%;width: 100%;background-color: #0e92dd;color: white;" type="primary" @click="diagnosis_treatment_next(2)">下一步</el-button>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
      <el-dialog title="信息预览" :visible.sync="preview" append-to-body width="40%">
        <div style="margin: 5px 0 0 10px;">
          编号: {{ add_text.user_sid }}
        </div>
        <div style="margin: 5px 0 0 10px;">
          姓名: {{ add_text.user_name }}
        </div>
        <div style="margin: 5px 0 0 10px;">
          性别: {{ add_text.user_sex }}
        </div>
        <div style="margin: 5px 0 0 10px;">
          年龄: {{ add_text.user_age }}
        </div>
        <div style="margin: 5px 0 0 10px;">
          医生: {{ add_text.doctor_name }}
        </div>
        <div style="margin: 5px 0 0 10px;">
          日期: {{ add_text.user_data }}
        </div>
        <div style="margin: 5px 0 0 10px;" v-for="(item,index) in add_text.text1" :key="index">
          诊断: {{ item.name1 }}-{{ item.name2 }}-{{ item.intro }}
        </div>
        <div style="margin: 5px 0 0 10px;" v-for="(item,index2) in add_text.text2" :key="index2">
          {{ order_type == 1 ? '诊疗' : '处方' }}: {{ item.name1 }}-{{ item.name2 }}-{{ item.name3 }}x{{ item.number }}{{ item.unit }}
        </div>
        <div style="margin: 5px 0 0 10px;">
          备注: {{ add_text.remark }}
        </div>

        <div style="width: 80%;margin: 20px auto;display: flex;justify-content: center;padding-bottom: 20px;">
          <el-button size="small" style="padding: 5px;height: 70%;width: 30%;background-color: #0e92dd;color: white;" type="primary" @click="cancellation">取消</el-button>
          <el-button size="small" style="padding: 5px;height: 70%;width: 30%;background-color: #0e92dd;color: white;" type="primary" @click="determine">确定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="信息预览" append-to-body :visible.sync="preview_info" width="40%">
        <div style="margin: 5px 0 0 10px;">
          编号: {{ order_infos.order_num }}
        </div>
        <div style="margin: 5px 0 0 10px;">
          姓名: {{ add_text.user_name }}
        </div>
        <div style="margin: 5px 0 0 10px;">
          性别: {{ add_text.user_sex }}
        </div>
        <div style="margin: 5px 0 0 10px;">
          年龄: {{ add_text.user_age }}
        </div>
        <div style="margin: 5px 0 0 10px;">
          医生: {{ add_text.doctor_name }}
        </div>
        <div style="margin: 5px 0 0 10px;">
          日期: {{ add_text.user_data }}
        </div>
        <div style="margin: 5px 0 0 10px;" v-for="(item,index) in order_infos.userDiagnose" :key="index">
          诊断: {{ item.diagnose.name }}-{{ item.describe }}
        </div>
        <div style="margin: 5px 0 0 10px;" v-for="(item2,index2) in order_infos.userPrescription" :key="index2">
          {{ order_infos.order_type == '1' ? '诊疗' : '处方' }}: {{ item2.prescription.name }}x{{ item2.number}}{{ item2.unit }}
        </div>
        <div style="margin: 5px 0 0 10px;">
          备注: {{ order_infos.remark }}
        </div>

        <div style="width: 80%;margin: 20px auto;display: flex;justify-content: center;padding-bottom: 20px;">
          <el-button size="small" style="padding: 5px;height: 70%;width: 30%;background-color: #0e92dd;color: white;" type="primary" @click="cancellation_info">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
    diagnose, diagnosisOrder, diaOrderAdd, doctorInfo,
    prescription,
    user,
    userDiagnoseAdd,
    userPrescriptionAdd
} from "@/api";

export default {
    name: 'patientDiag',
    data() {
        return{
            preview: false,
            memo: '',
            diagnosis_number: [1],
            treatment_number: [1],
            textarea: [],
            diagnosis_value: '',
            isDiagnosisSelected_diagnosis: true,
            isDiagnosisSelected_prescription: false,
            dialogMaterial: false,
            value: [],
            value_diagnosis: [],
            // keyword: '372522197308072610',
            keyword: '',
            user_info: [],
            patient_id: '',
            patient_name: '',
            patient_sex: '',
            patient_age: '',
            patient_number: '',
            patient_phone: '',
            submitType: 1,
            diagnosis_list: [],
            prescription_list: [],
            valueDiagnosis: 1,
            valuePrescription: 1,
            user_id: '',
            listLoading: false,
            diagnosisLists: [],
            switch_type: 1,
            options: [],
            doctor_array: [],
            toDate: '',
            add_text: {},
            order_type: 0,
            number: [],
            units: [],
            unit_array: [{
                value: '盒',
                label: '盒'
            }, {
                value: '支',
                label: '支'
            }, {
                value: '克',
                label: '克'
            }, {
                value: '片',
                label: '片'
            }],
            number_id: '',
            prescription_type: 1,
            order_infos: [],
            preview_info: false,
            id_new: '',
            ids_new: '',
        }
    },
    mounted() {
        this.keyword = localStorage.getItem('keyword')
        if(this.keyword){
          this.retrieval();
        }
        this.user_id = localStorage.getItem('user_id')
        this.doctor_info()
        this.getCurrentDate()
    },
    methods: {
      switchOut(e) {
        if (e == 2) {
          this.$emit('loginOuts')
        }
      },
        order_info(item){
            this.preview_info = true
            this.order_infos = item
        },
        cancellation_info(){
            this.preview_info = false
        },
        getValueAfterComma(str) {
            const index = str.indexOf(',');
            if (index !== -1) {
                return str.substring(index + 1);
            } else {
                return '';
            }
        },
        findArrayWithId6(arr,id) {
            for (let i = 0; i < arr.length; i++) {
                for (let j = 0; j < arr[i]['children'].length; j++) {
                    if (arr[i]['children'][j]['id'] == id) {
                        return arr[i]['children'][j].intro;
                    }
                }
            }
            return null;
        },
        //诊疗下一步
        diagnosis_treatment_next(e){
            console.log(this.add_text)
            this.preview = true
            this.order_type = e
        },
        determine(){
            console.log(this.add_text)
            diaOrderAdd({
                order_type: this.order_type,
                user_id: this.add_text.user_id,
                doctor_id: this.add_text.doctor_id,
                diagnose_arr: this.add_text.diagnose_arr,
                prescription_arr: this.add_text.prescription_arr,
                remark: this.add_text.remark,
                order_num: this.number_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.preview = false
                    this.dialogMaterial = false
                    this.retrieval()
                    this.value = []
                    this.value_diagnosis = []
                    this.textarea = ''
                    this.memo = ''
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，需要加 1
            const day = String(today.getDate()).padStart(2, '0');
            this.toDate = `${year}-${month}-${day}`;
            this.number_id = today.getTime()+this.user_id;
        },
        doctor_info(){
            doctorInfo({
                id: localStorage.getItem('user_id'),
            }).then(res=>{
                if(res.data.code === 1){
                    this.doctor_array = res.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        findArraysById(targetId,id) {
            for (let i = 0; i < targetId.length; i++) {
                if (targetId[i]['id'] == id) {
                    return targetId[i]['name']
                }
            }
        },
        findArraysById1(targetId,id) {
            for (let i = 0; i < targetId.length; i++) {
                for (let j = 0; j < targetId[i]['children'].length; j++) {
                    console.log(targetId[i]['children'][j],id)
                    if (targetId[i]['children'][j]['id'] == id) {
                        return targetId[i]['children'][j]['name']
                    }
                }
            }
        },
        findArraysById2(targetId,id) {
            for (let i = 0; i < targetId.length; i++) {
                for (let j = 0; j < targetId[i]['children'].length; j++) {
                    for (let k = 0; k < targetId[i]['children'][j]['children'].length; k++) {
                        if (targetId[i]['children'][j]['children'][k]['id'] == id) {
                            return targetId[i]['children'][j]['children'][k]['name']
                        }
                    }
                }
            }
        },
        handleChange(id_array,item){
            this.textarea = ''
            const id = id_array[id_array.length - 1];
            if(id.length === 1){
                this.id_new = id[0]
            }else{
                this.id_new = id[1]
            }
            const intro = this.findArrayWithId6(item,this.id_new)
            console.log(id_array,id_array[id_array.length - 1],intro,this.id_new)
            this.textarea = intro
            const diagnose_arr = {
                 'id': this.id_new,
                 'describe': intro,
            }
            this.add_text.diagnose_arr.push(diagnose_arr);
            const arr = id_array[id_array.length - 1];
            const result = this.findArraysById(item, arr[0]);
            const result1 = this.findArraysById1(item, arr[1]);
            const array = {
                'name1':result,
                'name2':result1,
                'intro':intro,
            }
            console.log(id,intro,result,result1,this.textarea)
            this.add_text.text1.push(array)
            console.log(this.add_text)
        },
        blur_textarea(){
            this.add_text.diagnose_arr[this.add_text.diagnose_arr.length - 1].describe = this.textarea;
            this.add_text.text1[this.add_text.text1.length - 1].intro = this.textarea;
            console.log(this.textarea,this.add_text)
        },
        blur_number(e){
            const number = this.number[e]
            this.add_text.prescription_arr[this.add_text.prescription_arr.length - 1].number = number;
            this.add_text.text2[this.add_text.text2.length - 1].number = number;
            console.log(this.add_text)
        },
        blur_memo(){
            this.add_text.remark = this.memo;
            console.log(this.memo,this.add_text)
        },
        getLastNumberAfterComma(str) {
            const index = str.lastIndexOf(',');
            if (index !== -1) {
                return str.substring(index + 1);
            } else {
                return str;
            }
        },
        handleChange1(id_array,item){
            const id = id_array.join(',');
            const result1 = this.getLastNumberAfterComma(id);
            const prescription_arr = {
                'id': result1,
                'unit': '',
                'number': '',
            }
            this.add_text.prescription_arr.push(prescription_arr);
            const arr = id_array[id_array.length - 1];
            const result2 = this.findArraysById(item, arr[0]);
            const result3 = this.findArraysById1(item, arr[1]);
            const result4 = this.findArraysById2(item, arr[2]);
            const array = {
                'name1':result2,
                'name2':result3,
                'name3':result4,
                'unit': '',
                'number': '',
            }
            this.add_text.text2.push(array)
            console.log(array,this.add_text)
        },
        handleChange_unit(id_array){
            this.add_text.prescription_arr[this.add_text.prescription_arr.length - 1].unit = id_array[0];
            this.add_text.text2[this.add_text.text2.length - 1].unit = id_array[0];
            console.log(this.add_text)
        },
        //诊断点击添加
        diagnosis_add(){
            if (this.diagnosis_number.length >= 3){
                this.$message({
                    message: '最多增加三条',
                    type: 'warning'
                });
            }else{
                this.diagnosis_number.push(1)
            }
        },
        //诊断点击删除
        diagnosis_del(){
            if (this.diagnosis_number.length <= 1){
                this.$message({
                    message: '最少保留一条',
                    type: 'warning'
                });
            }else{
                this.diagnosis_number.pop(1)
                this.value.pop(1)
                this.add_text.diagnose_arr.pop(1)
                this.add_text.text1.pop(1)
                this.textarea = this.add_text.diagnose_arr[this.add_text.diagnose_arr.length - 1].describe
                console.log(this.add_text)
            }
        },
        //诊疗点击添加
        treatment_add(){
            if (this.treatment_number.length >= 3){
                this.$message({
                    message: '最多增加三条',
                    type: 'warning'
                });
            }else{
                this.treatment_number.push(1)
            }
        },
        //诊疗点击删除
        treatment_del(){
            if (this.treatment_number.length <= 1){
                this.$message({
                    message: '最少保留一条',
                    type: 'warning'
                });
            }else{
                this.treatment_number.pop(1)
                this.value_diagnosis.pop(1)
                this.add_text.prescription_arr.pop(1)
                this.add_text.text2.pop(1)
                console.log(this.add_text)
            }
        },
        //点击诊疗
        diagnosis_treatment(){
            this.diagnosis_number = [1]
            this.treatment_number = [1]
            this.number = []
            this.units = []
            this.switch_type = 1
            this.isDiagnosisSelected_diagnosis = true
            this.isDiagnosisSelected_prescription = false
            this.add_text.diagnose_arr = []
            this.add_text.prescription_arr = []
            this.add_text.text1 = []
            this.add_text.text2 = []
            this.add_text.remark = ''
            this.textarea = ''
            this.memo = ''
            this.value = []
            this.value_diagnosis = []
            this.prescription_type = 1
            this.prescriptionList()
        },
        //点击处方
        prescription(){
            this.diagnosis_number = [1]
            this.treatment_number = [1]
            this.number = []
            this.units = []
            this.switch_type = 2
            this.isDiagnosisSelected_prescription = true
            this.isDiagnosisSelected_diagnosis = false
            this.add_text.diagnose_arr = []
            this.add_text.prescription_arr = []
            this.add_text.text1 = []
            this.add_text.text2 = []
            this.add_text.remark = ''
            this.textarea = ''
            this.memo = ''
            this.value = []
            this.value_diagnosis = []
            this.prescription_type = 2
            this.prescriptionList()
        },
        cancellation(){
            this.preview = false
        },
        //下一步
        nextStep(){
            this.dialogMaterial = true
            this.diagnosis()
            this.prescriptionList()
            this.diagnosis_number = [1]
            this.treatment_number = [1]
            this.number = []
            this.units = []
            console.log(this.add_text)
        },
        //获取诊疗列表
        diagnosis(){
            diagnose().then(res=>{
                if(res.data.code === 1){
                    this.options = res.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //获取处方列表
        prescriptionList(){
            prescription({
                type: this.prescription_type,
            }).then(res=>{
                if(res.data.code === 1){
                    this.prescription_list = res.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //诊疗提交
        diagnosisSubmit(){
            userDiagnoseAdd({
                user_id: this.patient_id,
                doctor_id: this.user_id,
                diagnose_id: this.valueDiagnosis,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.dialogMaterial = false
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //处方提交
        prescriptionSubmit(){
            userPrescriptionAdd({
                user_id: this.patient_id,
                doctor_id: this.user_id,
                prescription_id: this.valuePrescription,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.dialogMaterial = false
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //身份证检索
        retrieval(){
            this.listLoading = true;
            user({
                id_number: this.keyword,
            }).then(res=>{
                if(res.data.code === 1){
                    this.user_info = res.data.data
                    this.patient_id = res.data.data.id
                    this.patient_name = res.data.data.name
                    if(res.data.data.sex === "1"){
                        this.patient_sex = '男'
                    }else{
                        this.patient_sex = '女'
                    }
                    this.patient_age = res.data.data.age
                    this.patient_number = res.data.data.id_number
                    this.patient_phone = res.data.data.tel
                    this.submitType = 2
                    this.add_text = {
                        "order_type": 1,
                        "user_id": res.data.data.id,
                        "doctor_id": this.user_id,
                        "diagnose_arr": [],
                        "prescription_arr": [],
                        "remark": '',
                        "text1":[],
                        "text2":[],
                        "user_sid":this.number_id,
                        "user_name":res.data.data.name,
                        "user_sex":this.patient_sex,
                        "user_age":this.patient_age,
                        "user_data":this.toDate,
                        "doctor_name":this.doctor_array.name,
                    }
                    localStorage.setItem('patient_id',this.patient_id)
                    diagnosisOrder({
                        page: 1,
                        limit: 999,
                        order_type: 0,
                        user_id: this.patient_id,
                    }).then(res1=>{
                        if(res1.data.code === 1){
                            this.listLoading = false;
                            this.diagnosisLists = res1.data.data.data
                        }else{
                            this.$message({
                                message: res1.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.submitType = 1
                    this.patient_name = ''
                    this.patient_sex = ''
                    this.patient_age = ''
                    this.patient_number = ''
                    this.$message({
                        message: '先去患者登记创建档案',
                        type: 'warning'
                    });
                }
            })
        },
    }
}
</script>
<style>
#pop_frame .el-dialog__header{
    padding: 0;
}
.con .el-dialog__body{
    padding: 0;
}
.diagnosis_treatment_text_diagnosis .el-input__inner{
    background-color: #f7f7f7;
}
.diagnosis_treatment_text_diagnosis .el-textarea__inner{
    background-color: #f7f7f7;
}
</style>
<style scoped>

.con {
    height: 100%;
    width: 100%;
    background-color: #f7f7f7cc;
    border: 1px #d6d8dc solid;
    z-index: 1;
}
.title{
    font-size: 16px;
    padding: 2% 0 0 3%;
}
.but{
    width: 96%;
    height: 50%;
    margin: auto;
}
.condition{
    width: 90%;
    height: 5rem;
    margin: 0 auto 1rem;
    display: flex;
    align-items: center;
    justify-content: left;
    /*border: 1px #dddddd solid;*/
    flex-wrap: wrap;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 10px 5px #eeeeee80;
}
.info{
    width: 90%;
    /*height: 20vh;*/
    height: calc(100% - 260px);
    margin: 1rem auto;
    overflow-y: auto;
}
.text{
    padding-right: 3%;
    font-size: 1.1rem;
}
.switch_titles{
    width: 100%;
    height: 40px;
    background-color: #b1b1b1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
}
.diagnosis_treatment{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.prescription{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blue_style{
    background-color: #007ed7;
    color: white;
}
.diagnosis_treatment_text{
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;
}
.diagnosis_treatment_text_id{
    width: 90%;
    margin: auto;
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
}
.diagnosis_treatment_text_user{
    width: 94%;
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;
    background-color: white;
    height: 40px;
}
.span_style{
    display: flex;
    align-items: center;
    justify-content: center;
}
.diagnosis_treatment_text_diagnosis{
    width: 94%;
    margin: 1rem auto;
    justify-content: space-between;
    background-color: white;
    height: 400px;
}
.diagnosis_treatment_text_but{
    width: 90%;
    margin: auto;
    padding: 0.5rem 0 2rem 0;
    justify-content: space-between;
}
.diagnosis{
    width: 96%;
    height: 50%;
    margin: 0 auto;
    border-bottom: 3px #f1f1f1 solid;
    display: flex;
}
.diagnosis_select{
    width: 70%;
    height: 100%;
}
.diagnosis_input{
    width: 30%;
    height: 100%;
}
.treatment{
    width: 96%;
    height: 50%;
    margin: 0 auto;
}
.treatment_select{
    width: 100%;
    height: 60%;
    overflow-y: auto;
}
.treatment_input{
    width: 100%;
    height: 40%;
    display: flex;
}
::v-deep .border-none .el-input__inner {
  border: none !important;
}
.list_name {
  position: relative;
  width: 200px;
  height: 100%;
  background: #f0f9ff;
}
.list_name .el-menu-item{
  padding: 0 !important;
  height: 40px;
  line-height: 40px;
  width: 100% !important;
  min-width: 100% !important;
}

.list_name .el-submenu__title{
  padding: 0 !important;
  height: 3rem;
}
.list_name .is-opened .el-submenu__title i {
  color: #fff !important;
}
.list_name .el-menu{
  width: 90%;
  border: none !important;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  width: 95vw;
  height: 70vh;
}
</style>
